import { useGenerateDocumentMutation } from '../api/documentApi';
import { GenerateDocRequest } from '../api/types';

export const useDocuments = () => {
	const [generateDocument, { isLoading: isGenerating }] =
		useGenerateDocumentMutation();

	return {
		isLoading: isGenerating,
		generateDocument: async (request: GenerateDocRequest) => {
			try {
				const response = await generateDocument(request).unwrap();
				return {
					success: true,
					documentUrl: response.document_url,
				};
			} catch (err) {
				return {
					success: false,
					error: err,
				};
			}
		},
	};
};
