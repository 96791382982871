import { useState } from 'react';

import { useSubmitEngagementActionMutation } from '../api/engagementApi';
import {
	EngagementActionRequest,
	EngagementActionResponse,
} from '../api/types';

export const useEngagement = () => {
	const [submitEngagementAction, { isLoading }] =
		useSubmitEngagementActionMutation();
	const [error, setError] = useState<string | null>(null);

	const handleEngagementAction = async (request: EngagementActionRequest) => {
		try {
			setError(null);
			const response: EngagementActionResponse =
				await submitEngagementAction(request).unwrap();
			return response;
		} catch (err) {
			setError('Failed to submit engagement action');
			console.error('Error submitting engagement action:', err);
		}
	};

	return {
		handleEngagementAction,
		isLoading,
		error,
	};
};
