import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Tenant } from './types';

// Assuming the Tenant type is defined in the types file

export interface TenantsState {
	tenants: {
		values: Tenant[];
		email: string;
	};
}

const initialState: TenantsState = {
	tenants: {
		values: [],
		email: '',
	},
};

export const tenantsSlice = createSlice({
	name: 'tenants',
	initialState,
	reducers: {
		setTenants: (
			state,
			action: PayloadAction<{ values: Tenant[]; email: string }>,
		) => {
			state.tenants = action.payload;
		},
	},
});

export const { setTenants } = tenantsSlice.actions;

export default tenantsSlice.reducer;
