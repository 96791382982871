/**
 * This function calculates the coordinates of a specific position within a textarea element.
 * It creates a hidden div element with the same styles as the textarea, and then appends a span
 * element at the specified position to determine the coordinates.
 *
 * @param {HTMLTextAreaElement} element - The textarea element.
 * @param {number} position - The position within the textarea to calculate the coordinates for.
 * @returns {Object} An object containing the top and left coordinates of the specified position.
 */

export const getElementCoordinates = (
	element: HTMLTextAreaElement,
	position: number,
) => {
	if (!element || !(element instanceof HTMLTextAreaElement)) {
		console.error('Invalid element: not an HTMLTextAreaElement');
		return { top: 0, left: 0 };
	}
	const div = document.createElement('div');
	const style = getComputedStyle(element);
	for (const prop in style) {
		if (
			Object.prototype.hasOwnProperty.call(style, prop) &&
			typeof style[prop] !== 'function'
		) {
			try {
				div.style[prop] = style[prop];
			} catch (e) {
				// Ignore read-only properties
			}
		}
	}
	div.style.position = 'absolute';
	div.style.visibility = 'hidden';
	div.style.whiteSpace = 'pre-wrap';
	div.style.overflowWrap = 'break-word';
	div.style.overflow = 'hidden';
	div.style.width = `${element.offsetWidth}px`;
	div.textContent = element.value.substring(0, position);
	document.body.appendChild(div);
	const span = document.createElement('span');
	span.textContent = element.value.substring(position) || '.';
	div.appendChild(span);
	const { offsetTop: top, offsetLeft: left } = span;
	document.body.removeChild(div);
	return { top, left };
};

export const removeCharsAfterEqual = (url: string) => {
	const index = url.indexOf('=');
	return index !== -1 ? url.substring(0, index) : url;
};

export const getInitial = (name: string) => {
	const nameParts = name.split(' ');
	const initial = nameParts[0].charAt(0).toUpperCase();
	return initial;
};

const memoizedColors: { [key: string]: string } = {};

export const getConsistentColor = (input: string) => {
	if (memoizedColors[input]) {
		return memoizedColors[input];
	}
	let hash = 0;
	for (let i = 0; i < input.length; i++) {
		hash = input.charCodeAt(i) + ((hash << 5) - hash);
	}
	let color = '#';
	for (let i = 0; i < 3; i++) {
		const value = (hash >> (i * 8)) & 0xff;
		color += ('00' + value.toString(16)).slice(-2);
	}
	memoizedColors[input] = color;
	return color;
};
