import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { User } from '../api/types';
import { RootState } from '../store';

export interface EnterpriseState {
	name: string;
	id: string;
	logo: string;
	users: User[];
	autopilots: {
		count: number;
	};
}

const initialState: EnterpriseState[] = [];

export const enterpriseSlice = createSlice({
	name: 'enterprise',
	initialState,
	reducers: {
		addEnterpriseData: (state, action: PayloadAction<EnterpriseState>) => {
			state.push(action.payload);
		},
		setEnterpriseData: (state, action: PayloadAction<EnterpriseState[]>) => {
			state = action.payload;
		},
	},
});

export const { addEnterpriseData, setEnterpriseData } = enterpriseSlice.actions;

export const selectEnterprise = (state: RootState) => state.enterpriseState;

export default enterpriseSlice.reducer;
