import { api } from './base';
import { FeedRequest, FeedResponse } from './types';

// Define a service using a base URL and expected endpoints
export const feedApi = api.injectEndpoints({
	endpoints: (builder) => ({
		fetchFeed: builder.query<FeedResponse, FeedRequest>({
			query: (request) => ({
				url: 'home_feed/',
				method: 'GET',
				params: request,
			}),
			transformResponse: (response: any) => {
				return response;
			},
		}),
	}),
});

// Export hooks for usage in functional components
export const { useFetchFeedQuery } = feedApi;
