import {
	ChromeWebLogo,
	GmailLogo,
	GmailLogoPlain,
	GoogleCalendarLogo,
	GoogleCalendarLogoPlain,
	GoogleDocsLogo,
	GoogleDocsLogoPlain,
	GoogleDriveLogo,
	GoogleDriveLogoPlain,
	GoogleMeetLogo,
	GoogleMeetLogoPlain,
	GoogleSheetsLogo,
	GoogleSheetsLogoPlain,
	GoogleWorkspaceLogo,
	HubspotLogo,
	LinearLogo,
	MBLogoIcon,
	MeetingsLogo,
	SalesforceLogo,
	SlackLogo,
	SlackLogoPlain,
	TeamsLogo,
	TwilioLogo,
	ZapierLogo,
	ZendeskLogo,
	ZoomLogo,
} from '../index';

const MEETING_INTEGRATIONS = [
	'google_meet',
	'google_meet_plain',
	'zoom',
	'zoom_plain',
];

function useIntegrationLogos() {
	function getIntegrationLogo(integration: string) {
		if (MEETING_INTEGRATIONS.includes(integration)) {
			integration = 'meetings';
		}
		switch (integration) {
			case 'gmail':
				return GmailLogo as string;
			case 'gmail_plain':
				return GmailLogoPlain as string;
			case 'salesforce':
			case 'salesforce_app':
				return SalesforceLogo as string;
			case 'teams':
				return TeamsLogo as string;
			case 'twilio':
				return TwilioLogo as string;
			case 'zapier':
				return ZapierLogo as string;
			case 'zendesk':
				return ZendeskLogo as string;
			case 'chrome_web':
				return ChromeWebLogo as string;
			case 'google_calendar':
				return GoogleCalendarLogo as string;
			case 'google_docs':
				return GoogleDocsLogo as string;
			case 'google_drive':
			case 'drive':
				return GoogleDriveLogo as string;
			case 'google_sheets':
				return GoogleSheetsLogo as string;
			case 'google_meet':
				return GoogleMeetLogo as string;
			case 'google_workspace':
			case 'google':
				return GoogleWorkspaceLogo as string;
			case 'hubspot':
			case 'hubspot_app':
				return HubspotLogo as string;
			case 'linear':
			case 'linear_app':
				return LinearLogo as string;
			case 'slack':
			case 'slack_app':
				return SlackLogo as string;
			case 'zoom':
			case 'zoom_plain':
				return ZoomLogo as string;
			case 'google_meet_plain':
				return GoogleMeetLogoPlain as string;
			case 'google_docs_plain':
				return GoogleDocsLogoPlain as string;
			case 'google_drive_plain':
			case 'pdf':
				return GoogleDriveLogoPlain as string;
			case 'google_calendar_plain':
				return GoogleCalendarLogoPlain as string;
			case 'google_sheets_plain':
				return GoogleSheetsLogoPlain as string;
			case 'meetings':
				return MeetingsLogo as string;
			default:
				return;
		}
	}

	const integrationRegexMap: { [key: string]: string } = {
		'drive(?!_plain)|google_drive(?!_plain)|pdf': GoogleDriveLogoPlain,
		'gmail(?!_plain)|mail(?!_plain)|email(?!_plain)': GmailLogoPlain,
		'salesforce(?!_plain)': SalesforceLogo,
		'teams(?!_plain)': TeamsLogo,
		'google_calendar(?!_plain)|calendar(?!_plain)': GoogleCalendarLogoPlain,
		'google_docs(?!_plain)|docs(?!_plain)': GoogleDocsLogoPlain,
		'google_sheets(?!_plain)|sheets(?!_plain)': GoogleSheetsLogoPlain,
		meetings: MeetingsLogo,
		'google_meet(?!_plain)': GoogleMeetLogo,
		'zoom(?!_plain)': ZoomLogo,
		'google_workspace(?!_plain)|google(?!_)(?!_plain)': GoogleWorkspaceLogo,
		'hubspot(?!_plain)': HubspotLogo,
		'slack(?!_plain)': SlackLogoPlain,
		'linear(?!_plain)|linear(?!_app)': LinearLogo,
	};

	function getIntegrationLogoRegex(integration: string) {
		if (MEETING_INTEGRATIONS.includes(integration)) {
			integration = 'meetings';
		}
		for (const [key, logo] of Object.entries(integrationRegexMap)) {
			if (new RegExp(key, 'i').test(integration)) {
				return logo as string;
			}
		}

		return undefined;
	}

	return {
		getIntegrationLogo,
		getIntegrationLogoRegex,
	};
}

export default useIntegrationLogos;
