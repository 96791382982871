import { useEffect, useState } from 'react';

import { useGetAutopilotLogsQuery } from '../api/autopilotsApi';

export function useAutopilotLogs({
	enterpriseId,
	autopilotId,
	userId,
	traceId,
	dateFrom,
	dateTo,
	isTest,
	page,
	perPage,
	showIndexLogs = true,
}: {
	enterpriseId?: string[];
	autopilotId?: string[];
	userId?: string[];
	traceId?: string;
	dateFrom?: string;
	dateTo?: string;
	isTest?: boolean;
	page?: number;
	perPage?: number;
	showIndexLogs?: boolean;
}) {
	const [currentPage, setCurrentPage] = useState(page ?? 1);
	const [currentPerPage, setCurrentPerPage] = useState(perPage ?? 10);

	useEffect(() => {
		if (page !== undefined) {
			setCurrentPage(page);
		}
	}, [page]);

	const { data, refetch, isFetching } = useGetAutopilotLogsQuery({
		enterpriseId,
		autopilotId,
		userId,
		traceId,
		dateFrom,
		dateTo,
		isTest,
		page: currentPage,
		perPage: currentPerPage,
		showIndexLogs,
	});

	const handleRefetch = (
		params: Partial<{ page: number; perPage: number }> = {},
	) => {
		if (params.page) setCurrentPage(params.page);
		if (params.perPage) setCurrentPerPage(params.perPage);
		return refetch();
	};

	return {
		logs: data?.logs ?? [],
		total: data?.total ?? 0,
		page: data?.page ?? currentPage,
		perPage: data?.per_page ?? currentPerPage,
		refetch: handleRefetch,
		isLoading: isFetching,
	};
}
