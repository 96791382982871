import React, { lazy, Suspense } from "react";
import { createBrowserRouter, Navigate, Outlet } from "react-router-dom";
import * as Sentry from "@sentry/react";

// Import only the components needed for initial load
import RootRoute from "./routes/rootRoute";
import ErrorFallback from "./components/ErrorFallback";

// Lazy load all other route components
const Admin = lazy(() => import(/* webpackChunkName: "adminRoute" */ "./routes/adminRoute"));
const Authenticated = lazy(() => import(/* webpackChunkName: "authenticatedRoute" */ "./routes/authenticatedRoute"));
const AuthCallback = lazy(() => import(/* webpackChunkName: "authCallbackRoute" */ "./routes/authCallbackRoute"));
const AuthRedirect = lazy(() => import(/* webpackChunkName: "authRedirectRoute" */ "./routes/authRedirectRoute"));
const Auth = lazy(() => import(/* webpackChunkName: "authRoute" */ "./routes/authRoute"));
const Chats = lazy(() => import(/* webpackChunkName: "chatsRoute" */ "./routes/chatsRoute"));
const Companies = lazy(() => import(/* webpackChunkName: "companiesRoute" */ "./routes/companiesRoute"));
const CompanyDetails = lazy(() => import(/* webpackChunkName: "companyDetailsRoute" */ "./routes/companyDetailsRoute"));
const Deals = lazy(() => import(/* webpackChunkName: "dealsRoute" */ "./routes/dealsRoute"));
const DealDetails = lazy(() => import(/* webpackChunkName: "dealDetailsRoute" */ "./routes/dealDetailsRoute"));
const Feedback = lazy(() => import(/* webpackChunkName: "feedbackRoute" */ "./routes/feedbackRoute"));
const Home = lazy(() => import(/* webpackChunkName: "homeRoute" */ "./routes/homeRoute"));
const Meetings = lazy(() => import(/* webpackChunkName: "meetingsRoute" */ "./routes/meetingsRoute"));
const Onboarding = lazy(() => import(/* webpackChunkName: "onboardingRoute" */ "./routes/onboardingRoute"));
const PaymentStatus = lazy(() => import(/* webpackChunkName: "paymentStatusRoute" */ "./routes/paymentStatusRoute"));
const People = lazy(() => import(/* webpackChunkName: "peopleRoute" */ "./routes/peopleRoute"));
const PeopleDetails = lazy(() => import(/* webpackChunkName: "peopleDetailsRoute" */ "./routes/peopleDetailsRoute"));
const Search = lazy(() => import(/* webpackChunkName: "searchRoute" */ "./routes/searchRoute"));
const Settings = lazy(() => import(/* webpackChunkName: "settingsRoute" */ "./routes/settingsRoute"));
const SnippetView = lazy(() => import(/* webpackChunkName: "snippetViewRoute" */ "./routes/snippetViewRoute"));
const TeamMeetings = lazy(() => import(/* webpackChunkName: "teamMeetingsRoute" */ "./routes/teamMeetingsRoute"));
const Unsubscribe = lazy(() => import(/* webpackChunkName: "unsubscribeRoute" */ "./routes/unsubscribeRoute"));
const Welcome = lazy(() => import(/* webpackChunkName: "welcomeRoute" */ "./routes/welcomeRoute"));

// Loading component to show while chunks are loading
const LoadingFallback = () => (
	<div className="flex items-center justify-center h-screen">
		<div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
	</div>
);

const routes = {
	auth: {
		signin: "/signin",
		login: "/login",
		signup: "/signup",
		callback: "/auth/callback",
		oauthCallback: "/oauth/callback",
	},
	onboarding: {
		root: "/onboarding",
		welcome: "/welcome",
	},
	app: {
		home: "/home",
		chats: "/chats",
		companies: "/companies",
		deals: "/deals",
		people: "/people",
		search: "/search",
		meetings: "/meetings",
		teamMeetings: {
			root: "/team/meetings",
			details: "/team/meetings/:meetingId/:view",
		},
		settings: "/settings",
		billing: {
			root: "/settings/billing",
			paymentStatus: "/settings/billing/payment-status",
		},
		snippets: "/snippets",
	},
	admin: {
		root: "/admin",
		enterprise: "/admin/enterprise",
	},
} as const;

export const createAppRouter = () => {
	const sentryCreateBrowserRouter =
		Sentry.wrapCreateBrowserRouter(createBrowserRouter);

	// Wrap component with Suspense
	const withSuspense = (Component: React.ComponentType<any>) => (
		<Suspense fallback={<LoadingFallback />}>
			<Component />
		</Suspense>
	);

	const routeConfig = [
		{
			path: "/",
			element: <RootRoute />,
			errorElement: <ErrorFallback />,
			children: [
				{
					errorElement: <ErrorFallback />,
					children: [
						// Auth Routes
						{
							path: routes.auth.signin,
							element: withSuspense(Auth),
						},
						{
							path: routes.auth.login,
							element: withSuspense(Auth),
						},
						{
							path: routes.auth.signup,
							element: withSuspense(Auth),
						},
						{
							path: routes.auth.callback,
							element: withSuspense(AuthCallback),
						},
						{
							path: routes.auth.oauthCallback,
							element: withSuspense(AuthCallback),
						},

						// Onboarding Routes
						{
							path: routes.onboarding.root,
							element: withSuspense(Onboarding),
						},
						{
							path: routes.onboarding.welcome,
							element: withSuspense(Welcome),
						},
						{
							path: `${routes.app.snippets}/:snippetId`,
							element: withSuspense(SnippetView),
						},

						// Protected Routes with SubscriptionGuard
						{
							element: withSuspense(Authenticated),
							children: [
								// Main App Routes
								{
									path: routes.app.home,
									element: withSuspense(Home),
								},
								{
									path: routes.app.chats,
									element: withSuspense(Chats),
								},
								{
									path: `${routes.app.chats}/:chatId`,
									element: withSuspense(Chats),
								},
								{
									path: routes.app.companies,
									element: withSuspense(Companies),
								},
								{
									path: `${routes.app.companies}/:companyId/details`,
									element: withSuspense(CompanyDetails),
								},
								{
									path: routes.app.deals,
									element: withSuspense(Deals),
								},
								{
									path: `${routes.app.deals}/:dealId/details`,
									element: withSuspense(DealDetails),
								},
								{
									path: routes.app.people,
									element: withSuspense(People),
								},
								{
									path: `${routes.app.people}/:personId/details`,
									element: withSuspense(PeopleDetails),
								},
								{
									path: routes.app.search,
									element: withSuspense(Search),
								},
								{
									path: routes.app.meetings,
									element: withSuspense(Meetings),
								},
								{
									path: `${routes.app.meetings}/:meetingId`,
									element: withSuspense(Meetings),
								},
								{
									path: `${routes.app.meetings}/:meetingId/:view`,
									element: withSuspense(Meetings),
								},
								{
									path: routes.app.settings,
									element: withSuspense(Settings),
								},
								{
									path: `${routes.app.settings}/*`,
									element: withSuspense(Settings),
								},
								{
									path: routes.app.billing.paymentStatus,
									element: withSuspense(PaymentStatus),
								},
								{
									path: routes.app.teamMeetings.root,
									element: withSuspense(TeamMeetings),
								},
								{
									path: `${routes.app.teamMeetings.details}`,
									element: withSuspense(TeamMeetings),
								},

								// Admin Routes
								{
									path: routes.admin.root,
									element: withSuspense(Admin),
								},
								{
									path: `${routes.admin.root}/:adminTab`,
									element: withSuspense(Admin),
								},
								{
									path: `${routes.admin.root}/enterprise`,
									element: <Navigate to="/admin/enterprises" replace />,
								},
								{
									path: `${routes.admin.root}/:adminTab/:enterpriseId`,
									element: withSuspense(Admin),
								},
								{
									path: `${routes.admin.root}/enterprise/:enterpriseId/:adminTab/:autopilotId`,
									element: withSuspense(Admin),
								},
								{
									path: `${routes.admin.root}/enterprise/:enterpriseId/:adminTab/:autopilotId/edit`,
									element: withSuspense(Admin),
								},
								{
									path: `${routes.admin.root}/enterprise/:enterpriseId/:adminTab/:autopilotId/new`,
									element: withSuspense(Admin),
								},
								{
									path: `${routes.admin.root}/enterprise/:enterpriseId/autopilot/:autopilotId/:adminTab`,
									element: withSuspense(Admin),
								},

								// Utility Routes
								{
									path: "/email/unsubscribe",
									element: withSuspense(Unsubscribe),
								},
								{
									path: "/email/feedback",
									element: withSuspense(Feedback),
								},
								{
									path: "/integrations/*",
									element: withSuspense(AuthRedirect),
								},

								// Redirects
								{
									path: "/connect",
									element: <Navigate to="/settings/connect" replace />,
								},
							],
						},

						// 404 Route
						{
							path: "*",
							element: <ErrorFallback errorType="routeNotFound" />,
						},
					],
				},
			],
		},
	];

	return sentryCreateBrowserRouter(routeConfig, {
		basename: "/app",
		future: {
			v7_skipActionErrorRevalidation: true,
			v7_normalizeFormMethod: true,
			v7_routeLoaderData: true,
			v7_relativeSplatPath: true,
			v7_fetcherPersist: true,
			v7_partialHydration: true,
		},
	});
};
