import styled, { css } from 'styled-components';

import Button from '../Button';

export const CalendarContainer = styled.div`
	background: white;
	border-radius: 8px;
	padding: 10px 16px;
	width: 100%;
`;

export const CalendarHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 16px;
`;

export const WeekDaysRow = styled.div`
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	text-align: center;
	margin-bottom: 8px;
`;

export const DaysGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	gap: 2px;
	width: 100%;
`;

export const DayButton = styled.button<{
	selected: boolean;
	isinrange: string;
	disabled: boolean;
	isdimmed: string;
	israngestart: boolean;
	israngeend: boolean;
}>`
	width: 36px;
	height: 36px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	background: ${(props) =>
		props.selected
			? 'var(--mb-blue-6)'
			: props.isinrange === 'true'
				? 'var(--mb-blue-2)'
				: 'transparent'};
	color: ${(props) =>
		props.selected
			? 'white'
			: props.isdimmed === 'true'
				? 'var(--mb-gray-6)'
				: 'var(--mb-gray-9)'};
	border-radius: 4px;
	cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
	opacity: ${(props) => (props.disabled ? 0.5 : 1)};
	font-size: 13px;
	position: relative;

	${(props) =>
		props.israngestart &&
		css`
			border-radius: 4px 0 0 4px;
			background: var(--mb-blue-8);
			color: white;
		`}

	${(props) =>
		props.israngeend &&
		css`
			border-radius: 0 4px 4px 0;
			background: var(--mb-blue-8);
			color: white;
		`}

	&:hover {
		background: ${(props) =>
			props.disabled
				? 'transparent'
				: props.selected
					? 'var(--mb-blue-7)'
					: 'var(--mb-gray-2)'};
	}
`;

export const MonthNavigationButton = styled(Button)`
	padding: 4px;
	min-width: unset;
	background: transparent;
	cursor: pointer;

	&:hover {
		background: var(--mb-gray-2);
	}
`;
