import { agentApi } from './base';
import { MeetingRequest, TranscriptSegment } from './types';

export const transcriptApi = agentApi.injectEndpoints({
	endpoints: (builder) => ({
		getMeetingTranscript: builder.query<
			{ transcript: TranscriptSegment[] },
			MeetingRequest
		>({
			query: ({ meeting_id, is_team_view }) => ({
				url: `/metastore/raw_transcript/${meeting_id}?structured=true`,
				method: 'GET',
				params: { is_team_view: is_team_view ?? false },
			}),
		}),
	}),
});

export const { useGetMeetingTranscriptQuery } = transcriptApi;
