import { useGetPersonDetailsQuery } from '../api/personApi';
import { PersonDetailsParams } from '../api/types';

export const usePersonDetails = (
	email: string,
	options: PersonDetailsParams = {},
) => {
	const {
		data: personDetails,
		isLoading,
		isFetching,
		error,
		refetch,
	} = useGetPersonDetailsQuery(
		{
			email,
			params: {
				include_deals: options.include_deals,
				include_tasks: options.include_tasks,
				include_notes: options.include_notes,
				include_activity: options.include_activity,
				include_latest_activity: options.include_latest_activity,
			},
		},
		{
			// Only fetch if we have an email
			skip: !email,
		},
	);

	return {
		personDetails,
		isLoading,
		isFetching,
		error,
		refetch,
		deals: personDetails?.deals ?? [],
		tasks: personDetails?.tasks ?? [],
		notes: personDetails?.notes ?? [],
		lastContacted: personDetails?.last_contacted,
		hubspotUrl: personDetails?.hubspot_url,
		salesforceUrl: personDetails?.salesforce_url,
	};
};
