import React from 'react';

import {
	Button as RadixButton,
	ButtonProps as RadixButtonProps,
} from '@radix-ui/themes';

type ButtonVariant = RadixButtonProps['variant'] | 'white-bg';

export interface ButtonProps extends Omit<RadixButtonProps, 'variant'> {
	variant?: ButtonVariant;
}

/**
 * Trigger an action or event, such as submitting a form or displaying a dialog.
 */
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	(props, ref) => {
		const { variant, style, ...rest } = props;

		if (variant === 'white-bg') {
			const whiteBackgroundStyle = {
				backgroundColor: 'white',
				color: 'var(--color-text-primary)',
				border: '1px solid var(--gray-6)',
				cursor: 'pointer',
				...style,
			};

			return <RadixButton {...rest} style={whiteBackgroundStyle} ref={ref} />;
		}

		// Only pass the variant if it's a valid RadixButton variant
		const radixVariant = variant as Exclude<ButtonVariant, 'white-bg'>;
		return (
			<RadixButton
				{...rest}
				style={{ cursor: 'pointer', ...style }}
				variant={radixVariant}
				ref={ref}
			/>
		);
	},
);

export default Button;
