import { useEffect } from 'react';

import { UUID } from 'crypto';

import { api as searchApi } from '../api/base';
import {
	useCreateSearchMutation,
	useDeleteSearchMutation,
	useGetSearchQuery,
	useGetSearchesQuery,
	useUpdateSearchMutation,
} from '../api/searchApi';
import { CreateSearchRequest, UpdateSearchRequest } from '../api/types';

export const useSearch = (activeSearchId: UUID | null) => {
	const [createSearch] = useCreateSearchMutation();
	const [updateSearch] = useUpdateSearchMutation();
	const [deleteSearch] = useDeleteSearchMutation();

	const { data: activeSearch, refetch: refetchActiveSearch } =
		useGetSearchQuery(activeSearchId ?? '', {
			// skip: !activeSearchId,
			refetchOnMountOrArgChange: true,
		});

	const {
		data: userSearches,
		refetch: refetchUserSearches,
		isLoading: isLoadingUserSearches,
	} = useGetSearchesQuery({});

	useEffect(() => {
		if (activeSearchId) {
			refetchActiveSearch();
		}
	}, [activeSearchId, refetchActiveSearch]);

	const handleCreateSearch = async (searchData: CreateSearchRequest) => {
		try {
			const result = await createSearch(searchData).unwrap();
			return result;
		} catch (error) {
			console.error('Failed to create search:', error);
		}
	};

	const handleUpdateSearch = async (
		id: string,
		updateData: UpdateSearchRequest,
	) => {
		try {
			const result = await updateSearch({ id, body: updateData }).unwrap();
			return result;
		} catch (error) {
			console.error('Failed to update search:', error);
		}
	};

	const handleDeleteSearch = async (id: string) => {
		try {
			await deleteSearch(id).unwrap();
			refetchUserSearches();
		} catch (error) {
			console.error('Failed to delete search:', error);
		}
	};

	const clearActiveSearch = () => {
		// Instead of trying to manually update the cache, we'll invalidate the tag
		searchApi.util.invalidateTags([
			{ type: 'Search', id: activeSearchId ?? '' },
		]);
		activeSearchId && refetchActiveSearch();
	};

	return {
		activeSearch: activeSearchId ? activeSearch : null,
		userSearches,
		createSearch: handleCreateSearch,
		updateSearch: handleUpdateSearch,
		deleteSearch: handleDeleteSearch,
		clearActiveSearch,
		refetchUserSearches,
		isLoadingUserSearches,
		refetchActiveSearch,
	};
};
