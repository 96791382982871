import React from 'react';

import { styled } from 'styled-components';

import AvatarWrapper from '../AvatarWrapper';
import Box from '../Box';

type OverlappingAvatarsProps = {
	data: {
		avatar: string;
		alt: string;
		key?: string;
	}[];
	size?: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';
};

const OverlappingAvatarsContainer = styled(Box)`
	display: flex;
	align-items: center;
	.rt-AvatarRoot {
		margin-left: -5px;
	}
`;

const OverlappingAvatars = ({ data, size }: OverlappingAvatarsProps) => {
	return (
		<OverlappingAvatarsContainer>
			{data.map(
				(d: {
					avatar: string;
					fallback?: React.ReactNode;
					alt: string;
					key?: string;
				}) => (
					<AvatarWrapper
						key={d.key || d.alt}
						alt={d.alt}
						src={d.avatar}
						radius="full"
						variant="solid"
						size={size ?? '1'}
					/>
				),
			)}
		</OverlappingAvatarsContainer>
	);
};

export default OverlappingAvatars;
