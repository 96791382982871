import { useEffect, useState } from 'react';

import localforage from 'localforage';

import { useFetchFeedQuery } from '../api/feedApi';
import { ExampleFeedData, FeedItem, FeedRequest } from '../api/types';
import { EXAMPLE_FEED_KEY } from '../constants';
import { SAMPLE_FEED } from '../utils/sampleFeedData';

export const useFeed = (params: FeedRequest) => {
	const [localFeed, setLocalFeed] = useState<Partial<FeedItem>[]>([]);
	const [isLoadingLocal, setIsLoadingLocal] = useState(false);

	const { data, error, isLoading } = useFetchFeedQuery({
		page: params.page ?? 1,
		per_page: params.per_page ?? 200,
		person_email: params.person_email,
		company_name: params.company_name,
	});

	useEffect(() => {
		const fetchLocalFeed = async () => {
			setIsLoadingLocal(true);
			try {
				const stored =
					await localforage.getItem<ExampleFeedData['fields']>(
						EXAMPLE_FEED_KEY,
					);
				setLocalFeed(stored?.feed ?? (SAMPLE_FEED as Partial<FeedItem>[]));
			} catch (err) {
				console.error('Error fetching from localforage:', err);
				setLocalFeed(SAMPLE_FEED as Partial<FeedItem>[]);
			} finally {
				setIsLoadingLocal(false);
			}
		};

		if (!data?.items || data.items.length === 0) {
			fetchLocalFeed();
		}
	}, [data]);

	return {
		data: data?.items?.length ? data : { items: localFeed },
		error,
		isLoading: isLoading || isLoadingLocal,
	};
};
