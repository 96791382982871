export const ACCESS_TOKEN_KEY = 'access_token';
export const REFRESH_TOKEN_KEY = 'refresh_token';
export const CHATS_KEY = 'chats';
export const CHAT_MESSAGES_KEY = 'chat_messages';
export const JOURNALS_KEY = 'journals';
export const JOURNAL_CATEGORIES_KEY = 'journal_categories';
export const JOURNALS_TOGGLED_KEY = 'journalsToggled';
export const AUTOPILOTS_TOGGLED_KEY = 'autopilotsToggled';
export const ONBOARDING_KEY = 'onboarding';
export const ACCESS_TOKEN_TYPE = 'access_token';
export const CHAIN_OF_THOUGHT_TOGGLED_KEY = 'chainOfThoughtToggled';
export const WS_URL_KEY = 'ws_url';
export const API_URL_KEY = 'api_url';
export const MAX_ACTIVE_CHATS_KEY = 'maxActiveChats';
export const CONNECTED_CHANNELS_KEY = 'connectedChannels';
export const LOGGED_IN_TENANT_KEY = 'loggedInTenant';
export const APP_URL_KEY = 'app_url';
export const WORKSPACE_DOMAIN_KEY = 'workspace_domain';
export const AUTOPILOTS_BASE_URL = '/autopilot';
export const ENTERPRISE_BASE_URL = '/enterprise';
export const USER_ANALYTICS_TOGGLED_KEY = 'userAnalyticsToggled';
export const QUALITY_PERFORMANCE_TOGGLED_KEY = 'qualityPerformanceToggled';
export const WEB_PUSH_TOGGLED_KEY = 'webPushToggled';
export const CSRF_TOKEN_KEY = 'csrf_token';
export const LANGSMITH_KEY = 'langsmith';
export const POSTHOG_KEY = 'posthog';
export const PORTKEY_KEY = 'portkey';
export const WEB_PUSH_KEY = 'webPush';
export const AUTOPILOTS_DRAFT_KEY = 'autopilots_draft';
export const INTERNAL_MEETING_BOT_TOGGLED_KEY =
	'internalMeetingBotDisabledToggled';
export const SEARCH_FILTERS_KEY = 'search_filters';
export const EXAMPLE_MEETINGS_KEY = 'example_meetings';
export const EXAMPLE_FEED_KEY = 'example_feed';
export const CHAT_BOH_KEY = 'chat_boh';
export const EXAMPLE_MEETING_IDS = ['example-meeting'];
export const LOGIN_ERROR_MESSAGE_KEY = 'loginErrorMessage';
export const LOGOUT_MESSAGE_KEY = 'logoutMessage';
export const CONNECT_APPS_REMINDER_IGNORED_KEY =
	'connect_apps_reminder_ignored';

// split feature flags key
export const MEETINGS_LIST_FILTERS_FEATURE = 'meetings_list_filters';
export const PEOPLE_LIST_FILTERS_FEATURE = 'people_list_filters';
export const PEOPLE_PAGE_FEATURE = 'people_page';
export const SEARCH_MAINTENANCE = 'search_maintenance';
export const TEAM_MEETINGS = 'team_meetings';
export const COMPANY_LIST_FILTERS_FEATURE = 'company_list_filters';
export const COMPANY_PAGE_FEATURE = 'company_page';
export const DEAL_PAGE_FEATURE = 'deal_page';

// MightyBot prod domain
export const MIGHTYBOT_PROD = 'app.mightybot.ai';

export const UPDATE_INTEGRATIONS = 'update-integrations';
export const CRON_KEYS = [
	{ id: 'year', label: 'Year' },
	{ id: 'month', label: 'Month' },
	{ id: 'day', label: 'Day' },
	{ id: 'week', label: 'Week' },
	{ id: 'day_of_week', label: 'Day of Week' },
	{ id: 'hour', label: 'Hour' },
	{ id: 'minute', label: 'Minute' },
	{ id: 'second', label: 'Second' },
];

export const INTERVAL_UNITS = [
	{
		id: 'minutes',
		label: 'minutes',
	},
	{
		id: 'hours',
		label: 'hours',
	},
	{
		id: 'days',
		label: 'days',
	},
	{
		id: 'weeks',
		label: 'weeks',
	},
	{
		id: 'months',
		label: 'months',
	},
	{
		id: 'years',
		label: 'years',
	},
];

export const SELECTED_MODEL_KEY = 'selectedModel';
export const MODELS = [
	{ id: 'auto', label: 'Auto' },
	{ id: 'gpt-4o-mini-2024-07-18', label: 'GPT-4o Mini' },
	{ id: 'claude-3-5-haiku-20241022', label: 'Claude 3.5 Haiku' },
	{ id: 'gemini-1.5-flash-002', label: 'Gemini Flash' },
	{ id: 'grok-2-1212', label: 'Grok 2' },
	{ id: 'deepseek-r1-distill-llama-70b', label: 'DeepSeek R1 Distill 70B' },
	{ id: 'llama-3.3-70b-specdec', label: 'Llama 3.3 70B SpecDec' },
	{ id: 'llama-3.3-70b-versatile', label: 'Llama 3.3 70B Versatile' },
];

export const PUBLIC_PATHS = [
	'/signin',
	'/login',
	'/auth/callback',
	'/oauth/callback',
	'/email/unsubscribe',
	'/email/feedback',
];

export const PUBLIC_ROUTES = [
	'/snippets',
	// Add other public routes here
];
