import { useEffect, useState } from 'react';

import {
	useGetPersonDealsQuery,
	useGetPersonNotesQuery,
	useGetPersonTasksQuery,
} from '../api/personApi';

const DEFAULT_LIMIT = 100;

interface UsePersonPaginatedDataOptions {
	page?: number;
	perPage?: number;
}

export const usePersonTasks = (
	person_id: string,
	options: UsePersonPaginatedDataOptions = {},
) => {
	const [page, setPage] = useState(1);
	const perPage = options.perPage ?? DEFAULT_LIMIT;

	const {
		data: crmData,
		isLoading,
		isFetching,
		refetch,
	} = useGetPersonTasksQuery(
		{
			person_id,
			params: {
				per_page: perPage,
				page: options?.page ?? page,
			},
		},
		{ skip: !person_id },
	);

	// Reset page when person_id changes
	useEffect(() => {
		setPage(1);
	}, [ person_id ] );

	return {
		tasks: crmData?.data ?? [],
		isLoading,
		isFetching,
		page,
		setPage,
		hasMore: crmData?.data && crmData.data.length === perPage,
		refetch,
		total: crmData?.total ?? 0,
	};
};

export const usePersonNotes = (
	person_id: string,
	options: UsePersonPaginatedDataOptions = {},
) => {
	const [page, setPage] = useState(1);
	const perPage = options.perPage ?? DEFAULT_LIMIT;

	const {
		data: crmData,
		isLoading,
		isFetching,
		refetch,
	} = useGetPersonNotesQuery(
		{
			person_id,
			params: {
				per_page: perPage,
				page: options?.page ?? page,
			},
		},
		{ skip: !person_id },
	);

	// Reset page when person_id changes
	useEffect(() => {
		setPage(1);
	}, [ person_id ] );

	return {
		notes: crmData?.data ?? [],
		isLoading,
		isFetching,
		page,
		setPage,
		hasMore: crmData?.data && crmData.data.length === perPage,
		refetch,
		total: crmData?.total ?? 0,
	};
};

export const usePersonDeals = (
	person_id: string,
	options: UsePersonPaginatedDataOptions = {},
) => {
	const [page, setPage] = useState(1);
	const perPage = options.perPage ?? DEFAULT_LIMIT;

	const { data, isLoading, isFetching, refetch } = useGetPersonDealsQuery(
		{
			person_id,
			params: {
				per_page: perPage,
				page,
			},
		},
		{ skip: !person_id },
	);

	// Reset page when person_id changes
	useEffect(() => {
		setPage(1);
	}, [ person_id ] );

	return {
		deals: data?.data ?? [],
		isLoading,
		isFetching,
		page,
		setPage,
		hasMore: data?.data && data.data.length === perPage,
		refetch,
		total: data?.total,
	};
};
