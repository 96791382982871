import React from 'react';

import { styled } from 'styled-components';

import {
	getConsistentColor,
	getInitial,
	removeCharsAfterEqual,
} from '../../util/helper';
import Avatar from '../Avatar';
import Text from '../Text';

type AvatarWrapperProps = {
	src: string;
	alt: string;
	fallback?: React.ReactNode;
	size?: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';
	radius?: 'none' | 'small' | 'medium' | 'large' | 'full';
	variant?: 'solid' | 'soft';
	styles?: React.CSSProperties;
	blur?: boolean;
};

const Fallback = ({ alt }: { alt: string }) => {
	const initial = getInitial(alt);

	return <Text style={{ color: 'white' }}>{initial}</Text>;
};

interface AvatarProps {
	bgcolor?: string;
	$blur?: boolean;
}

const StyledAvatar = styled(Avatar)<AvatarProps>`
	.rt-AvatarFallback {
		background-color: ${(props) => props.bgcolor || ''};
	}

	${(props) =>
		props.$blur &&
		`
		filter: blur(4px);
		user-select: none;
	`}
`;


const processAvatarSrc = (url: string): string => {
	if (!url) return '';

	if (url.includes('licdn.com')) { // LinkedIn image
		return url;
	}

	return removeCharsAfterEqual(url);
};

const AvatarWrapper = ({
	src,
	alt,
	fallback,
	size = '3',
	radius = 'medium',
	variant = 'soft',
	styles,
	blur = false,
}: AvatarWrapperProps) => {
	const processedSrc = src ? processAvatarSrc(src) : '';
	
	let color = '';

	if (!fallback) {
		color = getConsistentColor(alt);
		fallback = <Fallback alt={alt} />;
	}

	return (
		<StyledAvatar
			bgcolor={color}
			src={processedSrc}
			alt={alt}
			fallback={fallback}
			variant={variant}
			radius={radius}
			size={size}
			style={styles}
			$blur={blur}
		/>
	);
};

export default AvatarWrapper;
