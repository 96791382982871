import { CollectionType } from '../commons/types';
import { CollectionTypeEnum, ContentTypeEnum } from '../constants/enums';
import { contentApi } from './base';
import { CreateNoteRequest, NoteResponse, UpdateNoteRequest } from './types';

export const notesApi = contentApi.injectEndpoints({
	endpoints: (builder) => ({
		listNotes: builder.query<
			{ items: NoteResponse[]; has_more: boolean; total: number },
			{
				collection_id: string;
				collection_type?: CollectionType[];
				page?: number;
				page_size?: number;
			}
		>({
			query: ({
				collection_id,
				page = 1,
				page_size = 10,
				collection_type,
			}) => ({
				url: 'content/',
				method: 'GET',
				params: {
					collection_id,
					collection_type,
					content_type: ContentTypeEnum.NOTE,
					page,
					page_size,
				},
			}),
			serializeQueryArgs: ({ queryArgs }) => {
				// Create cache key based on collection_id only
				return `notes-${queryArgs.collection_id}`;
			},
			merge: (currentCache, newItems, { arg: { page = 1 } }) => {
				if (page === 1) {
					return newItems;
				}
				return {
					...newItems,
					items: [...(currentCache?.items || []), ...newItems.items],
				};
			},
			// Only fetch new data if page changes
			forceRefetch: ({ currentArg, previousArg }) => {
				if (!previousArg) return true;
				return currentArg?.page !== previousArg?.page;
			},
			providesTags: (result, error, { collection_id }) => [
				{ type: 'Notes', id: collection_id },
			],
		}),

		createNote: builder.mutation<NoteResponse, CreateNoteRequest>({
			query: (body) => ({
				url: 'content/',
				method: 'POST',
				body: {
					...body,
					collection_type: body.collection_type,
					content_type: ContentTypeEnum.NOTE,
				},
			}),
			invalidatesTags: (result, error, { collection_id }) => [
				{ type: 'Notes', id: collection_id },
			],
		}),

		getNote: builder.query<NoteResponse, string>({
			query: (noteId) => ({
				url: `content/${noteId}`,
				method: 'GET',
			}),
			providesTags: (result, error, noteId) => [{ type: 'Notes', id: noteId }],
		}),

		updateNote: builder.mutation<
			NoteResponse,
			{ noteId: string; body: UpdateNoteRequest }
		>({
			query: ({ noteId, body }) => ({
				url: `content/${noteId}`,
				method: 'PUT',
				body: {
					...body,
					content_type: ContentTypeEnum.NOTE,
				},
			}),
			invalidatesTags: (result) => [
				{ type: 'Notes', id: result?.collection_id },
			],
		}),

		deleteNote: builder.mutation<void, string>({
			query: (noteId) => ({
				url: `content/${noteId}`,
				method: 'DELETE',
			}),
			invalidatesTags: (result, error, noteId) => [
				{ type: 'Notes', id: 'LIST' },
			],
		}),
	}),
});

export const {
	useListNotesQuery,
	useCreateNoteMutation,
	useGetNoteQuery,
	useUpdateNoteMutation,
	useDeleteNoteMutation,
} = notesApi;
