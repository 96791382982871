import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { User } from '../api/types';
import { RootState } from '../store';
import { Autopilot, AutopilotConfig } from './types';

export interface AutopilotsState {
	autopilots: Autopilot[];
	autopilot: Autopilot | null;
}

const initialState: AutopilotsState = {
	autopilots: [],
	autopilot: null,
};

export const autopilotsSlice = createSlice({
	name: 'autopilots',
	initialState,
	reducers: {
		setAutopilots: (state, action: PayloadAction<Autopilot[]>) => {
			state.autopilots = action.payload;
		},
		setAutopilot: (state, action: PayloadAction<Autopilot | null>) => {
			state.autopilot = action.payload;
		},
		addAutopilot: (state, action: PayloadAction<Autopilot>) => {
			state.autopilots.push(action.payload);
		},
		updateAutopilot: (state, action: PayloadAction<Autopilot>) => {
			const index = state.autopilots.findIndex(
				(autopilot) => autopilot.id === action.payload.id,
			);
			if (index !== -1) {
				state.autopilots[index] = action.payload;
			}
		},
		removeAutopilot: (state, action: PayloadAction<string>) => {
			state.autopilots = state.autopilots.filter(
				(autopilot) => autopilot.id !== action.payload,
			);
		},
		addAutopilotUser: (
			state,
			action: PayloadAction<{ autopilotId: string; user: User }>,
		) => {
			const index = state.autopilots.findIndex(
				(autopilot) => autopilot.id === action.payload.autopilotId,
			);
			if (index !== -1) {
				state.autopilots[index].users.push(action.payload.user);
			}
		},
		removeAutopilotUser: (
			state,
			action: PayloadAction<{ autopilotId: string; userId: string }>,
		) => {
			const index = state.autopilots.findIndex(
				(autopilot) => autopilot.id === action.payload.autopilotId,
			);
			if (index !== -1) {
				state.autopilots[index].users = state.autopilots[index].users.filter(
					(user) => user.id !== action.payload.userId,
				);
			}
		},
		toggleAutopilotEnabled: (state, action: PayloadAction<string>) => {
			const index = state.autopilots.findIndex(
				(autopilot) => autopilot.id === action.payload,
			);
			if (index !== -1) {
				state.autopilots[index].is_enabled =
					!state.autopilots[index].is_enabled;
			}
		},
	},
});

export const {
	setAutopilots,
	setAutopilot,
	addAutopilot,
	updateAutopilot,
	removeAutopilot,
	toggleAutopilotEnabled,
} = autopilotsSlice.actions;

export const selectAutopilots = (state: RootState) =>
	state.autopilotsState.autopilots;

export const selectAutopilot = (state: RootState) =>
	state.autopilotsState.autopilot;

export default autopilotsSlice.reducer;
