import { UUID } from 'crypto';

import { contentApi } from './base';
import { Template } from './types';

const templateApi = contentApi.injectEndpoints({
	endpoints: (builder) => ({
		listTemplates: builder.query<{ templates: Template[] }, void>({
			query: () => ({
				url: 'template/',
				method: 'GET',
			}),
		}),

		createTemplate: builder.mutation<Template, Partial<Template>>({
			query: (body) => ({
				url: 'template/',
				method: 'POST',
				body,
			}),
		}),

		updateTemplate: builder.mutation<
			Template,
			{ templateId: UUID; body: Partial<Template> }
		>({
			query: ({ templateId, body }) => ({
				url: `template/${templateId}`,
				method: 'PUT',
				body,
			}),
		}),

		deleteTemplate: builder.mutation<void, UUID>({
			query: (templateId) => ({
				url: `template/${templateId}`,
				method: 'DELETE',
			}),
		}),
	}),
	overrideExisting: false,
});

export const {
	useListTemplatesQuery,
	useCreateTemplateMutation,
	useUpdateTemplateMutation,
	useDeleteTemplateMutation,
} = templateApi;
