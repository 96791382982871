import React, { forwardRef } from 'react';

import { MightyBotLogo } from '@mightybot/core-ui';

import Flex from '../Flex';
import Text from '../Text';
import { AgentSpinnerCard, Spinner } from './styled';

interface AgentSpinnerProps {
	text?: string;
	iconWidth?: string;
	iconHeight?: string;
	containerStyle?: any;
	spinnerStyle?: any;
}

const AgentSpinner = forwardRef<HTMLDivElement, AgentSpinnerProps>(
	(
		{
			text,
			iconWidth = '1.75rem',
			iconHeight = '1.75rem',
			containerStyle,
			spinnerStyle,
		},
		ref,
	) => {
		return (
			<AgentSpinnerCard
				variant="surface"
				style={{
					backgroundColor: 'var(--mb-blue-1)',
					padding: '5px',
					...containerStyle,
				}}
				ref={ref}
			>
				<Flex direction="column" align="center" justify="center">
					<Spinner
						style={{
							width: '3.25rem',
							height: '3.25rem',
							...spinnerStyle,
						}}
					>
						<img
							src={MightyBotLogo as string}
							alt=""
							style={{
								position: 'relative',
								width: iconWidth,
								height: iconHeight,
							}}
						/>
					</Spinner>
					{text && (
						<Text size="2" ml="2">
							{text}
						</Text>
					)}
				</Flex>
			</AgentSpinnerCard>
		);
	},
);

export default AgentSpinner;
