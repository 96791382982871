import { useEffect, useMemo, useState } from 'react';

import debounce from 'lodash/debounce';

import { useGetRelevantEnterprisePeopleQuery } from '../api/enterpriseApi';
import {
	useGetRecentContactsQuery,
	useGetRelevantPeopleQuery,
} from '../api/personApi';
import { Person, User } from '../api/types';

interface UseRelevantPeopleProps {
	searchTerm: string;
	enterpriseId?: string;
	onlyMbUsers?: boolean;
	enabled?: boolean;
	debounceMs?: number;
	showRecentContacts?: boolean;
	onlyEnterprisePeople?: boolean;
}

export const useRelevantPeople = ({
	searchTerm,
	enterpriseId,
	onlyMbUsers = false,
	enabled = true,
	debounceMs = 300,
	showRecentContacts = true,
	onlyEnterprisePeople = false,
}: UseRelevantPeopleProps) => {
	const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

	const debouncedSetSearch = useMemo(
		() => debounce((term: string) => setDebouncedSearchTerm(term), debounceMs),
		[debounceMs],
	);

	useEffect(() => {
		debouncedSetSearch(searchTerm);
		return () => {
			debouncedSetSearch.cancel();
		};
	}, [searchTerm, debouncedSetSearch]);

	const [people, setPeople] = useState<Person[] | Partial<User>[]>([]);

	const {
		data: relevantPeople,
		isLoading: isRelevantPeopleLoading,
		error: relevantPeopleError,
	} = useGetRelevantPeopleQuery(
		{ searchTerm: debouncedSearchTerm, onlyMbUsers },
		{
			skip: !enabled || debouncedSearchTerm.length < 1 || onlyEnterprisePeople,
		},
	);

	const {
		data: recentContacts,
		isLoading: isRecentContactsLoading,
		error: recentContactsError,
	} = useGetRecentContactsQuery(10, {
		skip:
			!enabled ||
			!showRecentContacts ||
			onlyEnterprisePeople ||
			debouncedSearchTerm.length > 0,
	});

	const {
		data: relevantEnterprisePeople,
		isLoading: isRelevantEnterprisePeopleLoading,
		error: relevantEnterprisePeopleError,
	} = useGetRelevantEnterprisePeopleQuery(
		{ enterpriseId: enterpriseId ?? '', searchTerm: debouncedSearchTerm },
		{
			skip:
				!enabled ||
				!onlyEnterprisePeople ||
				!enterpriseId ||
				debouncedSearchTerm.length < 1,
		},
	);

	useEffect(() => {
		if (onlyEnterprisePeople && relevantEnterprisePeople) {
			setPeople(relevantEnterprisePeople.users);
		} else if (relevantPeople && debouncedSearchTerm.length > 0) {
			setPeople(relevantPeople);
		} else if (recentContacts) {
			setPeople(recentContacts);
		}
	}, [
		relevantPeople,
		recentContacts,
		debouncedSearchTerm,
		relevantEnterprisePeople,
		onlyEnterprisePeople,
	]);

	return {
		people,
		isLoading:
			isRelevantPeopleLoading ||
			isRecentContactsLoading ||
			isRelevantEnterprisePeopleLoading,
		error:
			relevantPeopleError ||
			recentContactsError ||
			relevantEnterprisePeopleError,
	};
};
