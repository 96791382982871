import {
	ANALYTICS_EVENTS,
	ANALYTICS_PROPERTIES,
	AnalyticsEvent,
} from '../constants/analytics';
import { PosthogUtil } from './posthog';

type PropertyValue = string | number | boolean | null | undefined;

const defaultEventOptions: AnalyticsOptions = {
	posthog: true,
	// debug: process.env.NODE_ENV === 'development',
};

export interface AnalyticsProperties {
	[key: string]:
		| PropertyValue
		| PropertyValue[]
		| Record<string, PropertyValue>;
}

export interface AnalyticsOptions {
	posthog: boolean;
	debug?: boolean;
}

export interface AnalyticsError extends Error {
	code: string;
	context?: Record<string, unknown>;
}

type QueueItem = {
	type: 'track' | 'identify' | 'reset';
	args: unknown[];
};

const createAnalyticsService = () => {
	let options: AnalyticsOptions = {
		posthog: true,
		debug: process.env.NODE_ENV === 'development',
	};
	let initialized = false;
	const queue: QueueItem[] = [];

	const createError = (
		message: string,
		code: string,
		context?: Record<string, unknown>,
	): AnalyticsError => {
		const error = new Error(message) as AnalyticsError;
		error.code = code;
		error.context = context;
		return error;
	};

	const logDebug = (...args: unknown[]): void => {
		if (options.debug) {
			console.debug('[Analytics]', ...args);
		}
	};

	const processQueue = (): void => {
		while (queue.length > 0) {
			const item = queue.shift();
			if (item) {
				switch (item.type) {
					case 'track':
						trackEvent.apply(
							null,
							item.args as [
								AnalyticsEvent,
								AnalyticsProperties?,
								AnalyticsOptions?,
							],
						);
						break;
					case 'identify':
						identifyUser.apply(
							null,
							item.args as [string, AnalyticsProperties?, AnalyticsOptions?],
						);
						break;
					case 'reset':
						reset.apply(null, item.args as [AnalyticsOptions?]);
						break;
				}
			}
		}
	};

	const initialize = (initOptions?: Partial<AnalyticsOptions>): void => {
		options = { ...options, ...initOptions };
		initialized = true;
		processQueue();
	};

	const trackEvent = (
		eventName: AnalyticsEvent,
		properties?: AnalyticsProperties,
		eventOptions?: Partial<AnalyticsOptions>,
	): void => {
		if (!initialized) {
			queue.push({
				type: 'track',
				args: [eventName, properties, eventOptions],
			});
			return;
		}

		const mergedOptions = {
			...options,
			...eventOptions,
			...defaultEventOptions,
		};

		try {
			const enrichedProperties = {
				timestamp: new Date().toISOString(),
				environment: process.env.NODE_ENV,
				[ANALYTICS_PROPERTIES.PLATFORM]: 'web',
				...properties,
			};

			logDebug('Tracking event:', eventName, enrichedProperties);

			if (mergedOptions.posthog) {
				PosthogUtil.captureEvent(eventName, enrichedProperties);
			}
		} catch (error) {
			const analyticsError = createError(
				'Failed to track event',
				'ANALYTICS_TRACK_ERROR',
				{ eventName, properties, error },
			);
			console.error(analyticsError);
			throw analyticsError;
		}
	};

	const identifyUser = (
		userId: string,
		properties?: AnalyticsProperties,
		identifyOptions?: Partial<AnalyticsOptions>,
	): void => {
		if (!initialized) {
			queue.push({
				type: 'identify',
				args: [userId, properties, identifyOptions],
			});
			return;
		}

		const mergedOptions = {
			...options,
			...identifyOptions,
			...defaultEventOptions,
		};

		try {
			const enrichedProperties = {
				timestamp: new Date().toISOString(),
				[ANALYTICS_PROPERTIES.USER_TYPE]: properties?.user_type || 'unknown',
				...properties,
			};

			logDebug('Identifying user:', userId, enrichedProperties);

			if (mergedOptions.posthog) {
				PosthogUtil.identifyUser(userId, enrichedProperties);
			}
		} catch (error) {
			const analyticsError = createError(
				'Failed to identify user',
				'ANALYTICS_IDENTIFY_ERROR',
				{ userId, properties, error },
			);
			console.error(analyticsError);
			throw analyticsError;
		}
	};

	const reset = (resetOptions?: Partial<AnalyticsOptions>): void => {
		if (!initialized) {
			queue.push({ type: 'reset', args: [resetOptions] });
			return;
		}

		const mergedOptions = { ...options, ...resetOptions };

		try {
			logDebug('Resetting analytics');

			if (mergedOptions.posthog) {
				PosthogUtil.resetUser();
			}
		} catch (error) {
			const analyticsError = createError(
				'Failed to reset analytics',
				'ANALYTICS_RESET_ERROR',
				{ error },
			);
			console.error(analyticsError);
			throw analyticsError;
		}
	};

	return {
		initialize,
		trackEvent,
		identifyUser,
		reset,
	};
};

export const Analytics = createAnalyticsService();
