import { useEffect, useState } from 'react';

import { useGetCompanyTasksQuery } from '../api/companyApi';

const DEFAULT_LIMIT = 100;

interface UseCompanyTasksOptions {
	page?: number;
	perPage?: number;
}

export const useCompanyTasks = (
	company_id: string,
	options: UseCompanyTasksOptions = {},
) => {
	const [page, setPage] = useState(1);
	const perPage = options.perPage ?? DEFAULT_LIMIT;

	const {
		data,
		isLoading,
		isFetching,
		refetch,
	} = useGetCompanyTasksQuery(
		{
			company_id,
			per_page: perPage,
			page: options?.page ?? page,
		},
	);

	// Reset page when company_id changes
	useEffect(() => {
		setPage(1);
	}, [company_id]);

	return {
		tasks: data?.data ?? [],
		isLoading,
		isFetching,
		page,
		setPage,
		hasMore: data?.data && data.data.length === perPage,
		refetch,
		total: data?.total ?? 0,
	};
};
