import { contentApi } from './base';
import {
	DocType,
	DocumentOutput,
	GenerateDocRequest,
	GenerateDocResponse,
} from './types';

const documentApi = contentApi.injectEndpoints({
	endpoints: (builder) => ({
		generateDocument: builder.mutation<GenerateDocResponse, GenerateDocRequest>(
			{
				query: (body) => ({
					url: 'document-outputs/generate',
					method: 'POST',
					body,
				}),
			},
		),
		listDocuments: builder.query<
			{ documents: DocumentOutput[] },
			{
				collection_id?: string;
				template_id?: string;
				document_type?: DocType;
			}
		>({
			query: (params) => ({
				url: 'document-outputs',
				method: 'GET',
				params,
			}),
		}),
	}),
	overrideExisting: false,
});

export const { useGenerateDocumentMutation, useListDocumentsQuery } =
	documentApi;
