import { CollectionType } from '../commons/types';
import { CollectionTypeEnum, ContentTypeEnum } from '../constants/enums';
import { contentApi } from './base';
import { CreateTaskRequest, TaskResponse, UpdateTaskRequest } from './types';

export const tasksApi = contentApi.injectEndpoints({
	endpoints: (builder) => ({
		listTasks: builder.query<
			{ items: TaskResponse[]; has_more: boolean; total: number },
			{
				collection_id: string;
				collection_type?: CollectionType[];
				page?: number;
				page_size?: number;
			}
		>({
			query: ({
				collection_id,
				collection_type,
				page = 1,
				page_size = 10,
			}) => ({
				url: 'content/',
				method: 'GET',
				params: {
					collection_id,
					collection_type,
					content_type: ContentTypeEnum.TASK,
					page,
					page_size,
				},
			}),
			serializeQueryArgs: ({ queryArgs }) => {
				// Create cache key based on collection_id only
				return `tasks-${queryArgs.collection_id}`;
			},
			merge: (currentCache, newItems, { arg: { page = 1 } }) => {
				if (page === 1) {
					return newItems;
				}
				return {
					...newItems,
					items: [...(currentCache?.items || []), ...newItems.items],
				};
			},
			// Only fetch new data if page changes
			forceRefetch: ({ currentArg, previousArg }) => {
				if (!previousArg) return true;
				return currentArg?.page !== previousArg?.page;
			},
			providesTags: (result, error, { collection_id }) => [
				{ type: 'Tasks', id: collection_id },
			],
		}),

		createTask: builder.mutation<TaskResponse, CreateTaskRequest>({
			query: (body) => ({
				url: 'content/',
				method: 'POST',
				body: {
					...body,
					collection_type: body.collection_type,
					content_type: ContentTypeEnum.TASK,
				},
			}),
			invalidatesTags: (result, error, { collection_id }) => [
				{ type: 'Tasks', id: collection_id },
			],
		}),

		getTask: builder.query<TaskResponse, string>({
			query: (taskId) => ({
				url: `content/${taskId}`,
				method: 'GET',
			}),
		}),

		updateTask: builder.mutation<
			TaskResponse,
			{ taskId: string; body: UpdateTaskRequest }
		>({
			query: ({ taskId, body }) => ({
				url: `content/${taskId}`,
				method: 'PUT',
				body: {
					...body,
					content_type: ContentTypeEnum.TASK,
				},
			}),
			invalidatesTags: (result, error, { taskId }) => [
				{ type: 'Tasks', id: result?.collection_id },
				{ type: 'Tasks', id: taskId },
			],
		}),

		deleteTask: builder.mutation<void, string>({
			query: (taskId) => ({
				url: `content/${taskId}`,
				method: 'DELETE',
			}),
			invalidatesTags: (result, error, taskId) => [
				{ type: 'Tasks', id: 'LIST' },
			],
		}),
	}),
});

export const {
	useListTasksQuery,
	useCreateTaskMutation,
	useGetTaskQuery,
	useUpdateTaskMutation,
	useDeleteTaskMutation,
} = tasksApi;
