import { useEffect, useState } from 'react';

import localforage from 'localforage';

import { useGetMeetingQuery } from '../api/meetingsApi';
import { ExampleMeetingData, Meeting } from '../api/types';
import { EXAMPLE_MEETINGS_KEY, EXAMPLE_MEETING_IDS } from '../constants';
import { SAMPLE_MEETINGS } from '../utils/sampleMeetingData';

export const useMeeting = (meetingId?: string) => {
	const [localMeeting, setLocalMeeting] = useState<Meeting | null>(null);
	const isTeamView = window.location.pathname.includes('/team/');

	const { data, error, isLoading } = useGetMeetingQuery(
		{
			meeting_id: meetingId ?? '',
			is_team_view: isTeamView,
		},
		{ skip: !meetingId || EXAMPLE_MEETING_IDS.includes(meetingId) },
	);

	useEffect(() => {
		const fetchLocalMeeting = async () => {
			if (meetingId && EXAMPLE_MEETING_IDS.includes(meetingId)) {
				const stored = (
					await localforage.getItem<ExampleMeetingData['fields']>(
						EXAMPLE_MEETINGS_KEY,
					)
				)?.meetings;
				const requestedMeeting = stored?.find(
					(m) => m.id === meetingId,
				) as Meeting;
				setLocalMeeting({
					...requestedMeeting,
					start_time: new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString(),
					end_time: new Date(Date.now() + 25 * 60 * 60 * 1000).toISOString(),
				});
			}
		};

		fetchLocalMeeting();
	}, [meetingId]);

	return {
		meeting: EXAMPLE_MEETING_IDS.includes(meetingId ?? '')
			? (localMeeting ?? SAMPLE_MEETINGS[0])
			: data,
		error,
		isLoading:
			isLoading ||
			(EXAMPLE_MEETING_IDS.includes(meetingId ?? '') && !localMeeting),
	};
};