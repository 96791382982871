import { useGetEnterpriseQuery } from '../api/enterpriseApi';

export function useEnterprise() {
	const {
		data: enterpriseData,
		isLoading: isEnterpriseLoading,
		isFetching: isEnterpriseFetching,
		isError: isEnterpriseError,
		refetch,
	} = useGetEnterpriseQuery();

	return {
		enterpriseData,
		isEnterpriseLoading,
		isEnterpriseFetching,
		isEnterpriseError,
		refetch,
	};
}
