import { useCallback, useMemo } from 'react';

import { useGetCompaniesQuery } from '../api/companyApi';

export interface UseCompanyListOptions {
	page: number;
	per_page: number;
	enterprise_id: string | undefined;
	search?: string;
	industry?: string[];
	type?: string[];
	health?: string[];
}

export const useCompanyList = (options: UseCompanyListOptions) => {
	// Create a stable filterKey that doesn't include pagination
	const filterKey = useMemo(
		() =>
			JSON.stringify({
				search: options.search,
				type: options.type,
				health: options.health,
				industry: options.industry,
				enterprise_id: options.enterprise_id,
			}),
		[
			options.search,
			options.type,
			options.health,
			options.industry,
			options.enterprise_id,
		],
	);

	const { data, isLoading, isFetching, error, refetch } = useGetCompaniesQuery(
		{
			...options,
			filterKey,
		},
		{
			skip: !options.enterprise_id,
			// Prevent refetching on window focus
			refetchOnFocus: false,
			// Prevent refetching on reconnect
			refetchOnReconnect: false,
		},
	);

	const loadMore = useCallback(() => {
		if (data?.has_more && !isLoading && !isFetching) {
			return refetch();
		}
	}, [data?.has_more, isLoading, isFetching, refetch]);

	return {
		companies: data?.companies || [],
		total: data?.total || 0,
		hasMore: data?.has_more || false,
		isLoading,
		isFetching,
		error,
		loadMore,
	};
};
