import { useGetAppSearchCountsQuery } from '../api/agentSearchApi';
import { SearchCountsResponse } from '../api/types';

export interface UseAgentSearchResult {
	searchCounts: SearchCountsResponse['data'] | null;
	isLoading: boolean;
	error: unknown;
	refetch: () => void;
}

export const useAgentSearch = (): UseAgentSearchResult => {
	const {
		data: searchCounts,
		isLoading,
		error,
		refetch,
	} = useGetAppSearchCountsQuery();

	return {
		searchCounts: searchCounts?.data ?? null,
		isLoading,
		error,
		refetch,
	};
};
