import {
	useConnectIntegrationMutation,
	useDisconnectGoogleAppsMutation,
	useDisconnectIntegrationMutation,
	useGoogleAppBatchConnectMutation,
	useGoogleAppConnectMutation,
} from '../api/integrationsApi';
import { AppType, DisconnectAppResponse, GoogleAppType } from '../api/types';
import { UPDATE_INTEGRATIONS } from '../constants';
import { useMe } from './useMe';

export function useIntegrationConnect() {
	const connectApp = (app: AppType | GoogleAppType) => {
		window.open(app.redirect_url, '_blank', 'noopener,noreferrer');
	};

	const { refetch: refetchUser } = useMe();

	const [disconnectIntegration, { isSuccess: isDisconnectSuccess }] =
		useDisconnectIntegrationMutation();
	const [googleAppConnect, { isSuccess: isGoogleConnectSuccess }] =
		useGoogleAppConnectMutation();
	const [googleAppBatchConnect, { isSuccess: isGoogleBatchConnectSuccess }] =
		useGoogleAppBatchConnectMutation();
	const [connectIntegration, { isSuccess: isConnectSuccess }] =
		useConnectIntegrationMutation();
	const [disconnectGoogleApps, { isSuccess: isDisconnectGoogleAppsSuccess }] =
		useDisconnectGoogleAppsMutation();

	const connectGoogleApp = async (app: GoogleAppType) => {
		// @ts-ignore
		window.google.accounts.oauth2
			.initCodeClient({
				client_id: (app as GoogleAppType).client_id,
				scope: app.scopes.join(' '),
				ux_mode: 'popup',
				callback: async (response: { code: string; scope: string }) => {
					try {
						await googleAppConnect({
							app: app as GoogleAppType,
							clientData: response,
						});
						await refetchUser();

						window.postMessage({
							type: UPDATE_INTEGRATIONS,
							data: app.id,
						});
						// @ts-ignore
						if (chrome.runtime?.sendMessage) {
							// @ts-ignore
							chrome.runtime.sendMessage(
								process.env.REACT_APP_PUBLIC_EXTENSION_ID,
								'app-connected',
							);
						}
					} catch (e) {
						console.error(e);
					}
				},
			})
			.requestCode();
	};

	const connectGoogleAppBatch = async (apps: GoogleAppType[]) => {
		// @ts-ignore
		window.google.accounts.oauth2
			.initCodeClient({
				client_id: apps.find((app) => app.client_id)?.client_id,
				scope: apps.map((app) => app.scopes.join(' ')).join(' '),
				ux_mode: 'popup',
				callback: async (response: { code: string; scope: string }) => {
					try {
						const appIds = apps.map((app) => app.id.toUpperCase());
						await googleAppBatchConnect({
							clientData: response,
							appIds: appIds,
						});
						await refetchUser();
						window.postMessage({
							type: UPDATE_INTEGRATIONS,
							data: 'google',
						});
						// @ts-ignore
						if (chrome.runtime?.sendMessage) {
							// @ts-ignore
							chrome.runtime.sendMessage(
								process.env.REACT_APP_PUBLIC_EXTENSION_ID,
								'app-connected',
							);
						}
					} catch (e) {
						console.error(e);
					}
				},
			})
			.requestCode();
	};

	const disconnectApp = async (
		app: AppType | GoogleAppType,
	): Promise<{ isSuccess: boolean; status: number }> => {
		try {
			const response = await disconnectIntegration(app.id);
			if ('data' in response) {
				const { isSuccess, status } = response.data;
				window.postMessage({ type: UPDATE_INTEGRATIONS });
				// @ts-ignore
				if (chrome.runtime?.sendMessage) {
					// @ts-ignore
					chrome.runtime.sendMessage(
						process.env.REACT_APP_PUBLIC_EXTENSION_ID,
						'app-disconnected',
					);
				}
				return { isSuccess, status };
			}
			return { isSuccess: false, status: 500 };
		} catch (e) {
			console.error(e);
			return { isSuccess: false, status: 500 };
		}
	};

	const disconnectAllGoogleApps = async () => {
		try {
			const response = await disconnectGoogleApps();
			return { isSuccess: true, status: 200 };
		} catch (e) {
			console.error(e);
			return { isSuccess: false, status: 500 };
		}
	};

	return {
		connectApp,
		connectGoogleApp,
		disconnectApp,
		connectIntegration,
		isDisconnectSuccess,
		isGoogleConnectSuccess,
		isConnectSuccess,
		connectGoogleAppBatch,
		disconnectAllGoogleApps,
	};
}
