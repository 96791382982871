import { useState } from 'react';

import { useDispatch } from 'react-redux';

import { setAccessToken } from '../features/authSlice';
import { TokenUtil } from '../services/token';
import { useTenantConfig } from './useTenantConfig';

export const useRefreshToken = () => {
	const dispatch = useDispatch();
	const { app_url, api_url } = useTenantConfig();
	const [refreshingToken, setRefreshingToken] = useState<boolean>(false);
	const refreshToken = async () => {
		const storedRefreshToken = await TokenUtil.getRefreshToken(app_url);
		if (storedRefreshToken && api_url) {
			try {
				setRefreshingToken(true);
				const response = await fetch(`${api_url}refresh-token`, {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${storedRefreshToken}`,
					},
				});
				if (response.ok) {
					const data = await response.json();
					await TokenUtil.setAccessToken(data?.access_token, app_url); // storing in cookie
					dispatch(setAccessToken(data.accessToken)); // storing in redux
					setRefreshingToken(false);
				} else {
					setRefreshingToken(false);
					console.error('Failed to refresh token');
				}
			} catch (error) {
				setRefreshingToken(false);
				console.error('Error refreshing token:', error);
			}
		}
	};
	return { refreshToken, refreshingToken };
};
