import {
	useAddAutopilotMutation,
	useDeleteAutopilotMutation,
	useGetIndexingStatusQuery,
	useTestRunMutation,
	useUpdateAutopilotMutation,
} from '../api/autopilotsApi';
import { selectAutopilots } from '../features/autopilotsSlice';

export function useAutopilot() {
	const [addAutopilot, { isSuccess: isAddSuccess, isError: isAddError }] =
		useAddAutopilotMutation();

	const [
		updateAutopilot,
		{ isSuccess: isUpdateSuccess, isError: isUpdateError },
	] = useUpdateAutopilotMutation();

	const [
		deleteAutopilot,
		{ isSuccess: isDeleteSuccess, isError: isDeleteError },
	] = useDeleteAutopilotMutation();

	const [testRun, { isSuccess: isTestRunSuccess, isError: isTestRunError }] =
		useTestRunMutation();

	return {
		addAutopilot,
		updateAutopilot,
		deleteAutopilot,
		selectAutopilots,
		isAddSuccess,
		isUpdateSuccess,
		isDeleteSuccess,
		isAddError,
		isUpdateError,
		isDeleteError,
		testRun,
		isTestRunSuccess,
		isTestRunError,
	};
}
