import { ONBOARDING_KEY } from '../constants';
import { api } from './base';
import { OnboardingResponse } from './types';

export const onboardingDetailsApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getOnboardingData: builder.query<OnboardingResponse, void>({
			queryFn: () => {
				const onboardingDataJSON = localStorage.getItem(ONBOARDING_KEY);
				const onboardingData = onboardingDataJSON
					? JSON.parse(onboardingDataJSON)
					: null;
				return onboardingData;
			},
		}),
		updateOnboardingData: builder.query<OnboardingResponse, void>({
			queryFn: (data: any) => {
				const onboardingDataJSON = localStorage.getItem(ONBOARDING_KEY);
				const onboardingData = onboardingDataJSON
					? JSON.parse(onboardingDataJSON)
					: null;
				if (!onboardingData) return;
				const updatedDetails = { ...onboardingData, ...data };
				localStorage.setItem(ONBOARDING_KEY, JSON.stringify(updatedDetails));
				return updatedDetails;
			},
		}),
	}),
});

export const { useGetOnboardingDataQuery, useUpdateOnboardingDataQuery } =
	onboardingDetailsApi;
