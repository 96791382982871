export const ANALYTICS_EVENTS = {
	// Auth & User events
	USER_SIGNED_UP: 'user_signed_up',
	USER_LOGGED_IN: 'user_logged_in',
	USER_LOGGED_OUT: 'user_logged_out',

	// Navigation events
	PAGE_VIEW: 'page_viewed',
	NAVIGATION_CLICKED: 'navigation_clicked',

	// Meeting events
	MEETING_VIEWED: 'meeting_viewed',
	MEETING_JOINED: 'meeting_joined',
	MEETING_LEFT: 'meeting_left',

	// Chat events
	CHAT_STARTED: 'chat_started',
	CHAT_MESSAGE_SENT: 'chat_message_sent',
	CHAT_COMPLETED: 'chat_completed',
	CHAT_MESSAGE_RECEIVED: 'chat_message_received',
	CHAT_ERROR_OCCURRED: 'chat_error_occurred',
	CHAT_STREAMING_STARTED: 'chat_streaming_started',
	CHAT_STREAMING_COMPLETED: 'chat_streaming_completed',
	CHAT_STREAMING_ERROR: 'chat_streaming_error',

	// Settings events
	SETTINGS_UPDATED: 'settings_updated',
	NOTIFICATION_SETTINGS_CHANGED: 'notification_settings_changed',
	SETTINGS_PAGE_VIEWED: 'settings_page_viewed',
	SETTINGS_TAB_CHANGED: 'settings_tab_changed',
	SETTINGS_CONNECTION_ADDED: 'settings_connection_added',
	SETTINGS_CONNECTION_REMOVED: 'settings_connection_removed',
	SETTINGS_NOTIFICATION_CHANGED: 'settings_notification_changed',
	SETTINGS_TEAM_UPDATED: 'settings_team_updated',

	// Feature usage events
	FEATURE_USED: 'feature_used',
	FILTER_APPLIED: 'filter_applied',

	// Error events
	ERROR_OCCURRED: 'error_occurred',

	// Home Feed events
	HOME_FEED_VIEWED: 'home_feed_viewed',
	HOME_FEED_FILTERED: 'home_feed_filtered',
	HOME_FEED_ITEM_CLICKED: 'home_feed_item_clicked',
	HOME_FEED_ITEM_DISMISSED: 'home_feed_item_dismissed',
	HOME_VIEW_MODE_CHANGED: 'home_view_mode_changed',

	// Search events
	SEARCH_STARTED: 'search_started',
	SEARCH_PERFORMED: 'search_performed',
	SEARCH_RESULTS_VIEWED: 'search_results_viewed',
	SEARCH_FILTERED: 'search_filtered',
	SEARCH_RESULT_CLICKED: 'search_result_clicked',
	SEARCH_NO_RESULTS: 'search_no_results',
	SEARCH_ERROR: 'search_error',

	// Meetings events
	MEETINGS_LIST_VIEWED: 'meetings_list_viewed',
	MEETING_SELECTED: 'meeting_selected',
	MEETING_FILTERED: 'meeting_filtered',
	MEETING_BULK_ACTION: 'meeting_bulk_action',
	MEETING_CHAT_STARTED: 'meeting_chat_started',

	// People events
	PEOPLE_LIST_VIEWED: 'people_list_viewed',
	PEOPLE_SELECTED: 'people_selected',
	PEOPLE_FILTERED: 'people_filtered',
	PEOPLE_BULK_ACTION: 'people_bulk_action',
	PEOPLE_CHAT_STARTED: 'people_chat_started',

	// Company events
	COMPANY_LIST_VIEWED: 'company_list_viewed',
	COMPANY_SELECTED: 'company_selected',
	COMPANY_FILTERED: 'company_filtered',
	COMPANY_BULK_ACTION: 'company_bulk_action',
	COMPANY_CHAT_STARTED: 'company_chat_started',

	// Deals events
	DEALS_LIST_VIEWED: 'deals_list_viewed',
	DEAL_SELECTED: 'deal_selected',
	DEAL_FILTERED: 'deal_filtered',
	DEAL_BULK_ACTION: 'deal_bulk_action',
	DEAL_CHAT_STARTED: 'deal_chat_started',

	// Navigation events
	NAV_ITEM_CLICKED: 'nav_item_clicked',
	NAV_MENU_TOGGLED: 'nav_menu_toggled',
	NAV_PROFILE_OPENED: 'nav_profile_opened',
	NAV_MOBILE_MENU_OPENED: 'nav_mobile_menu_opened',
} as const;

export type AnalyticsEvent =
	(typeof ANALYTICS_EVENTS)[keyof typeof ANALYTICS_EVENTS];

export const ANALYTICS_PROPERTIES = {
	// Common properties
	SOURCE: 'source',
	WORKSPACE: 'workspace',
	PLATFORM: 'platform',

	// Feature properties
	FEATURE_NAME: 'feature_name',
	FEATURE_LOCATION: 'feature_location',

	// Error properties
	ERROR_TYPE: 'error_type',
	ERROR_MESSAGE: 'error_message',

	// Navigation properties
	PAGE_NAME: 'page_name',
	PREVIOUS_PAGE: 'previous_page',

	// User properties
	USER_TYPE: 'user_type',
	USER_ROLE: 'user_role',
	ACCOUNT_AGE: 'account_age',

	// Home Feed properties
	VIEW_MODE: 'view_mode',
	FEED_ITEM_TYPE: 'feed_item_type',
	FEED_ITEM_SOURCE: 'feed_item_source',
	FILTER_TYPE: 'filter_type',
	FILTER_VALUE: 'filter_value',
	ITEMS_COUNT: 'items_count',

	// Search properties
	SEARCH_QUERY: 'search_query',
	SEARCH_TYPE: 'search_type',
	RESULTS_COUNT: 'results_count',
	SEARCH_DURATION: 'search_duration',
	SELECTED_FILTERS: 'selected_filters',
	RESULT_POSITION: 'result_position',
	RESULT_TYPE: 'result_type',

	// Chat properties
	MESSAGE_TYPE: 'message_type',
	MESSAGE_LENGTH: 'message_length',
	CHAT_DURATION: 'chat_duration',
	STREAMING_DURATION: 'streaming_duration',
	ERROR_DETAILS: 'error_details',

	// Meetings properties
	MEETINGS_COUNT: 'meetings_count',
	BULK_ACTION_TYPE: 'bulk_action_type',
	SELECTED_COUNT: 'selected_count',
	MEETING_TYPE: 'meeting_type',

	// Settings properties
	SETTINGS_TAB: 'settings_tab',
	SETTINGS_ACTION: 'settings_action',
	SETTINGS_VALUE: 'settings_value',
	CONNECTION_TYPE: 'connection_type',
	CONNECTION_STATUS: 'connection_status',

	// People properties
	PEOPLE_TYPE: 'people_type',
	PEOPLE_COUNT: 'people_count',

	// Company properties
	COMPANY_COUNT: 'company_count',
	COMPANY_TYPE: 'company_type',

	// Deals properties
	DEAL_COUNT: 'deal_count',
	DEAL_TYPE: 'deal_type',

	// Navigation properties
	NAV_ITEM_NAME: 'nav_item_name',
	NAV_ITEM_TYPE: 'nav_item_type',
	NAV_LOCATION: 'nav_location',
	DEVICE_TYPE: 'device_type',
	MENU_STATE: 'menu_state',
} as const;
