import React from 'react';

import {
	addMonths,
	eachDayOfInterval,
	endOfMonth,
	endOfWeek,
	format,
	isAfter,
	isBefore,
	isSameDay,
	isSameMonth,
	isWithinInterval,
	startOfMonth,
	startOfWeek,
	subMonths,
} from 'date-fns';

import Icon from '../Icon';
import Text from '../Text';
import {
	CalendarContainer,
	CalendarHeader,
	DayButton,
	DaysGrid,
	MonthNavigationButton,
	WeekDaysRow,
} from './styled';

interface CalendarProps {
	selectedStartDate: Date | null;
	selectedEndDate: Date | null;
	onDateSelect: (date: Date) => void;
	currentMonth: Date;
	onMonthChange: (date: Date) => void;
	minDate?: Date;
	maxDate?: Date;
	isLeftCalendar?: boolean;
	isRightCalendar?: boolean;
}

const WEEKDAYS = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

const Calendar: React.FC<CalendarProps> = ({
	selectedStartDate,
	selectedEndDate,
	onDateSelect,
	currentMonth,
	onMonthChange,
	minDate,
	maxDate,
	isLeftCalendar,
	isRightCalendar,
}) => {
	const monthStart = startOfMonth(currentMonth);
	const monthEnd = endOfMonth(monthStart);
	const startDate = startOfWeek(monthStart);
	const endDate = endOfWeek(monthEnd);

	const days = eachDayOfInterval({ start: startDate, end: endDate });

	const isDateInRange = (date: Date) => {
		if (!selectedStartDate || !selectedEndDate) return false;
		return isWithinInterval(date, {
			start: selectedStartDate,
			end: selectedEndDate,
		});
	};

	const isDateSelected = (date: Date) =>
		(selectedStartDate && isSameDay(date, selectedStartDate)) ||
		(selectedEndDate && isSameDay(date, selectedEndDate));

	const isDateDisabled = (date: Date) => {
		if (minDate && isBefore(date, minDate)) return true;
		if (maxDate && isAfter(date, maxDate)) return true;
		return false;
	};

	const isMonthNavigationDisabled = (direction: 'prev' | 'next') => {
		if (direction === 'prev' && isRightCalendar) return true;
		if (direction === 'next' && isLeftCalendar) return true;
		return false;
	};

	return (
		<CalendarContainer>
			<CalendarHeader>
				<MonthNavigationButton
					onClick={() =>
						!isMonthNavigationDisabled('prev') &&
						onMonthChange(subMonths(currentMonth, 1))
					}
					disabled={isMonthNavigationDisabled('prev')}
				>
					<Icon.CaretLeft size={16} fill="var(--mb-gray-9)" />
				</MonthNavigationButton>
				<Text weight="medium">{format(currentMonth, 'MMMM yyyy')}</Text>
				<MonthNavigationButton
					onClick={() =>
						!isMonthNavigationDisabled('next') &&
						onMonthChange(addMonths(currentMonth, 1))
					}
					disabled={isMonthNavigationDisabled('next')}
				>
					<Icon.CaretRight size={16} fill="var(--mb-gray-9)" />
				</MonthNavigationButton>
			</CalendarHeader>

			<WeekDaysRow>
				{WEEKDAYS.map((day) => (
					<Text key={day} style={{ fontSize: 12, color: 'var(--mb-gray-8)' }}>
						{day}
					</Text>
				))}
			</WeekDaysRow>

			<DaysGrid>
				{days.map((date) => {
					const isCurrentMonth = isSameMonth(date, currentMonth);
					const isSelected = isDateSelected(date);
					const isInRange = isDateInRange(date);
					const disabled = isDateDisabled(date);

					return (
						<DayButton
							key={date.toISOString()}
							onClick={() => !disabled && onDateSelect(date)}
							selected={isSelected ?? false}
							isinrange={isInRange ? 'true' : 'false'}
							disabled={disabled ?? false}
							isdimmed={!isCurrentMonth ? 'true' : 'false'}
							israngestart={
								(selectedStartDate && isSameDay(date, selectedStartDate)) ??
								false
							}
							israngeend={
								(selectedEndDate && isSameDay(date, selectedEndDate)) ?? false
							}
						>
							{format(date, 'd')}
						</DayButton>
					);
				})}
			</DaysGrid>
		</CalendarContainer>
	);
};

export default Calendar;
