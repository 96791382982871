import * as RadixAccordion from '@radix-ui/react-accordion';

import * as Styled from './styled';

type AccordionType = typeof RadixAccordion & {
	Content: typeof Styled.Content;
};

const Accordion: AccordionType = {
	...RadixAccordion,
	Content: Styled.Content,
};

export default Accordion;
