import * as RadixCollapsible from '@radix-ui/react-collapsible';
import styled, { keyframes } from 'styled-components';

const slideDown = keyframes`
	from {
		height: 0;
	}
	to {
		height: var(--radix-collapsible-content-height);
	}
`;

const slideUp = keyframes`
	from {
		height: var(--radix-collapsible-content-height);
	}
	to {
		height: 0;
	}
`;

export const Content = styled(RadixCollapsible.Content)`
	overflow: hidden;

	&[data-state='open'] {
		animation: ${slideDown} 300ms ease-out;
	}

	&[data-state='closed'] {
		animation: ${slideUp} 300ms ease-out;
	}
`;
