import { useState } from 'react';

import {
	useCreateTaskMutation,
	useDeleteTaskMutation,
	useGetTaskQuery,
	useListTasksQuery,
	useUpdateTaskMutation,
} from '../api/tasksApi';
import { CollectionType } from '../commons/types';
import { CreateTaskData, UpdateTaskData } from './types';

interface UseTasksListProps {
	collectionId?: string;
	collectionType?: CollectionType;
	page?: number;
	pageSize?: number;
}

interface UseTasksProps {
	taskId?: string;
	collectionId?: string;
	collectionType?: CollectionType;
	skip?: boolean;
}

// Separate hook for list functionality
export const useTasksList = ({
	collectionId,
	collectionType,
	page = 1,
	pageSize = 10,
}: UseTasksListProps = {}) => {
	const {
		data: taskList,
		isLoading,
		error: fetchError,
		isFetching,
	} = useListTasksQuery(
		{
			collection_id: collectionId ?? '',
			collection_type: collectionType ? [collectionType] : undefined,
			page,
			page_size: pageSize,
		},
		{ skip: !collectionId || !collectionType },
	);

	return {
		tasks: taskList?.items ?? [],
		hasMore: taskList?.has_more ?? false,
		total: taskList?.total ?? 0,
		isLoading,
		fetchError,
		isFetching,
	};
};

// Main hook for CRUD operations
export const useTasks = ({
	taskId,
	collectionId,
	skip = false,
}: UseTasksProps = {}) => {
	const [error, setError] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState(false);

	const { data: task, isLoading: isFetchingTask } = useGetTaskQuery(
		taskId ?? '',
		{
			skip: !taskId,
		},
	);

	const [createTask] = useCreateTaskMutation();
	const [updateTask] = useUpdateTaskMutation();
	const [deleteTask] = useDeleteTaskMutation();

	const handleCreateTask = async (data: CreateTaskData) => {
		setError(null);
		setIsLoading(true);
		try {
			const response = await createTask(data).unwrap();
			return response;
		} catch (err) {
			setError(err instanceof Error ? err.message : 'Failed to create task');
			throw err;
		} finally {
			setIsLoading(false);
		}
	};

	const handleUpdateTask = async (taskId: string, data: UpdateTaskData) => {
		setError(null);
		setIsLoading(true);
		try {
			const response = await updateTask({ taskId, body: data }).unwrap();
			return response;
		} catch (err) {
			setError(err instanceof Error ? err.message : 'Failed to update task');
			throw err;
		} finally {
			setIsLoading(false);
		}
	};

	const handleDeleteTask = async (taskId: string) => {
		setError(null);
		setIsLoading(true);
		try {
			await deleteTask(taskId).unwrap();
		} catch (err) {
			setError(err instanceof Error ? err.message : 'Failed to delete task');
			throw err;
		} finally {
			setIsLoading(false);
		}
	};

	return {
		task,
		error,
		isLoading: isLoading || isFetchingTask,
		createTask: handleCreateTask,
		updateTask: handleUpdateTask,
		deleteTask: handleDeleteTask,
	};
};
