import { UUID } from 'crypto';

import { contentApi } from '../api/base';
import {
	useGetConversationByIdQuery,
	useGetConversationsQuery,
	useStoreConversationMutation,
	useUpdateConversationMutation,
} from '../api/conversationApi';
import {
	StoreConversationRequest,
	UpdateConversationRequest,
} from '../api/types';

export function useConversation(conversationId?: UUID, { personId, companyId, skipConversations = false }: { personId?: UUID, companyId?: UUID, skipConversations?: boolean } = {}) {
	const [storeConversation, { isLoading: isStoring, isError: isStoreError }] =
		useStoreConversationMutation();
	const [
		updateConversation,
		{ isLoading: isUpdating, isError: isUpdateError },
	] = useUpdateConversationMutation();
	const {
		data: conversations,
		isLoading: isLoadingConversations,
		refetch: refetchConversations,
	} = useGetConversationsQuery({ personId, companyId }, {
		skip: skipConversations,
	});
	const {
		data: conversation,
		isLoading: isLoadingConversation,
		refetch: refetchConversation,
	} = useGetConversationByIdQuery(conversationId as UUID, {
		skip: !conversationId,
	});

	const sendMessage: (
		request: StoreConversationRequest,
	) => Promise<UUID> = async (request: StoreConversationRequest) => {
		try {
			const response = await storeConversation(request).unwrap();
			return response.conversation_id;
		} catch (error) {
			console.error('Failed to store conversation:', error);
			throw error;
		}
	};

	const updateMessage = async (request: UpdateConversationRequest) => {
		if (!conversationId) {
			throw new Error('No conversation ID provided');
		}
		try {
			const response = await updateConversation({
				conversationId,
				body: request,
			}).unwrap();
			return response.conversation;
		} catch (error) {
			console.error('Failed to update conversation:', error);
			throw error;
		}
	};

	const clearConversation = () => {
		if (conversationId) {
			contentApi.util.invalidateTags(['Conversation']);
		}
	};

	return {
		sendMessage,
		updateMessage,
		isLoading: isStoring || isUpdating,
		isError: isStoreError || isUpdateError,
		conversations: conversations?.conversations,
		isLoadingConversations,
		conversation: conversation?.conversation,
		isLoadingConversation,
		refetchConversation,
		refetchConversations,
		clearConversation,
	};
}
