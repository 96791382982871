import { PeopleStatusOptions, PeopleTypeOptions } from '../commons/types';
import { serializeArrayParams } from '../utils/helper';
import { agentApi } from './base';
import {
	Deal,
	FilterOptions,
	GetPersonDataRequest,
	Note,
	PaginatedResponse,
	PeopleListRequest,
	PeopleListResponse,
	Person,
	PersonDataResponse,
	PersonDetailsParams,
	PersonDetailsResponse,
	PersonPaginationParams,
	Task,
	User,
} from './types';

export const personApi = agentApi.injectEndpoints({
	endpoints: (builder) => ({
		// POST /person/
		getPersonData: builder.mutation<PersonDataResponse, GetPersonDataRequest>({
			query: (body) => ({
				url: '/person',
				method: 'POST',
				body,
			}),
		}),

		// GET /person/related
		getRelatedPeople: builder.query<Person[], void>({
			query: () => ({
				url: '/person/related',
				method: 'GET',
			}),
			providesTags: ['RelatedPeople'],
		}),

		// GET /person/relevant/<search_term>
		getRelevantPeople: builder.query<
			Person[],
			{ searchTerm: string; onlyMbUsers: boolean }
		>({
			query: ({ searchTerm, onlyMbUsers }) => ({
				url: `/person/relevant/${searchTerm}`,
				method: 'GET',
				params: { only_mb_users: onlyMbUsers },
			}),
			providesTags: ['RelatedPeople'],
		}),

		// GET /person/recent-contacts/<limit>
		getRecentContacts: builder.query<Person[], number>({
			query: (limit) => ({
				url: `/person/recent-contacts/${limit}`,
				method: 'GET',
			}),
			providesTags: ['RelatedPeople'],
		}),

		// GET /person/list
		getPeopleList: builder.query<PeopleListResponse, PeopleListRequest>({
			query: (params) => {
				const { filterKey, ...queryParams } = params;
				const arrayParams = {
					companies: queryParams.companies || [],
				};

				const baseParams = {
					per_page: queryParams.per_page,
					search: queryParams.search,
					type: queryParams.type,
					page: queryParams.page,
				};

				const arrayParamsString = serializeArrayParams(arrayParams);
				const baseParamsString = new URLSearchParams(
					Object.entries(baseParams)
						.filter(([_, v]) => v != null)
						.map(([k, v]) => [k, v?.toString() ?? '']),
				).toString();

				const queryString = [baseParamsString, arrayParamsString]
					.filter(Boolean)
					.join('&');

				return {
					url: `/person/list${queryString ? `?${queryString}` : ''}`,
					method: 'GET',
				};
			},
			serializeQueryArgs: ({ queryArgs }) => {
				if (!queryArgs) return '';
				return queryArgs.filterKey || '';
			},
			merge: (currentCache, newItems, { arg }) => {
				if (!arg || arg.page === 1) {
					return newItems;
				}
				return {
					...newItems,
					people: [...(currentCache?.people || []), ...newItems.people].filter(
						(person, index, self) =>
							index === self.findIndex((p) => p.id === person.id),
					),
				};
			},
			forceRefetch: ({ currentArg, previousArg }) => {
				if (!currentArg || !previousArg) return true;
				if (currentArg.page !== previousArg.page) {
					return true;
				}

				if (currentArg.filterKey !== previousArg.filterKey) {
					return true;
				}

				return false;
			},
			providesTags: ['PeopleList'],
		}),

		// GET /person/filter-options
		getFilterOptions: builder.query<FilterOptions, void>({
			query: () => ({
				url: '/person/filter-options',
				method: 'GET',
			}),
			providesTags: ['PeopleList'],
		}),

		// GET /person/<person_id>
		getPersonById: builder.query<
			Person & { is_mb_user: boolean; profile_pic?: string },
			string
		>({
			query: (personId) => ({
				url: `/person/${personId}`,
				method: 'GET',
			}),
			providesTags: (result, error, personId) => [
				{ type: 'Person', id: personId },
				'PeopleList',
			],
			keepUnusedDataFor: 300,
		}),

		// Add new endpoint for person details
		getPersonDetails: builder.query<
			PersonDetailsResponse,
			{ email: string; params?: PersonDetailsParams }
		>({
			query: ({ email, params = {} }) => ({
				url: `/person/${email}/details`,
				method: 'GET',
				params: {
					include_deals: params.include_deals ?? false,
					include_tasks: params.include_tasks ?? false,
					include_notes: params.include_notes ?? false,
					include_activity: params.include_activity ?? false,
					include_latest_activity: params.include_latest_activity ?? false,
				},
			}),
			providesTags: (result, error, { email }) => [
				{ type: 'Person', id: email },
				'PeopleList',
			],
		}),

		// GET /person/<uuid:person_id>/tasks
		getPersonTasks: builder.query<
			PaginatedResponse<Task>,
			{ person_id: string; params: PersonPaginationParams }
		>({
			query: ({ person_id, params }) => ({
				url: `/person/${person_id}/tasks`,
				method: 'GET',
				params: {
					per_page: params.per_page,
					page: params.page,
				},
			}),
			serializeQueryArgs: ({ queryArgs }) => {
				return `${queryArgs.person_id}-${queryArgs.params?.page}`;
			},
			merge: (currentCache, newItems, { arg: { params } }) => {
				if (params.page === 1) {
					return newItems;
				}
				return {
					...newItems,
					data: [...(currentCache?.data || []), ...newItems.data],
				};
			},
			forceRefetch: ({ currentArg, previousArg }) => {
				if (!previousArg) return true;
				return (
					currentArg?.params.page !== previousArg?.params.page ||
					currentArg?.person_id !== previousArg?.person_id
				);
			},
			providesTags: (result, error, { person_id }) => [
				{ type: 'PersonTasks', id: person_id },
			],
		}),

		// GET /person/<uuid:person_id>/notes
		getPersonNotes: builder.query<
			PaginatedResponse<Note>,
			{ person_id: string; params: PersonPaginationParams }
		>({
			query: ({ person_id, params }) => ({
				url: `/person/${person_id}/notes`,
				method: 'GET',
				params: {
					per_page: params.per_page,
					page: params.page,
				},
			}),
			serializeQueryArgs: ({ queryArgs }) => {
				return `${queryArgs.person_id}-${queryArgs.params?.page}`;
			},
			merge: (currentCache, newItems, { arg: { params } }) => {
				if (params.page === 1) {
					return newItems;
				}
				return {
					...newItems,
					data: [...(currentCache?.data || []), ...newItems.data],
				};
			},
			forceRefetch: ({ currentArg, previousArg }) => {
				if (!previousArg) return true;
				return (
					currentArg?.params.page !== previousArg?.params.page ||
					currentArg?.person_id !== previousArg?.person_id
				);
			},
			providesTags: (result, error, { person_id }) => [
				{ type: 'PersonNotes', id: person_id },
			],
		}),

		// GET /person/<email>/deals
		getPersonDeals: builder.query<
			PaginatedResponse<Deal>,
			{ person_id: string; params: PersonPaginationParams }
		>({
			query: ({ person_id, params }) => ({
				url: `/person/${person_id}/deals`,
				method: 'GET',
				params: {
					per_page: params.per_page,
					page: params.page,
				},
			}),
			serializeQueryArgs: ({ queryArgs }) => {
				return queryArgs.person_id;
			},
			merge: (currentCache, newItems, { arg: { params } }) => {
				if (params.page === 1) {
					return newItems;
				}
				return {
					...newItems,
					data: [...(currentCache?.data || []), ...newItems.data],
				};
			},
			forceRefetch: ({ currentArg, previousArg }) => {
				if (!previousArg) return true;
				return currentArg?.params.page !== previousArg?.params.page;
			},
			providesTags: (result, error, { person_id }) => [
				{ type: 'PersonDeals', id: person_id },
			],
		}),

		// POST /person/<person_id>/share
		sharePerson: builder.mutation<
			{ message: string },
			{
				personId: string;
				data: {
					user_emails?: string[];
					enterprise_id?: string;
					operation_on_users: 'add' | 'remove';
				};
			}
		>({
			query: ({ personId, data }) => ({
				url: `/person/${personId}/share`,
				method: 'POST',
				body: data,
			}),
			invalidatesTags: (result, error, { personId }) => [
				{ type: 'Person', id: personId },
			],
		}),

		// GET /person/<person_id>/shared-users
		getPersonSharedUsers: builder.query<
			{
				message: string;
				users: Partial<User>[];
			},
			string
		>({
			query: (personId) => ({
				url: `/person/${personId}/shared-users`,
				method: 'GET',
			}),
			providesTags: (result, error, personId) => [
				{ type: 'PersonSharedUsers', id: personId },
			],
		}),
	}),
});

export const {
	useGetPersonDataMutation,
	useGetRelatedPeopleQuery,
	useGetRelevantPeopleQuery,
	useGetRecentContactsQuery,
	useGetPeopleListQuery,
	useGetFilterOptionsQuery,
	useGetPersonByIdQuery,
	useGetPersonDetailsQuery,
	useGetPersonTasksQuery,
	useGetPersonNotesQuery,
	useGetPersonDealsQuery,
	useSharePersonMutation,
	useGetPersonSharedUsersQuery,
} = personApi;
