import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { User } from '../api/types';
import { TokenUtil } from '../services/token';

export interface AuthState {
	user: Partial<User> | null;
	accessToken: string | null;
	refreshToken: string | null;
	isAuthenticated: boolean;
	isRefreshing: boolean;
}

const initialState: AuthState = {
	accessToken: null,
	refreshToken: null,
	isAuthenticated: false,
	isRefreshing: false,
	user: null,
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setAccessToken: (state, action: PayloadAction<string | null>) => {
			state.accessToken = action.payload;
			state.isAuthenticated = !!action.payload;
		},
		setRefreshToken: (state, action: PayloadAction<string | null>) => {
			state.refreshToken = action.payload;
		},
		setIsRefreshing: (state, action: PayloadAction<boolean>) => {
			state.isRefreshing = action.payload;
		},
		clearTokens: (state) => {
			state.accessToken = null;
			state.refreshToken = null;
			state.isAuthenticated = false;
			state.user = null;
			state.isRefreshing = false;
		},
		setCredentials: (
			state,
			action: PayloadAction<{
				user?: Partial<User>;
				accessToken?: string;
				refreshToken?: string;
			}>,
		) => {
			const { user, accessToken, refreshToken } = action.payload;

			if (user) {
				state.user = { ...state.user, ...user };
			}
			if (accessToken !== undefined) {
				state.accessToken = accessToken;
				state.isAuthenticated = !!accessToken;
			}
			if (refreshToken !== undefined) {
				state.refreshToken = refreshToken;
			}
		},
		logout: (state) => {
			state.user = null;
			state.accessToken = null;
			state.refreshToken = null;
			state.isAuthenticated = false;
		},
	},
});

export const {
	setAccessToken,
	setRefreshToken,
	setIsRefreshing,
	clearTokens,
	setCredentials,
	logout,
} = authSlice.actions;
export default authSlice.reducer;
