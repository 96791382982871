import { serializeArrayParams } from '../utils/helper';
import { agentApi } from './base';
import {
	CreateDealRequest,
	Deal,
	DealFilterOptions,
	DealListRequest,
	DealListResponse,
	UpdateDealRequest,
	PaginatedResponse,
	Note,
	Task,
} from './types';

export const dealsApi = agentApi.injectEndpoints({
	endpoints: (builder) => ({
		// GET /deal/list
		getDeals: builder.query<DealListResponse, DealListRequest>({
			query: (params) => {
				const { filterKey, ...queryParams } = params;
				const arrayParams = {
					stage: queryParams.stage || [],
					pipeline: queryParams.pipeline || [],
					company: queryParams.company || [],
					deal_owner: queryParams.deal_owner || [],
					amount: queryParams.amount || [],
					close_date: queryParams.close_date || [],
				};

				const baseParams = {
					enterprise_id: queryParams.enterprise_id,
					per_page: queryParams.per_page,
					search: queryParams.search,
					page: queryParams.page,
					is_closed: queryParams.is_closed,
					is_won: queryParams.is_won,
				};

				const arrayParamsString = serializeArrayParams(arrayParams);
				const baseParamsString = new URLSearchParams(
					Object.entries(baseParams)
						.filter(([_, v]) => v != null)
						.map(([k, v]) => [k, v?.toString() ?? '']),
				).toString();

				const queryString = [baseParamsString, arrayParamsString]
					.filter(Boolean)
					.join('&');

				return {
					url: `/deal/list${queryString ? `?${queryString}` : ''}`,
					method: 'GET',
				};
			},
			serializeQueryArgs: ({ queryArgs }) => {
				if (!queryArgs) return '';
				return queryArgs.filterKey ?? '';
			},
			merge: (currentCache, newItems, { arg }) => {
				if (!arg || arg.page === 1) {
					return newItems;
				}
				return {
					...newItems,
					deals: [...(currentCache?.deals || []), ...newItems.deals].filter(
						(deal, index, self) =>
							index === self.findIndex((d) => d.id === deal.id),
					),
				};
			},
			forceRefetch: ({ currentArg, previousArg }) => {
				if (!currentArg || !previousArg) return true;

				if (currentArg.page !== previousArg.page) {
					return true;
				}

				const currentFilters = JSON.stringify({
					search: currentArg.search,
					stage: currentArg.stage,
					pipeline: currentArg.pipeline,
					is_closed: currentArg.is_closed,
					is_won: currentArg.is_won,
					enterprise_id: currentArg.enterprise_id,
				});

				const previousFilters = JSON.stringify({
					search: previousArg.search,
					stage: previousArg.stage,
					pipeline: previousArg.pipeline,
					is_closed: previousArg.is_closed,
					is_won: previousArg.is_won,
					enterprise_id: previousArg.enterprise_id,
				});

				return currentFilters !== previousFilters;
			},
			providesTags: (result) =>
				result
					? [
							...result.deals.map(({ id }) => ({
								type: 'Deal' as const,
								id,
							})),
							{ type: 'DealList' as const, id: 'LIST' },
						]
					: [{ type: 'DealList' as const, id: 'LIST' }],
		}),

		// GET /deal/filter-options
		getDealFilterOptions: builder.query<
			DealFilterOptions,
			{ enterprise_id: string }
		>({
			query: ({ enterprise_id }) => ({
				url: '/deal/filter-options',
				method: 'GET',
				params: { enterprise_id },
			}),
			providesTags: ['DealList'],
		}),

		// GET /deal/<deal_id>
		getDeal: builder.query<Deal, { deal_id: string; enterprise_id: string }>({
			query: ({ deal_id, enterprise_id }) => ({
				url: `/deal/${deal_id}`,
				method: 'GET',
				params: { enterprise_id },
			}),
			providesTags: (_result, _error, { deal_id }) => [
				{ type: 'Deal', id: deal_id },
			],
		}),

		// POST /deal
		createDeal: builder.mutation<Deal, CreateDealRequest>({
			query: (body) => ({
				url: '/deal',
				method: 'POST',
				body,
			}),
			invalidatesTags: ['DealList'],
		}),

		// PUT /deal/<deal_id>
		updateDeal: builder.mutation<
			Deal,
			{ deal_id: string; enterprise_id: string; body: UpdateDealRequest }
		>({
			query: ({ deal_id, enterprise_id, body }) => ({
				url: `/deal/${deal_id}`,
				method: 'PUT',
				params: { enterprise_id },
				body,
			}),
			invalidatesTags: (_result, _error, { deal_id }) => [
				'DealList',
				{ type: 'Deal', id: deal_id },
			],
		}),

		// DELETE /deal/<deal_id>
		deleteDeal: builder.mutation<
			void,
			{ deal_id: string; enterprise_id: string }
		>({
			query: ({ deal_id, enterprise_id }) => ({
				url: `/deal/${deal_id}`,
				method: 'DELETE',
				params: { enterprise_id },
			}),
			invalidatesTags: ['DealList'],
		}),

		// GET /deal/<deal_id>/notes
		getDealNotes: builder.query<
			PaginatedResponse<Note>,
			{
				deal_id: string;
				page?: number;
				per_page?: number;
			}
		>( {
			query: ( { deal_id, page = 1, per_page = 40 } ) => ( {
				url: `/deal/${ deal_id }/notes`,
				method: 'GET',
				params: {
					page,
					per_page,
				},
			} ),
			serializeQueryArgs: ( { queryArgs } ) => {
				return `${ queryArgs.deal_id }-${ queryArgs.page }`;
			},
			merge: ( currentCache, newItems, { arg: { page } } ) => {
				if ( page === 1 ) {
					return newItems;
				}
				return {
					...newItems,
					data: [ ...( currentCache?.data || [] ), ...newItems.data ],
				};
			},
			forceRefetch: ( { currentArg, previousArg } ) => {
				if ( !previousArg ) return true;
				return (
					currentArg?.page !== previousArg?.page ||
					currentArg?.deal_id !== previousArg?.deal_id
				);
			},
			providesTags: ( result, error, { deal_id } ) => [
				{ type: 'Deal', id: `${ deal_id }-notes` },
			],
		} ),

		// GET /deal/<deal_id>/tasks
		getDealTasks: builder.query<
			PaginatedResponse<Task>,
			{
				deal_id: string;
				page?: number;
				per_page?: number;
			}
		>( {
			query: ( { deal_id, page = 1, per_page = 40 } ) => ( {
				url: `/deal/${ deal_id }/tasks`,
				method: 'GET',
				params: {
					page,
					per_page,
				},
			} ),
			serializeQueryArgs: ( { queryArgs } ) => {
				return `${ queryArgs.deal_id }-${ queryArgs.page }`;
			},
			merge: ( currentCache, newItems, { arg: { page } } ) => {
				if ( page === 1 ) {
					return newItems;
				}
				return {
					...newItems,
					data: [ ...( currentCache?.data || [] ), ...newItems.data ],
				};
			},
			forceRefetch: ( { currentArg, previousArg } ) => {
				if ( !previousArg ) return true;
				return (
					currentArg?.page !== previousArg?.page ||
					currentArg?.deal_id !== previousArg?.deal_id
				);
			},
			providesTags: ( result, error, { deal_id } ) => [
				{ type: 'Deal', id: `${ deal_id }-tasks` },
			],
		} ),
	}),
});

export const {
	useGetDealsQuery,
	useGetDealFilterOptionsQuery,
	useGetDealQuery,
	useCreateDealMutation,
	useUpdateDealMutation,
	useDeleteDealMutation,
	useGetDealNotesQuery,
	useGetDealTasksQuery,
} = dealsApi;
