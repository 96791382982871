import { v4 as uuid } from 'uuid';

import agentTypesData from '../data/agentTypes.json';
import {
	addConnectedChannel,
	removeConnectedChannel,
	setConnectedChannels,
} from '../features/channelSlice';
import { getSocket as getSocketService } from '../services/socket';
import { sendToServiceWorker } from '../services/worker';
import { RootState } from '../store';
import { getSocketData } from '../utils/helper';
import { api } from './base';
import { chatsApi } from './chatsApi';
import {
	AgentType,
	ChatMessageIncoming,
	ChatMessageOutgoing,
	CreateChannelResponse,
} from './types';

const getSocket = (state: RootState) => {
	const socketData = getSocketData(state);
	return getSocketService(socketData);
};

export const channelApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getAgentTypes: builder.query<AgentType[], void>({
			// query: () => ({ url: 'agent-types' }),
			queryFn: () => {
				return {
					data: agentTypesData as AgentType[],
				};
			},
		}),
		createChannel: builder.mutation<
			CreateChannelResponse,
			{ agentType: string; channelId?: string }
		>({
			query({ agentType, channelId }) {
				return {
					url: '/channel',
					method: 'POST',
					body: {
						agent_type: agentType,
						channel_id: channelId,
						timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
					},
				};
			},
		}),
		joinChannel: builder.mutation<
			{ channel_id: string },
			{ channelId: string; userId: string; chatId?: string }
		>({
			queryFn: async (
				{ channelId, userId, chatId },
				{ dispatch, getState },
			) => {
				const state = getState() as RootState;
				const socket = getSocket(state);
				const emitJoin = () => {
					console.debug('Channel.Join.Started', channelId);
					socket?.emit(
						'join',
						{
							channel_id: channelId,
							user_id: userId,
							user_type: 'USER',
							chat_id: chatId,
						},
						async () => {
							if (chatId) {
								await dispatch(
									chatsApi.endpoints.updateChat.initiate({
										id: chatId,
										status: 'started',
									}),
								);
							}
							console.debug('Channel.Emit.Joined', channelId);

							// TODO: add back when we have a way to detect extension unload or move to background
							// chrome.runtime.onSuspend.addListener(() => {
							// 	socket?.emit(
							// 		'disconnected',
							// 		{
							// 			channel_id: channelId,
							// 			user_id: userId,
							// 		},
							// 		() => {
							// 			console.debug(
							// 				'Channel.Emit.Disconnected[extension unload]',
							// 				channelId,
							// 			);
							// 		},
							// 	);
							// });
						},
					);
				};
				// if (chatId)
				// 	await dispatch(
				// 		chatsApi.endpoints.updateChat.initiate({
				// 			id: chatId,
				// 			status: 'waiting',
				// 		}),
				// 	);
				return new Promise((resolve) => {
					socket?.on('ready', ({ channel_id }: { channel_id: string }) => {
						if (channelId === channel_id) {
							console.debug('Channel.On.Ready', channel_id);
							resolve({ data: { channel_id } });
						}
					});
					emitJoin();
				});
			},
			async onQueryStarted(
				{ channelId, userId, chatId },
				{ dispatch, queryFulfilled, getState },
			) {
				const { data } = await queryFulfilled;
				const state = getState() as RootState;
				const socket = getSocket(state);
				dispatch(addConnectedChannel(data.channel_id));

				sendToServiceWorker('channel_connected', {
					channelId,
					userId,
				});

				socket?.on('disconnected', ({ channel_id }: { channel_id: string }) => {
					if (channelId === channel_id) {
						console.debug('Channel.On.Disconnected', channel_id);
						dispatch(removeConnectedChannel(channel_id));
						(async () => {
							const chat = await dispatch(
								chatsApi.endpoints.getChatByChannelId.initiate(channel_id),
							);
							if (chat.data) {
								await dispatch(
									chatsApi.endpoints.updateChat.initiate({
										id: chat.data?.id,
										status: undefined,
									}),
								);
							}
						})();
						sendToServiceWorker('channel_disconnected', {
							channelId,
							userId,
						});
					}
				});

				socket?.on('disconnect', () => {
					dispatch(setConnectedChannels([]));
					socket?.off('disconnect');
					(async () => {
						const chats = await dispatch(
							chatsApi.endpoints.getChats.initiate(),
						);
						const chatsToUpdate = chats.data?.filter(
							(chat) => chat.status === 'waiting' || chat.status === 'started',
						);
						if (!chatsToUpdate) return { data: [] };
						await dispatch(
							chatsApi.endpoints.bulkUpdateChats.initiate({
								chats: chatsToUpdate,
								patch: { status: undefined },
							}),
						);
					})();
					sendToServiceWorker('all_channels_disconnected', {
						channelId,
						userId,
					});
				});
			},
		}),
		leaveChannel: builder.mutation<void, { channelId: string; userId: string }>(
			{
				queryFn: async ({ channelId, userId }, { getState }) => {
					const state = getState() as RootState;
					const socket = getSocket(state);

					return new Promise((resolve) => {
						socket?.emit(
							'disconnected',
							{
								channel_id: channelId,
								user_id: userId,
							},
							() => {
								console.debug('Channel.Emit.Disconnected[leave]', channelId);
								resolve({ data: undefined });
							},
						);
					});
				},
				async onQueryStarted(
					{ channelId, userId },
					{ dispatch, queryFulfilled },
				) {
					await queryFulfilled;
					dispatch(removeConnectedChannel(channelId));

					sendToServiceWorker('channel_disconnected', {
						channelId,
						userId,
					});
				},
			},
		),
		sendChannelMessage: builder.mutation<
			ChatMessageOutgoing,
			ChatMessageOutgoing
		>({
			queryFn: async (chatMessage: ChatMessageOutgoing, { getState }) => {
				const state = getState() as RootState;
				const socket = getSocket(state);

				return new Promise((resolve) => {
					socket?.emit('message', chatMessage, () => {
						console.debug('Channel.Emit.Message', chatMessage);
						resolve({ data: chatMessage });
					});
				});
			},
			async onQueryStarted(
				chatMessage,
				{ dispatch, queryFulfilled, getState },
			) {
				await queryFulfilled;
				// const state = getState() as RootState;
				// const ws_url = state.tenantConfigState.ws_url;
				if (chatMessage.type !== 'LABEL') {
					await dispatch(
						chatsApi.util.updateQueryData(
							'getChatMessages',
							{
								channelId: chatMessage.channel_id,
								chatId: chatMessage.conversation_id,
							},
							(draft) => {
								draft.push({
									...(chatMessage as ChatMessageIncoming),
									parent_id: undefined,
									message_id: uuid(),
								});
							},
						),
					);

					// set chat title to first message
					const chat = await dispatch(
						chatsApi.endpoints.getChat.initiate(chatMessage.conversation_id),
					);

					if (chat?.data?.title === 'Untitled chat') {
						await dispatch(
							chatsApi.endpoints.updateChat.initiate({
								id: chatMessage.conversation_id,
								title: chatMessage.content,
							}),
						);
					}
				}
			},
			invalidatesTags: (result) =>
				result ? [{ type: 'Chats', id: result.conversation_id }] : [],
		}),
	}),
});

export const {
	useGetAgentTypesQuery,
	useCreateChannelMutation,
	useJoinChannelMutation,
	useLeaveChannelMutation,
	useSendChannelMessageMutation,
} = channelApi;
