import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface StreamingState {
	streamingMessage: string;
	isStreaming: boolean;
}

const initialState: StreamingState = {
	streamingMessage: '',
	isStreaming: false,
};

export const streamingSlice = createSlice({
	name: 'streaming',
	initialState,
	reducers: {
		appendChunk: (state, action: PayloadAction<string>) => {
			state.streamingMessage += action.payload;
		},
		startStreaming: (state) => {
			state.streamingMessage = '';
			state.isStreaming = true;
		},
		endStreaming: (state) => {
			state.isStreaming = false;
		},
	},
});

export const { appendChunk, startStreaming, endStreaming } =
	streamingSlice.actions;
export default streamingSlice.reducer;
