import { UUID } from 'crypto';

import { contentApi } from './base';
import {
	GetConversationByIdResponse,
	GetConversationsResponse,
	StoreConversationRequest,
	StoreConversationResponse,
	UpdateConversationRequest,
} from './types';

export interface GetConversationsParams {
	personId?: UUID;
	companyId?: UUID;
}

const conversationApi = contentApi.injectEndpoints({
	endpoints: (builder) => ({
		storeConversation: builder.mutation<
			StoreConversationResponse,
			StoreConversationRequest
		>({
			query: (body) => ({
				url: 'conversation/',
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Conversation'],
		}),
		getConversations: builder.query<GetConversationsResponse, GetConversationsParams | void>({
			query: (params) => {
				// Build query parameters if provided
				const queryParams = new URLSearchParams();

				if (params) {
					if (params.personId) {
						queryParams.append('person_id', params.personId.toString());
					}
					if (params.companyId) {
						queryParams.append('company_id', params.companyId.toString());
					}
				}

				const queryString = queryParams.toString();

				return {
					url: `conversation/conversations${queryString ? `?${queryString}` : ''}`,
					method: 'GET',
				};
			},
			providesTags: ['Conversation'],

		}),
		getConversationById: builder.query<GetConversationByIdResponse, UUID>({
			query: (conversationId) => ({
				url: `conversation/${conversationId}`,
				method: 'GET',
			}),
			providesTags: (result, error, id) => [{ type: 'Conversation', id }],
		}),
		updateConversation: builder.mutation<
			GetConversationByIdResponse,
			{ conversationId: UUID; body: UpdateConversationRequest }
		>({
			query: ({ conversationId, body }) => ({
				url: `conversation/${conversationId}`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: (result, error, { conversationId }) => [
				{ type: 'Conversation', id: conversationId },
				'Conversation',
			],
		}),
	}),
	overrideExisting: false,
});

export const {
	useStoreConversationMutation,
	useGetConversationsQuery,
	useGetConversationByIdQuery,
	useUpdateConversationMutation,
} = conversationApi;
