// TODO: use this to wrap all calls to Sentry within core package
import * as Sentry from '@sentry/browser';

import { PosthogUtil } from './posthog';

const message = (message: string) => {
	Sentry.captureMessage(message);
};

const error = (error: Error) => {
	Sentry.captureException(error);
};

const user = (user: { email: string; id: string }) => {
	Sentry.setUser(user);
	PosthogUtil.identifyUser(user.id, { email: user.email });
};

export const logger = {
	message,
	error,
	user,
};
