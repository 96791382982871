import skillsData from '../data/skills.json';
import { api } from './base';
import { Skill, SkillsResponse } from './types';

export const skillsApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getSkills: builder.query<SkillsResponse, void>({
			// query: () => ({ url: 'skills' }),
			queryFn: () => {
				return {
					data: skillsData as Skill[],
				};
			},
		}),
	}),
});

export const { useGetSkillsQuery } = skillsApi;
