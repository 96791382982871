import { useGetCompanyFilterOptionsQuery } from '../api/companyApi';

export const useCompanyFilterOptions = () => {
	const { data, isLoading, error } = useGetCompanyFilterOptionsQuery();

	return {
		data,
		isLoading,
		error,
	};
};
