import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';

import debounce from 'lodash/debounce';
import uniq from 'lodash/uniq';

import { getElementCoordinates } from '../../util/helper';
import Flex from '../Flex/Flex';
import { X } from '../Icon/Icon';
import Text from '../Text/Text';
import TextArea from '../TextArea/TextArea';

const autoSuggestIdentifierMap: Record<
	string,
	{ open: string; close: string }
> = {
	'{': {
		open: '{',
		close: '}',
	},
	'(': {
		open: '(',
		close: ')',
	},
};

interface DynamicTextAreaProps {
	textAreaId?: string;
	placeholder?: string;
	name?: string;
	defaultValue?: string;
	value?: string;
	disabled?: boolean;
	onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
	style?: React.CSSProperties;
	maxLines?: number;
	showDeleteTextArea?: boolean;
	onDelete?: () => void;
	requiredVariables?: string[];
	optionalVariables?: string[];
	enableAutoSuggest?: boolean;
	autoSuggestIdentifier?: string;
}
const DynamicTextArea = forwardRef<HTMLTextAreaElement, DynamicTextAreaProps>(
	(
		{
			textAreaId,
			placeholder,
			name,
			defaultValue,
			value: externalValue,
			disabled,
			onChange,
			style,
			maxLines,
			showDeleteTextArea,
			onDelete,
			requiredVariables = [],
			optionalVariables = [],
			enableAutoSuggest = false,
			autoSuggestIdentifier = autoSuggestIdentifierMap['{'].open,
		}: DynamicTextAreaProps,
		ref,
	) => {
		const inputRef = useRef<HTMLTextAreaElement>(null);
		const suggestionsRef = useRef<HTMLDivElement>(null);

		const [height, setHeight] = useState(102);
		const [value, setValue] = useState(externalValue ?? '');

		const [suggestions, setSuggestions] = useState<string[]>([]);
		const [showSuggestions, setShowSuggestions] = useState(false);
		const [cursorPosition, setCursorPosition] = useState(0);
		const [suggestionPosition, setSuggestionPosition] = useState({
			top: 0,
			left: 0,
		});
		const [highlightedIndex, setHighlightedIndex] = useState(0);

		const combinedVariables = useMemo(
			() => uniq([...requiredVariables, ...optionalVariables]),
			[requiredVariables, optionalVariables],
		);

		useEffect(() => {
			if (inputRef.current) {
				inputRef.current.focus();
			}
		}, []);

		useEffect(() => {
			if (value) {
				if (inputRef.current) {
					adjustHeight(inputRef.current);
				}
			}
		}, [value]);

		const adjustHeight = useMemo(
			() =>
				debounce((textarea: HTMLTextAreaElement) => {
					const lineHeight = 20;
					const minHeight = 102;
					const maxHeight = lineHeight * (maxLines ?? 10);

					textarea.style.height = 'auto';
					const newHeight = Math.max(
						minHeight,
						Math.min(textarea.scrollHeight, maxHeight),
					);
					textarea.style.height = `${newHeight}px`;
					setHeight(newHeight);
				}, 10),
			[maxLines],
		);

		useEffect(() => {
			if (inputRef.current) {
				adjustHeight(inputRef.current);
			}
		}, [value, adjustHeight]);

		const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> = (
			event,
		) => {
			setValue(event.target.value);
			onChange?.(event);
			if (inputRef.current) {
				adjustHeight(inputRef.current);
			}
		};

		useEffect(() => {
			if (externalValue !== undefined) {
				setValue(externalValue);
				if (inputRef.current) {
					adjustHeight(inputRef.current);
				}
			}
		}, [externalValue]);

		useEffect(() => {
			const handleClickOutside = (event: MouseEvent) => {
				if (
					inputRef.current &&
					!inputRef.current.contains(event.target as Node)
				) {
					inputRef.current.style.height = '102px';
					setHeight(102);
				}
				if (
					suggestionsRef.current &&
					!suggestionsRef.current.contains(event.target as Node)
				) {
					setShowSuggestions(false);
				}
			};

			if (inputRef.current) {
				inputRef.current.addEventListener('click', () => {
					adjustHeight(inputRef.current!);
				});
			}

			document.addEventListener('mousedown', handleClickOutside);
			return () => {
				document.removeEventListener('mousedown', handleClickOutside);
			};
		}, []);

		const getFilterValue = useMemo(() => {
			return (value: string) => {
				const lastOpenBraceIndex = value.lastIndexOf(autoSuggestIdentifier);
				return {
					value: value.slice(lastOpenBraceIndex + 1),
					index: lastOpenBraceIndex + 1,
				};
			};
		}, [value, autoSuggestIdentifier]);

		const handleInputChange: React.ChangeEventHandler<HTMLTextAreaElement> = (
			event,
		) => {
			const newValue = event.target.value;
			setValue(newValue);
			handleChange(event);
			adjustHeight(event.target);

			const cursorPos = event.target.selectionStart;
			setCursorPosition(cursorPos);

			const filterValue = getFilterValue(newValue);

			if (filterValue.index !== -1) {
				// Extract the substring from the last '{' to the cursor position
				const filteredSuggestions = combinedVariables.filter((variable) =>
					variable.toLowerCase().includes(filterValue.value.toLowerCase()),
				);
				setSuggestions(filteredSuggestions);
				setShowSuggestions(filteredSuggestions.length > 0);
				setSuggestionPosition(
					getElementCoordinates(inputRef.current!, cursorPos),
				);
				setHighlightedIndex(0); // Highlight the first suggestion
			} else {
				setShowSuggestions(false);
			}
		};

		const handleSuggestionClick = (suggestion: string) => {
			const filterValue = getFilterValue(value);
			const newValue =
				value.slice(0, cursorPosition) +
				suggestion.replace(filterValue.value, '') +
				value.slice(cursorPosition) +
				autoSuggestIdentifierMap[autoSuggestIdentifier].close +
				' ';
			setValue(newValue);
			const syntheticEvent = {
				target: { value: newValue },
			} as React.ChangeEvent<HTMLTextAreaElement>;

			handleInputChange(syntheticEvent);
			setShowSuggestions(false);
			inputRef.current?.focus();
		};

		const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
			if (showSuggestions) {
				if (event.key === 'ArrowDown') {
					event.preventDefault();
					setHighlightedIndex(
						(prevIndex) => (prevIndex + 1) % suggestions.length,
					);
				} else if (event.key === 'ArrowUp') {
					event.preventDefault();
					setHighlightedIndex(
						(prevIndex) =>
							(prevIndex - 1 + suggestions.length) % suggestions.length,
					);
				} else if (event.key === 'Enter') {
					event.preventDefault();
					handleSuggestionClick(suggestions[highlightedIndex]);
				}
			}
		};

		return (
			<Flex style={{ position: 'relative', width: '100%' }}>
				<TextArea
					id={textAreaId}
					name={name}
					placeholder={placeholder}
					defaultValue={defaultValue}
					value={value}
					disabled={disabled}
					onChange={handleInputChange}
					style={{
						...style,
						height: `${height}px`,
						resize: 'none',
						overflow: 'hidden',
					}}
					ref={inputRef}
					onKeyDown={handleKeyDown}
				/>
				{enableAutoSuggest && showSuggestions && (
					<Flex
						direction="column"
						id="suggestions-container"
						style={{
							position: 'absolute',
							top: suggestionPosition.top + 25,
							left: suggestionPosition.left,
							background: 'white',
							border: '1px solid var(--gray-5)',
							zIndex: 1000,
							borderRadius: '4px',
							padding: '8px 0px',
						}}
						ref={suggestionsRef}
					>
						{suggestions.map((suggestion, index) => (
							<Flex
								justify="between"
								align="center"
								key={index}
								style={{
									cursor: 'pointer',
									padding: '8px',
									backgroundColor:
										index === highlightedIndex ? 'var(--gray-7)' : 'white',
								}}
								onClick={() => handleSuggestionClick(suggestion)}
							>
								<Text>{suggestion}</Text>
							</Flex>
						))}
					</Flex>
				)}
				<Flex
					align="center"
					justify="center"
					style={{
						height: `${height}px`,
						borderRadius: '0px 4px 4px 0px',
						background: 'var(--gray-3)',
						width: '30px',
						border: '1px solid var(--gray-5)',
						borderLeft: 'none',
						display: showDeleteTextArea ? 'flex' : 'none',
					}}
					onClick={() => {
						onDelete?.();
					}}
				>
					<X size="22px" style={{ cursor: 'pointer' }} />
				</Flex>
			</Flex>
		);
	},
);

export default DynamicTextArea;
