import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { setAccessToken } from '../features/authSlice';
import { TokenUtil } from '../services/token';

export const useInitAuth = () => {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const initAuth = async () => {
			setIsLoading(true);
			const token = await TokenUtil.getAccessToken();
			if (token) {
				dispatch(setAccessToken(token));
			}
			setIsLoading(false);
		};

		void initAuth();
	}, []);

	return { isLoading };
};
