import { UUID } from 'crypto';

import { AUTOPILOTS_BASE_URL } from '../constants';
import {
	addAutopilot,
	removeAutopilot, setAutopilot, setAutopilots,
	updateAutopilot
} from '../features/autopilotsSlice';
import { Autopilot } from '../features/types';
import { api } from './base';
import {
	AutopilotAuthorResponse,
	AutopilotConfigResponse,
	AutopilotLogsRequest,
	AutopilotLogsResponse,
	AutopilotMilestoneRequest,
	AutopilotTestRunRequest,
	AutopilotTestRunResponse,
	AutopilotsApiRequest,
	AutopilotsApiResponse,
	IndexingStatus,
	Log,
	LogComparisonRequest,
	LogComparisonResponse,
} from './types';

export const autopilotsApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getAutopilots: builder.query<AutopilotsApiResponse, AutopilotsApiRequest>({
			query: ({ enterpriseId, page, perPage, search, userIds, isActionAP }) => {
				// if (!enterpriseId) return '';
				return {
					url: `${AUTOPILOTS_BASE_URL}/enterprise`,
					method: 'GET',
					params: {
						enterprise_id: enterpriseId,
						page,
						per_page: perPage,
						search,
						user_ids: userIds,
						is_action_ap: isActionAP,
					},
				};
			},
			async onQueryStarted(args, { queryFulfilled, dispatch }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(setAutopilots(data.autopilots));
				} catch (error) {
					// console.error(error);
				}
			},
			providesTags: ['Autopilots'],
		}),
		getAutopilotById: builder.query<
			Autopilot,
			{
				autopilotId: string;
				enterpriseId?: string;
				includeUsers?: boolean;
			}
		>({
			query: ({ autopilotId, enterpriseId, includeUsers }) => {
				if (!autopilotId) return '';
				const autopilot = {
					url: `${AUTOPILOTS_BASE_URL}/${autopilotId}`,
					method: 'GET',
					params: {
						enterprise_id: enterpriseId,
						include_users: includeUsers,
					},
				};
				return autopilot;
			},
			async onQueryStarted(args, { queryFulfilled, dispatch }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(setAutopilot(data));
				} catch (error) {
					dispatch(setAutopilot(null));
				}
			},
			providesTags: ['Autopilots'],
		}),
		addAutopilot: builder.mutation<Autopilot, Autopilot>({
			query: (body) => {
				return {
					url: `${AUTOPILOTS_BASE_URL}/`,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(args, { queryFulfilled, dispatch }) {
				const { data } = await queryFulfilled;
				dispatch(addAutopilot(data));
			},
			invalidatesTags: ['Autopilots'],
		}),
		updateAutopilot: builder.mutation<
			Autopilot,
			Partial<Autopilot & { enterprise_id: string }>
		>({
			query: (autopilot) => ({
				url: `${AUTOPILOTS_BASE_URL}/${autopilot.id}`,
				method: 'PATCH',
				body: autopilot,
			}),
			async onQueryStarted(args, { queryFulfilled, dispatch }) {
				const { data } = await queryFulfilled;
				dispatch(updateAutopilot(data));
			},
			invalidatesTags: ['Autopilots', 'EnterpriseUsers'],
		}),
		deleteAutopilot: builder.mutation<{ success: boolean; id: string }, string>(
			{
				query: (id) => ({
					url: `${AUTOPILOTS_BASE_URL}/${id}`,
					method: 'DELETE',
				}),
				async onQueryStarted(args, { queryFulfilled, dispatch }) {
					const { data } = await queryFulfilled;
					dispatch(removeAutopilot(data.id));
				},
				invalidatesTags: ['Autopilots'],
			},
		),
		getAutopilotConfig: builder.query<AutopilotConfigResponse, void>({
			query: () => `${AUTOPILOTS_BASE_URL}/config`,
		}),
		getAutopilotLogs: builder.query<
			AutopilotLogsResponse,
			AutopilotLogsRequest
		>({
			query: ({
				enterpriseId,
				autopilotId,
				userId,
				traceId,
				dateFrom,
				dateTo,
				isTest,
				page,
				perPage,
				showIndexLogs,
			}) => {
				const params: Record<string, any> = {
					page: page ?? 1,
					per_page: perPage ?? 10,
					show_index_logs: showIndexLogs ?? true,
				};

				// Only add isTest if it's defined
				if (isTest !== undefined) {
					params.is_test = isTest;
				}

				if (enterpriseId && enterpriseId.length > 0) {
					params.enterprise_id = enterpriseId;
				}

				if (autopilotId && autopilotId.length > 0) {
					params.autopilot_id = autopilotId;
				}

				if (userId && userId.length > 0) {
					params.user_id = userId;
				}

				if (traceId) {
					params.trace_id = traceId;
				}

				if (dateFrom) {
					params.date_from = dateFrom;
				}

				if (dateTo) {
					params.date_to = dateTo;
				}

				return {
					url: `${AUTOPILOTS_BASE_URL}/logs/query`,
					method: 'POST',
					body: params,
				};
			},
		}),

		runLogComparison: builder.mutation<
			LogComparisonResponse,
			LogComparisonRequest
		>({
			query: (body) => ({
				url: `${AUTOPILOTS_BASE_URL}/logs/run-comparison`,
				method: 'POST',
				body: {
					log_id: body.log_id,
					agent_type: body.agent_type,
				},
			}),
		}),

		getLogById: builder.query<Log, string>({
			query: (traceId) => ({
				url: `${AUTOPILOTS_BASE_URL}/logs/${traceId}`,
				method: 'GET',
			}),
		}),

		getAutopilotLogsWithMilestones: builder.query<
			AutopilotLogsResponse,
			AutopilotMilestoneRequest
		>({
			query: ({
				enterpriseId,
				autopilotId,
				isTest,
				page,
				perPage,
				showIndexLogs,
				userId,
				traceId,
				dateFrom,
				dateTo,
			}) => {
				const body: Record<string, any> = {
					page: page ?? 1,
					per_page: perPage ?? 10,
					show_index_logs: showIndexLogs ?? true,
				};

				// Only add isTest if it's defined
				if (isTest !== undefined) {
					body.is_test = isTest;
				}

				if (enterpriseId && enterpriseId.length > 0) {
					body.enterprise_id = enterpriseId;
				}
				if (autopilotId && autopilotId.length > 0) {
					body.autopilot_id = autopilotId;
				}
				if (userId && userId.length > 0) {
					body.user_id = userId;
				}
				if (traceId) {
					body.trace_id = traceId;
				}
				if (dateFrom) {
					body.date_from = dateFrom;
				}
				if (dateTo) {
					body.date_to = dateTo;
				}
				return {
					url: `${AUTOPILOTS_BASE_URL}/logs/milestones`,
					method: 'POST',
					body,
				};
			},
		}),

		testRun: builder.mutation<
			AutopilotTestRunResponse,
			AutopilotTestRunRequest
		>({
			query: (body) => ({
				url: `${AUTOPILOTS_BASE_URL}/test/${body.autopilot_id}`,
				method: 'POST',
				body,
			}),
		}),
		getAutopilotAuthor: builder.query<
			AutopilotAuthorResponse,
			UUID | undefined
		>({
			query: (authorId) => {
				if (!authorId) return '';
				return `${AUTOPILOTS_BASE_URL}/author/${authorId}`;
			},
		}),

		getIndexingStatus: builder.query<IndexingStatus, void>({
			query: () => ({
				url: `${AUTOPILOTS_BASE_URL}/indexing/progress`,
				method: 'GET',
			}),
		}),

		getMilestoneFilterOptions: builder.query<
			{
				autopilots: Array<{ id: string; name: string }>;
				users: Array<{ id: string; name: string; email: string }>;
				agent_types: Array<{ id: string; name: string }>;
				enterprises: Array<{ id: string; name: string }>;
			},
			void
		>({
			query: () => ({
				url: `${AUTOPILOTS_BASE_URL}/logs/milestone-filters`,
				method: 'GET',
			}),
		}),
	}),
});

export const {
	useGetAutopilotsQuery,
	useGetAutopilotByIdQuery,
	useAddAutopilotMutation,
	useUpdateAutopilotMutation,
	useDeleteAutopilotMutation,
	useGetAutopilotConfigQuery,
	useGetAutopilotLogsQuery,
	useGetAutopilotLogsWithMilestonesQuery,
	useGetMilestoneFilterOptionsQuery,
	useTestRunMutation,
	useGetAutopilotAuthorQuery,
	useGetIndexingStatusQuery,
	useRunLogComparisonMutation,
	useGetLogByIdQuery,
} = autopilotsApi;
