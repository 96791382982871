import { User } from '../api/types';
import { useGetMeQuery } from '../api/userApi';
import { RootState, useTypedSelector } from '../store';

export const useMe = () => {
	const { data, isLoading, isError, refetch } = useGetMeQuery(undefined, {
		// Skip if no access token
		skip: !useTypedSelector((state: RootState) => state.authState.accessToken),
	});
	const userFromState = useTypedSelector(
		(state: RootState) => state.authState.user,
	);

	// Combine data from query and state
	const user = data || userFromState;

	return {
		data: user as User | undefined,
		isLoading,
		isError,
		refetch,
	};
};
