import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { NLSearchResult } from '../api/types';

export interface SearchSelectionState {
	isSelectionMode: boolean;
	selectedResults: NLSearchResult[];
}

const initialState: SearchSelectionState = {
	isSelectionMode: false,
	selectedResults: [],
};

const searchSelectionSlice = createSlice({
	name: 'searchSelection',
	initialState,
	reducers: {
		toggleSelectionMode: (state) => {
			state.isSelectionMode = !state.isSelectionMode;
		},
		toggleResultSelection: (state, action: PayloadAction<NLSearchResult>) => {
			const result = action.payload;
			const index = state.selectedResults.findIndex(
				(selectedResult) => selectedResult.id === result.id,
			);
			if (index === -1) {
				state.selectedResults.push(result);
			} else {
				state.selectedResults.splice(index, 1);
			}
		},
		clearSelection: (state) => {
			state.selectedResults = [];
		},
		selectAll: (state, action: PayloadAction<NLSearchResult[]>) => {
			state.selectedResults = action.payload;
		},
		setInitialSelectedResults: (
			state,
			action: PayloadAction<NLSearchResult[]>,
		) => {
			state.selectedResults = action.payload;
		},
		setSelectionMode: (state, action: PayloadAction<boolean>) => {
			state.isSelectionMode = action.payload;
		},
	},
});

export const {
	toggleSelectionMode,
	toggleResultSelection,
	clearSelection,
	selectAll,
	setInitialSelectedResults,
	setSelectionMode,
} = searchSelectionSlice.actions;
export default searchSelectionSlice.reducer;
