import { useEffect, useState } from 'react';

import { useGetDealTasksQuery } from '../api/dealsApi';

const DEFAULT_LIMIT = 100;

interface UseDealTasksOptions {
	page?: number;
	perPage?: number;
}

export const useDealTasks = (
	deal_id: string,
	options: UseDealTasksOptions = {},
) => {
	const [ page, setPage ] = useState( 1 );
	const perPage = options.perPage ?? DEFAULT_LIMIT;

	const {
		data,
		isLoading,
		isFetching,
		refetch,
	} = useGetDealTasksQuery(
		{
			deal_id,

			per_page: perPage,
			page: options?.page ?? page,

		},
		{ skip: !deal_id },
	);

	// Reset page when deal_id changes
	useEffect( () => {
		setPage( 1 );
	}, [ deal_id ] );

	return {
		tasks: data?.data ?? [],
		isLoading,
		isFetching,
		page,
		setPage,
		hasMore: data?.data && data.data.length === perPage,
		refetch,
		total: data?.total ?? 0,
	};
};
