import { api } from './base';
import { AddTeamMemberRequest, TeamResponse } from './types';

export const teamsApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getTeamMembers: builder.query<TeamResponse, void>({
			query: () => '/teams/members',
		}),

		addTeamMember: builder.mutation<void, AddTeamMemberRequest>({
			query: (body) => ({
				url: '/teams/members',
				method: 'POST',
				body,
			}),
		}),

		removeTeamMember: builder.mutation<void, string>({
			query: (email) => ({
				url: `/teams/members/${email}`,
				method: 'DELETE',
			}),
		}),

		resendInvite: builder.mutation<void, string>({
			query: (inviteId) => ({
				url: `/teams/invites/${inviteId}/resend`,
				method: 'POST',
			}),
		}),

		cancelInvite: builder.mutation<void, string>({
			query: (inviteId) => ({
				url: `/teams/invites/${inviteId}`,
				method: 'DELETE',
			}),
		}),

		updateMemberRole: builder.mutation<void, { email: string; role: string }>({
			query: (body) => ({
				url: '/teams/members/role',
				method: 'PUT',
				body,
			}),
		}),
	}),
});

export const {
	useGetTeamMembersQuery,
	useAddTeamMemberMutation,
	useRemoveTeamMemberMutation,
	useResendInviteMutation,
	useCancelInviteMutation,
	useUpdateMemberRoleMutation,
} = teamsApi;
