import { useGetAllEnterprisesQuery } from '../api/enterpriseApi';

export function useEnterprises() {
	const {
		data: enterprisesData,
		isLoading: isEnterprisesLoading,
		isFetching: isEnterprisesFetching,
		isError: isEnterprisesError,
	} = useGetAllEnterprisesQuery();

	return {
		enterprisesData,
		isEnterprisesLoading,
		isEnterprisesFetching,
		isEnterprisesError,
	};
}
