import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ChatMinimal } from '../api/types';

export interface ChatsState {
	rejoinableChats: ChatMinimal[];
}

const initialState: ChatsState = {
	rejoinableChats: [],
};

const chatsSlice = createSlice({
	name: 'chats',
	initialState,
	reducers: {
		setRejoinableChats(state, action: PayloadAction<ChatMinimal[]>) {
			state.rejoinableChats = action.payload;
		},
		clearRejoinableChats(state) {
			state.rejoinableChats = [];
		},
	},
});

export const { setRejoinableChats, clearRejoinableChats } = chatsSlice.actions;

export default chatsSlice.reducer;
