import { LOGGED_IN_TENANT_KEY, ONBOARDING_KEY } from '../constants';
import { clearTokens, setCredentials } from '../features/authSlice';
import { PosthogUtil } from '../services/posthog';
import { resetSocket } from '../services/socket';
import { getSplitClient, resetSplitClient } from '../services/split';
import { TokenUtil } from '../services/token';
import { RootState } from '../store';
import { isChromeExtension } from '../utils/helper';
import { api } from './base';
import { channelApi } from './channelApi';
import { LoginRequest, LoginResponse, MeResponse } from './types';
import { userApi } from './userApi';

export const authApi = api.injectEndpoints({
	endpoints: (builder) => ({
		login: builder.mutation<LoginResponse, LoginRequest>({
			query(data) {
				return {
					url: 'login/google',
					method: 'POST',
					body: {
						...data,
						timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
					},
				};
			},
			async onQueryStarted(args, { dispatch, getState, queryFulfilled }) {
				try {
					const { data: response } = await queryFulfilled;
					const { data } = response;
					const state = getState() as RootState;
					const app_url = state.tenantConfigState.app_url;

					// Set tokens in both cookies and redux
					await TokenUtil.setAccessToken(data?.access_token, app_url);
					await TokenUtil.setRefreshToken(data?.refresh_token, app_url);

					dispatch(
						setCredentials({
							accessToken: data?.access_token,
							refreshToken: data?.refresh_token,
							user: {}, // Will be populated by getMe
						}),
					);

					if (args?.tenant_id) {
						localStorage.setItem(LOGGED_IN_TENANT_KEY, args?.tenant_id);
					}

					// Fetch user data
					await dispatch(userApi.endpoints.getMe.initiate());

					// Handle onboarding for chrome extension
					if (isChromeExtension) {
						if (
							data.hasOwnProperty('is_existing_user') &&
							!data.is_existing_user &&
							!localStorage.getItem(ONBOARDING_KEY)
						) {
							localStorage.setItem(
								ONBOARDING_KEY,
								JSON.stringify({
									appConnected: false,
									chatStarted: false,
								}),
							);
						} else {
							localStorage.removeItem(ONBOARDING_KEY);
						}
					}
				} catch (error) {
					console.error('Login failed:', error);
				}
			},
		}),
		logout: builder.mutation<void, void>({
			query() {
				console.debug('Auth.Logout');
				return {
					url: '/logout',
					method: 'GET',
				};
			},
			async onQueryStarted(args, { queryFulfilled, getState, dispatch }) {
				try {
					await queryFulfilled;

					const state = getState() as RootState;
					const app_url = state.tenantConfigState.app_url;

					// Handle chrome extension specific cleanup
					if (isChromeExtension) {
						const currentUser = state.api.queries['getMe(undefined)']
							?.data as MeResponse['data'];
						const connectedChannels = state.channelState.connectedChannels;

						if (currentUser) {
							connectedChannels.forEach((channelId: string) => {
								void dispatch(
									channelApi.endpoints.leaveChannel.initiate({
										channelId,
										userId: currentUser.id,
									}),
								);
							});
						}
						resetSocket();
						localStorage.removeItem(LOGGED_IN_TENANT_KEY);
					}

					// Clear tokens from both cookies and redux
					await TokenUtil.removeAccessToken(app_url);
					await TokenUtil.removeRefreshToken(app_url);
					dispatch(clearTokens());

					// Cleanup other services
					const splitClient = await getSplitClient();
					splitClient?.destroy();
					resetSplitClient();

					// Reset API state
					dispatch(api.util.resetApiState());
					PosthogUtil.resetUser();
				} catch (error) {
					console.error('Logout failed:', error);
				}
			},
		}),
	}),
});

export const { useLoginMutation, useLogoutMutation } = authApi;
