import { useEffect, useRef, useState } from 'react';

import { UUID } from 'crypto';

import { useExecuteNLSearchQuery } from '../api/agentSearchApi';
import { agentSearchApi } from '../api/agentSearchApi';
import {
	NLSearchRequest,
	NLSearchResponse,
	TimestampFilterValue,
} from '../api/types';
import { transformQueryWithFilters } from '../utils/helper';

type UseExecuteSearchProps = {
	query: string;
	filters?: {
		appFilter?: string[];
		timestampFilter?: TimestampFilterValue;
		peopleFilter?: string[];
	};
	searchId?: UUID;
	page?: number;
	per_page?: number;
};

export const useExecuteSearch = ({
	query,
	filters,
	searchId,
	per_page = 25,
}: UseExecuteSearchProps) => {
	const [page, setPage] = useState(1);
	const enhancedQuery = transformQueryWithFilters(query, filters);
	const searchKey = JSON.stringify({ query, filters, searchId });
	const previousSearchKey = useRef(searchKey);
	const isNewSearch = searchKey !== previousSearchKey.current;

	useEffect(() => {
		if (isNewSearch) {
			setPage(1);
			previousSearchKey.current = searchKey;
		}
	}, [searchKey, isNewSearch]);


	const { data, isLoading, isFetching, isError, error, refetch } = useExecuteNLSearchQuery(
		{
			query: enhancedQuery,
			filters,
			search_id: searchId,
			page: isNewSearch ? 1 : page,
			per_page,
		},
		{
			skip: !query || !searchId || query.length < 3,
			refetchOnMountOrArgChange: true,
			refetchOnReconnect: true,
		},
	);

	const clearNLSearch = () => {
		agentSearchApi.util.invalidateTags([
			{ type: 'NLSearch', id: 'LIST' }, 
			{ type: 'NLSearch', id: 'INFINITE' },
		]);
	};

	return {
		searchResults: data?.results || [],
		totalPages: data?.total_pages || 0,
		totalItems: data?.total_items || 0,
		page,
		setPage,
		isLoading: isLoading || isFetching,
		isError,
		error,
		clearNLSearch,
		refetch
	};
};
