import { useCallback, useEffect, useState } from 'react';

import {
    useGetAutopilotLogsWithMilestonesQuery,
    useGetLogByIdQuery,
    useGetMilestoneFilterOptionsQuery,
    useRunLogComparisonMutation,
} from '../api/autopilotsApi';

// Hook for handling log comparison
export function useLogComparison(traceId: string | null) {
	const [isRunning, setIsRunning] = useState(false);
	const [comparisonTraceId, setComparisonTraceId] = useState<string | null>(
		null,
	);
	const [error, setError] = useState<string | null>(null);

	const [runLogComparison] = useRunLogComparisonMutation();
	const { data: comparisonLog, refetch: refetchComparisonLog } =
		useGetLogByIdQuery(comparisonTraceId || 'skip', {
			skip: !comparisonTraceId,
		});

	// Function to run comparison
	const runComparison = async (agentType: string) => {
		if (!traceId) return;

		setIsRunning(true);
		setError(null);

		try {
			const response = await runLogComparison({
				log_id: traceId,
				agent_type: agentType,
			}).unwrap();

			setComparisonTraceId(response.trace_id);
			return response;
		} catch (err) {
			setError('Failed to start comparison run');
			setIsRunning(false);
		}
	};

	// Handle status updates
	useEffect(() => {
		if (comparisonLog) {
			if (comparisonLog.status === 'SUCCESS') {
				setIsRunning(false);
			} else if (comparisonLog.status === 'FAILED') {
				setIsRunning(false);
				setError('Comparison run failed');
			}
		}
	}, [comparisonLog]);

	const checkStatus = useCallback(() => {
		if (comparisonTraceId) {
			refetchComparisonLog();
		}
	}, [comparisonTraceId, refetchComparisonLog]);

	return {
		isRunning,
		comparisonLog,
		error,
		runComparison,
		checkStatus,
	};
}

// Hook for monitoring child runs of a log
export function useLogChildRuns(traceId: string | null) {
	const { data: log, refetch: refetchLog } = useGetLogByIdQuery(
		traceId || 'skip',
		{
			skip: !traceId,
		},
	);

	return {
		childRun: log || {},
		refetchLog,
		log,
	};
}

export function useAutopilotLogsWithMilestones({
	enterpriseId,
	autopilotId,
	isTest,
	page,
	perPage,
	showIndexLogs = true,
	userId,
	traceId,
	dateFrom,
	dateTo,
}: {
	enterpriseId?: string[];
	autopilotId?: string[];
	isTest?: boolean;
	page?: number;
	perPage?: number;
	showIndexLogs?: boolean;
	userId?: string[];
	traceId?: string;
	dateFrom?: string;
	dateTo?: string;
}) {
	const [currentPage, setCurrentPage] = useState(page ?? 1);
	const [currentPerPage, setCurrentPerPage] = useState(perPage ?? 10);

	const {
		data,
		refetch,
		isLoading,
		currentData,
	} = useGetAutopilotLogsWithMilestonesQuery({
		enterpriseId: enterpriseId ?? undefined,
		autopilotId: autopilotId ?? undefined,
		isTest,
		page: currentPage,
		perPage: currentPerPage,
		showIndexLogs,
		userId,
		traceId,
		dateFrom,
		dateTo,
	});

	// Directly use currentData to avoid stale closures
	const logs = currentData?.logs || [];
	const total = currentData?.total || 0;

	// Add useEffect to sync page/prop changes
	useEffect(() => {
		setCurrentPage(page ?? 1);
		setCurrentPerPage(perPage ?? 10);
	}, [page, perPage]);

	const handleRefetch = useCallback(() => {
		refetch();
	}, [refetch]);

	return {
		logs,
		total,
		page: currentPage,
		perPage: currentPerPage,
		refetch: handleRefetch,
		isLoading,
	};
}

// Hook for milestone filter options
export function useMilestoneFilterOptions() {
	const { data, isLoading } = useGetMilestoneFilterOptionsQuery();

	const [autopilots, setAutopilots] = useState<
		Array<{ id: string; name: string }>
	>([]);
	const [users, setUsers] = useState<
		Array<{ id: string; name: string; email: string }>
	>([]);
	const [enterprises, setEnterprises] = useState<
		Array<{ id: string; name: string }>
	>([]);
	const [agentTypes, setAgentTypes] = useState<
		Array<{ id: string; name: string }>
	>([]);

	useEffect(() => {
		if (data) {
			setAutopilots(data.autopilots || []);
			setUsers(data.users || []);
			setAgentTypes(data.agent_types || []);
			setEnterprises(data.enterprises || []);
		}
	}, [data]);

	return {
		autopilots,
		users,
		agentTypes,
		enterprises,
		isLoading,
	};
}
