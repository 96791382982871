import { ENTERPRISE_BASE_URL } from '../constants';
import { api } from './base';
import {
	EnterpriseAutopilotsResponse,
	EnterpriseResponse,
	RelevantEnterprisePeopleResponse,
	User,
} from './types';

export const enterpriseApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getEnterprise: builder.query<EnterpriseResponse, void>({
			query() {
				return {
					url: `${ENTERPRISE_BASE_URL}/hd`,
					method: 'GET',
				};
			},
			providesTags: ['Enterprise'],
		}),
		getEnterpriseById: builder.query<EnterpriseResponse, string>({
			query(id) {
				if (!id) return '';
				return {
					url: `${ENTERPRISE_BASE_URL}/${id}`,
					method: 'GET',
					headers: {
						Authorization: process.env.REACT_APP_API_KEY || '',
					},
				};
			},
		}),
		getEnterpriseAutopilots: builder.query<EnterpriseAutopilotsResponse, void>({
			query() {
				return {
					url: `${ENTERPRISE_BASE_URL}/autopilots`,
					method: 'GET',
				};
			},
			providesTags: ['EnterpriseUsers'],
		}),
		getEnterpriseUsers: builder.query<User[], string>({
			query(enterpriseId) {
				if (!enterpriseId) return '';
				return {
					url: `${ENTERPRISE_BASE_URL}/${enterpriseId}/users`,
					method: 'GET',
				};
			},
		}),
		getAllEnterprises: builder.query<EnterpriseResponse[], void>({
			query() {
				return {
					url: `${ENTERPRISE_BASE_URL}/all`,
					method: 'GET',
					headers: {
						Authorization: process.env.REACT_APP_API_KEY || '',
					},
				};
			},
		}),
		getRelevantEnterprisePeople: builder.query<
			RelevantEnterprisePeopleResponse,
			{ enterpriseId: string; searchTerm: string }
		>({
			query: ({ enterpriseId, searchTerm }) => ({
				url: `${ENTERPRISE_BASE_URL}/${enterpriseId}/relevant_users/${searchTerm}`,
				method: 'GET',
			}),
		}),
	}),
});

export const {
	useGetEnterpriseQuery,
	useGetEnterpriseByIdQuery,
	useGetEnterpriseAutopilotsQuery,
	useGetEnterpriseUsersQuery,
	useGetAllEnterprisesQuery,
	useGetRelevantEnterprisePeopleQuery,
} = enterpriseApi;
