import { useCallback, useMemo } from 'react';

import { useGetDealsQuery } from '../api/dealsApi';

export interface UseDealListOptions {
	page: number;
	per_page: number;
	enterprise_id: string | undefined;
	search?: string;
	pipeline?: string[];
	company?: string[];
	deal_owner?: string[];
	stage?: string[];
	amount?: string[];
	close_date?: string[];
	is_closed?: boolean;
	is_won?: boolean;
}

export const useDealList = (options: UseDealListOptions) => {
	// Create a stable filterKey that doesn't include pagination
	const filterKey = useMemo(
		() =>
			JSON.stringify({
				search: options.search,
				pipeline: options.pipeline,
				company: options.company,
				deal_owner: options.deal_owner,
				stage: options.stage,
				amount: options.amount,
				close_date: options.close_date,
				is_closed: options.is_closed,
				is_won: options.is_won,
				enterprise_id: options.enterprise_id,
			}),
		[
			options.search,
			options.pipeline,
			options.company,
			options.deal_owner,
			options.stage,
			options.amount,
			options.close_date,
			options.is_closed,
			options.is_won,
			options.enterprise_id,
		],
	);

	const { data, isLoading, isFetching, error, refetch } = useGetDealsQuery(
		{
			...options,
			filterKey,
		},
		{
			skip: !options.enterprise_id,
			// Prevent refetching on window focus
			refetchOnFocus: false,
			// Prevent refetching on reconnect
			refetchOnReconnect: false,
		},
	);

	const loadMore = useCallback(() => {
		if (data?.has_more && !isLoading && !isFetching) {
			return refetch();
		}
	}, [data?.has_more, isLoading, isFetching, refetch]);

	return {
		deals: data?.deals || [],
		total: data?.total || 0,
		hasMore: data?.has_more || false,
		isLoading,
		isFetching,
		error,
		loadMore,
	};
};
