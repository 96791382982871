import { UUID } from 'crypto';

import { UserFeedbackRequest, UserFeedbackResponse } from '../api/types';
import { api } from './base';

export const userFeedbackApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getUserFeedbackByEntity: builder.query<
			UserFeedbackResponse,
			{ entityId: UUID; entityType: string }
		>({
			query: ({ entityId, entityType }) => {
				if (!entityId || !entityType) return '';
				return `feedback/entity/${entityId}/${entityType}`;
			},
			async onQueryStarted({}, { queryFulfilled }) {
				try {
					await queryFulfilled;
				} catch (error) {
					// console.error(error);
				}
			},
		}),
		createUserFeedback: builder.mutation<
			UserFeedbackResponse,
			UserFeedbackRequest
		>({
			query: (feedback) => ({
				url: 'feedback/',
				method: 'POST',
				body: feedback,
			}),
		}),
		updateUserFeedback: builder.mutation<
			UserFeedbackResponse,
			{ feedbackId: UUID; feedback: UserFeedbackRequest }
		>({
			query: ({ feedbackId, feedback }) => {
				if (!feedbackId || !feedback) return '';
				return {
					url: `feedback/${feedbackId}`,
					method: 'PUT',
					body: feedback,
				};
			},
			async onQueryStarted({}, { queryFulfilled }) {
				try {
					await queryFulfilled;
				} catch (error) {
					// console.error(error);
				}
			},
		}),
		deleteUserFeedback: builder.mutation<{ message: string }, UUID>({
			query: (feedbackId) => ({
				url: `feedback/${feedbackId}`,
				method: 'DELETE',
			}),
		}),
	}),
});

export const {
	useGetUserFeedbackByEntityQuery,
	useCreateUserFeedbackMutation,
	useUpdateUserFeedbackMutation,
	useDeleteUserFeedbackMutation,
} = userFeedbackApi;
