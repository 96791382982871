import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import localforage from 'localforage';

export interface UserPreferencesState {
	userAnalyticsToggled: boolean | undefined;
	qualityPerformanceToggled: boolean | undefined;
	internalMeetingBotToggled: boolean | undefined;
	externalMeetingBotToggled: boolean | undefined;
	webNotificationsToggled: boolean | undefined;
	pushNotificationsToggled?: boolean | undefined;
	emailNotificationsToggled?: boolean | undefined;
	smsNotificationsToggled?: boolean | undefined;
	androidNotificationsToggled?: boolean | undefined;
	iosNotificationsToggled?: boolean | undefined;
	frequency?: number | undefined;
	startTime?: number | undefined;
	endTime?: number | undefined;
	days?: string | undefined;
	postMeetingSummaryToggled?: boolean | undefined;
	preMeetingResearchToggled?: boolean | undefined;
	salesforceAddContactsToggled?: boolean | undefined;
	summarizeEmailsToggled?: boolean | undefined;
	slackNotificationsToggled?: boolean | undefined;
	excludedParticipantDomains: string[];
	googleDriveFolderUrl: string;
}

const initialState: UserPreferencesState = {
	userAnalyticsToggled: true,
	qualityPerformanceToggled: true,
	internalMeetingBotToggled: false,
	externalMeetingBotToggled: false,
	webNotificationsToggled: true,
	excludedParticipantDomains: [],
	googleDriveFolderUrl: '',
	pushNotificationsToggled: true,
	postMeetingSummaryToggled: true,
	preMeetingResearchToggled: true,
	salesforceAddContactsToggled: true,
	summarizeEmailsToggled: true,
	slackNotificationsToggled: false,
	androidNotificationsToggled: true,
	iosNotificationsToggled: true,
	emailNotificationsToggled: false,
	smsNotificationsToggled: false,
	frequency: 0,
	startTime: 800,
	endTime: 1700,
};

const toggleState = (
	state: boolean | undefined,
	action: PayloadAction<boolean | undefined>,
): boolean => (action.payload !== undefined ? action.payload : !state);

export const userPreferencesSlice = createSlice({
	name: 'userPreferences',
	initialState,
	reducers: {
		toggleUserAnalytics: (
			state,
			action: PayloadAction<boolean | undefined>,
		) => {
			state.userAnalyticsToggled = toggleState(
				state.userAnalyticsToggled,
				action,
			);
		},
		toggleQualityPerformance: (
			state,
			action: PayloadAction<boolean | undefined>,
		) => {
			state.qualityPerformanceToggled = toggleState(
				state.qualityPerformanceToggled,
				action,
			);
		},
		toggleInternalMeetingBot: (
			state,
			action: PayloadAction<boolean | undefined>,
		) => {
			state.internalMeetingBotToggled = toggleState(
				state.internalMeetingBotToggled,
				action,
			);
		},
		toggleExternalMeetingBot: (
			state,
			action: PayloadAction<boolean | undefined>,
		) => {
			state.externalMeetingBotToggled = toggleState(
				state.externalMeetingBotToggled,
				action,
			);
		},
		setExcludedParticipantDomains: (state, action: PayloadAction<string[]>) => {
			state.excludedParticipantDomains = action.payload;
		},
		setUserPreferences: (
			state,
			action: PayloadAction<Partial<UserPreferencesState>>,
		) => {
			Object.assign(state, action.payload);
		},
	},
});

export const {
	toggleUserAnalytics,
	toggleQualityPerformance,
	toggleInternalMeetingBot,
	toggleExternalMeetingBot,
	setExcludedParticipantDomains,
	setUserPreferences,
} = userPreferencesSlice.actions;

export default userPreferencesSlice.reducer;
