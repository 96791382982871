import React, { CSSProperties } from 'react';

import ReactMarkdown, { Components } from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface CustomReactMarkdownProps {
	children: string;
	styles?: { [key: string]: CSSProperties };
	disableLinks?: boolean;
}

const DEFAULT_STYLES: { [key: string]: CSSProperties } = {
	content: {
		userSelect: 'text',
	},
	paragraph: {
		marginBottom: '1rem',
	},
	heading: {
		marginTop: '1.5rem',
		marginBottom: '1rem',
		fontWeight: 'bold',
	},
	h1: { fontSize: '24px' },
	h2: { fontSize: '20px' },
	h3: { fontSize: '18px' },
	h4: { fontSize: '16px' },
	h5: { fontSize: '14px' },
	h6: { fontSize: '12px' },
	list: {
		paddingLeft: '1.5rem',
		marginTop: '0.5rem',
		marginBottom: '0.5rem',
	},
	listItem: {
		marginBottom: '0.25rem',
	},
	link: {
		color: 'var(--mb-color-light-blue-8)',
		textDecoration: 'underline',
	},
	code: {
		backgroundColor: 'var(--mb-gray-3)',
		padding: '2px 4px',
		borderRadius: '4px',
		fontSize: '11px',
		fontFamily: 'monospace',
	},
};

const CustomReactMarkdown: React.FC<CustomReactMarkdownProps> = React.memo(
	({ children, styles: customStyles = {}, disableLinks = false }) => {
		const mergedStyles = Object.keys(DEFAULT_STYLES).reduce(
			(acc, key) => {
				acc[key] = { ...DEFAULT_STYLES[key], ...(customStyles[key] || {}) };
				return acc;
			},
			{} as typeof DEFAULT_STYLES,
		);

		const components: Components = {
			p: ({ node, ...props }) => (
				<p
					style={{
						...mergedStyles.paragraph,
					}}
					{...props}
				/>
			),
			h1: ({ node, ...props }) => (
				<h1
					style={{ ...mergedStyles.h1, ...mergedStyles.heading }}
					{...props}
				/>
			),
			h2: ({ node, ...props }) => (
				<h2
					style={{ ...mergedStyles.h2, ...mergedStyles.heading }}
					{...props}
				/>
			),
			h3: ({ node, ...props }) => (
				<h3
					style={{ ...mergedStyles.h3, ...mergedStyles.heading }}
					{...props}
				/>
			),
			h4: ({ node, ...props }) => (
				<h4
					style={{ ...mergedStyles.h4, ...mergedStyles.heading }}
					{...props}
				/>
			),
			h5: ({ node, ...props }) => (
				<h5
					style={{ ...mergedStyles.h5, ...mergedStyles.heading }}
					{...props}
				/>
			),
			h6: ({ node, ...props }) => (
				<h6
					style={{ ...mergedStyles.h6, ...mergedStyles.heading }}
					{...props}
				/>
			),
			ul: ({ node, ...props }) => (
				<ul
					style={{ ...mergedStyles.list, listStyleType: 'disc' }}
					{...props}
				/>
			),
			ol: ({ node, ...props }) => (
				<ol
					style={{ ...mergedStyles.list, listStyleType: 'decimal' }}
					{...props}
				/>
			),
			li: ({ node, ...props }) => (
				<li style={mergedStyles.listItem} {...props} />
			),
			a: ({ node, ...props }) => {
				if (disableLinks) {
					return <span style={mergedStyles.link}>{props.children}</span>;
				}
				return (
					<a
						style={mergedStyles.link}
						{...props}
						target="_blank"
						rel="noopener noreferrer"
					/>
				);
			},
			code: ({ node, inline, ...props }) => (
				<code
					style={{
						...mergedStyles.code,
						display: 'inline-block',
						maxWidth: '100%',
					}}
					{...props}
				/>
			),
		};

		return (
			<div style={{ ...mergedStyles.content }}>
				<ReactMarkdown components={components} remarkPlugins={[remarkGfm]}>
					{children}
				</ReactMarkdown>
			</div>
		);
	},
);

export default CustomReactMarkdown;
