import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Meeting } from '../api/types';

export interface MeetingsState {
	meetingsList: Meeting[];
	activeMeeting: Meeting | null;
}

const initialState: MeetingsState = {
	meetingsList: [],
	activeMeeting: null,
};

const meetingsSlice = createSlice({
	name: 'meetings',
	initialState,
	reducers: {
		setMeetingsList: (state, action: PayloadAction<Meeting[]>) => {
			state.meetingsList = action.payload;
		},
		setActiveMeeting: (state, action: PayloadAction<Meeting | null>) => {
			state.activeMeeting = action.payload;
		},
	},
});

export const { setMeetingsList, setActiveMeeting } = meetingsSlice.actions;
export default meetingsSlice.reducer;

// Selectors
export const selectMeetingsList = (state: { meetings: MeetingsState }) =>
	state.meetings.meetingsList;
export const selectActiveMeeting = (state: { meetings: MeetingsState }) =>
	state.meetings.activeMeeting;
