import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { useGetCompletionsHistoryQuery } from '../api/completionsApi';
import { CompletionCurrentItem, CompletionHistoryItem } from '../api/types';
import { CollectionType } from '../commons/types';
import {
	addCurrentCompletions,
	resetCurrentCompletions,
	setCompletionsHistory,
	setCurrentCompletions,
	updateCurrentCompletions,
} from '../features/completionsSlice';
import { RootState, useTypedSelector } from '../store';

export const useCompletionsHistory = (
	collectionId?: string,
	collectionType?: CollectionType,
) => {
	const isTeamView = window.location.pathname.includes('team');
	const dispatch = useDispatch();
	const history = useTypedSelector(
		(state: RootState) => state.completionsState.history,
	);

	const currentCompletions = useTypedSelector(
		(state: RootState) => state.completionsState.currentCompletions,
	);

	// Modify the streaming completion handler
	const handleStreamingComplete = (
		completion: Partial<CompletionHistoryItem>,
	) => {
		const optimisticMessage = {
			created_at: new Date().toISOString(),
			user_question: completion.user_question ?? '',
			response: completion.response ?? '',
		};

		// Use a Set to deduplicate messages based on content
		const newHistory = [...history];
		const lastMessage = newHistory[newHistory.length - 1];

		// Only add if the message is different from the last one
		if (
			!lastMessage ||
			lastMessage.user_question !== optimisticMessage.user_question ||
			lastMessage.response !== optimisticMessage.response
		) {
			dispatch(setCompletionsHistory([...history, optimisticMessage]));
		}
	};

	const {
		data: completionHistory,
		error: historyError,
		isLoading: isHistoryLoading,
		refetch: refetchHistory,
	} = useGetCompletionsHistoryQuery(
		{
			collectionId: collectionId ?? '',
			collectionType: collectionType,
			isTeamView: isTeamView ?? false,
		},
		{
			skip: !collectionId,
		},
	);

	const setCurrentCompletionsItems = (items: CompletionCurrentItem[]) => {
		dispatch(setCurrentCompletions(items));
	};
	const resetCurrentCompletionsItems = () => {
		dispatch(resetCurrentCompletions());
	};
	const updateCurrentCompletionsItem = (item: CompletionCurrentItem) => {
		dispatch(updateCurrentCompletions(item));
	};
	const addCurrentCompletionsItems = (item: CompletionCurrentItem) => {
		dispatch(addCurrentCompletions(item));
	};

	useEffect(() => {
		// Clear both history and current completions when collection ID changes
		dispatch(setCompletionsHistory([]));
		dispatch(resetCurrentCompletions());
	}, [collectionId, dispatch]);

	useEffect(() => {
		if (completionHistory) {
			dispatch(setCompletionsHistory([...completionHistory].reverse()));
		}
	}, [completionHistory, dispatch]);

	return {
		completionHistory: history,
		error: historyError,
		isLoading: isHistoryLoading,
		refetch: refetchHistory,
		currentCompletions,
		setCurrentCompletionsItems,
		resetCurrentCompletionsItems,
		updateCurrentCompletionsItem,
		addCurrentCompletionsItems,
		handleStreamingComplete,
	};
};
