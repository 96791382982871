import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
	API_URL_KEY,
	APP_URL_KEY,
	WORKSPACE_DOMAIN_KEY,
	WS_URL_KEY,
} from '../constants';

export interface TenantConfigState {
	client_id: string;
	redirect_url: string;
	api_url: string;
	app_url: string;
	ws_url: string;
	workspace_domain: string;
	contentful_entry_id: string;
}

const initialState: TenantConfigState = {
	client_id: '',
	redirect_url: '',
	api_url: localStorage.getItem(API_URL_KEY) || '',
	app_url: '',
	ws_url: localStorage.getItem(WS_URL_KEY) || '',
	workspace_domain: '',
	contentful_entry_id: '',
};

export const tenantConfigSlice = createSlice({
	name: 'tenantConfig',
	initialState,
	reducers: {
		updateTenantConfig: (state, action: PayloadAction<TenantConfigState>) => {
			const {
				client_id,
				api_url,
				app_url,
				ws_url,
				redirect_url,
				workspace_domain,
				contentful_entry_id,
			} = action.payload;
			state.client_id = client_id;
			state.redirect_url = redirect_url;
			state.api_url = api_url;
			state.app_url = app_url;
			state.ws_url = ws_url;
			state.workspace_domain = workspace_domain;
			state.contentful_entry_id = contentful_entry_id;
			localStorage.setItem(WS_URL_KEY, state.ws_url);
			localStorage.setItem(API_URL_KEY, state.api_url);
			localStorage.setItem(APP_URL_KEY, state.app_url);
			localStorage.setItem(WORKSPACE_DOMAIN_KEY, state.workspace_domain);
			chrome.storage.local.set({ app_url: state.app_url });
			chrome.storage.local.set({ api_url: state.api_url });
		},
	},
});

export const { updateTenantConfig } = tenantConfigSlice.actions;

export default tenantConfigSlice.reducer;
