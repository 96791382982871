import localforage from 'localforage';
import { v4 as uuid } from 'uuid';

import { JOURNALS_KEY, JOURNAL_CATEGORIES_KEY } from '../constants';
import { RootState } from '../store';
import { api } from './base';
import {
	Journal,
	JournalCategoriesResponse,
	JournalCategory,
	JournalResponse,
	JournalsResponse,
	User,
} from './types';

export const journalsApi = api.injectEndpoints({
	endpoints: (builder) => ({
		// TODO: this is a temp endpoint for local browser storage
		getJournals: builder.query<JournalsResponse, void>({
			// query: () => ({ url: 'journals' }),
			queryFn: async () => {
				const journals: Journal[] =
					(await localforage.getItem(`${JOURNALS_KEY}`)) ?? [];

				return {
					data: journals,
				};
			},
			providesTags: ['Journals'],
		}),
		// TODO: this is a temp endpoint for local browser storage
		getJournal: builder.query<JournalResponse, string>({
			// query: () => ({ url: 'chat' }),
			queryFn: async (id) => {
				const journals: Journal[] =
					(await localforage.getItem(`${JOURNALS_KEY}`)) ?? [];
				const journal = journals.find((journal) => journal.id === id);

				return {
					data: journal ?? null,
				};
			},
			providesTags: (result, error, id) => [{ type: 'Journals', id }],
		}),
		// TODO: this is a temp endpoint for local browser storage
		createJournal: builder.mutation<{ id: string; title: string }, void>({
			queryFn: () => {
				return new Promise((resolve) => {
					resolve({
						data: {
							id: uuid(),
							title: 'Untitled Journal',
						},
					});
				});
			},
			async onQueryStarted(args, { dispatch, getState, queryFulfilled }) {
				try {
					const { data: response } = await queryFulfilled;
					const { id, title } = response;

					const state = getState() as RootState;
					const currentUser = state.api.queries['getMe(undefined)']
						?.data as User;

					await dispatch(
						journalsApi.endpoints.saveJournal.initiate({
							id,
							title,
							users: [currentUser],
						}),
					);
				} catch (error) {
					// ignore error
				}
			},
		}),
		// TODO: this is a temp endpoint for local browser storage
		saveJournal: builder.mutation<Journal, Journal>({
			queryFn: (journal) => {
				return new Promise((resolve) => {
					void localforage.getItem(
						`${JOURNALS_KEY}`,
						(err, value: Journal[] | null) => {
							const journals = value ?? [];

							journals.push(journal);
							void localforage.setItem(JOURNALS_KEY, journals, () => {
								resolve({ data: journal });
							});
						},
					);
				});
			},
			invalidatesTags: ['Journals'],
		}),
		// TODO: this is a temp endpoint for local browser storage
		updateJournal: builder.mutation<
			Journal,
			Partial<Journal> & Pick<Journal, 'id'>
		>({
			queryFn: ({ id, ...patch }) => {
				return new Promise((resolve, reject) => {
					void localforage.getItem(
						`${JOURNALS_KEY}`,
						(err, value: Journal[] | null) => {
							const journals = value ?? [];

							const matchingIndex = journals.findIndex(
								(journal) => journal.id === id,
							);

							if (matchingIndex > -1) {
								const updatedJournal = {
									...journals[matchingIndex],
									...patch,
								};

								journals[matchingIndex] = updatedJournal;
								void localforage.setItem(JOURNALS_KEY, journals, () => {
									resolve({ data: updatedJournal });
								});
							} else {
								reject('Journal not found');
							}
						},
					);
				});
			},
			invalidatesTags: ['Journals'],
		}),
		// TODO: this is a temp endpoint for local browser storage
		deleteJournal: builder.mutation<string, string>({
			queryFn: (id) => {
				return new Promise((resolve) => {
					void localforage.getItem(
						`${JOURNALS_KEY}`,
						(err, value: Journal[] | null) => {
							const journals = value ?? [];
							void localforage.setItem(
								JOURNALS_KEY,
								journals.filter((journal) => journal.id !== id),
							);
							resolve({ data: id });
						},
					);
				});
			},
			invalidatesTags: ['Journals'],
		}),
		// TODO: this is a temp endpoint for local browser storage
		getJournalCategories: builder.query<JournalCategoriesResponse, void>({
			// query: () => ({ url: 'journal-categories' }),
			queryFn: async () => {
				const journalCategories: JournalCategory[] =
					(await localforage.getItem(`${JOURNAL_CATEGORIES_KEY}`)) ?? [];

				return {
					data: journalCategories,
				};
			},
			providesTags: ['JournalCategories'],
		}),
		// TODO: this is a temp endpoint for local browser storage
		createJournalCategory: builder.mutation<JournalCategory, string>({
			queryFn: (label) => {
				return new Promise((resolve) => {
					void localforage.getItem(
						`${JOURNAL_CATEGORIES_KEY}`,
						(err, value: JournalCategory[] | null) => {
							const journalCategories = value ?? [];

							const category = {
								id: uuid(),
								label,
							};

							journalCategories.push(category);
							void localforage.setItem(
								JOURNAL_CATEGORIES_KEY,
								journalCategories,
								() => {
									resolve({ data: category });
								},
							);
						},
					);
				});
			},
			invalidatesTags: ['JournalCategories'],
		}),
	}),
});

export const {
	useGetJournalsQuery,
	useGetJournalQuery,
	useCreateJournalMutation,
	useUpdateJournalMutation,
	useDeleteJournalMutation,
	useGetJournalCategoriesQuery,
	useCreateJournalCategoryMutation,
} = journalsApi;
