import { useGetIndexingStatusQuery } from '../api/autopilotsApi';

export const useIndexing = () => {
	const { data: indexingStatus } = useGetIndexingStatusQuery();

	return {
		isIndexing: indexingStatus?.status === 'in_progress',
		progress: indexingStatus?.logs
			? (indexingStatus.logs.filter((log) => log.is_indexed).length /
					indexingStatus.logs.length) *
				100
			: 0,
	};
};
