import { useEffect, useState } from 'react';

import localforage from 'localforage';

import { AUTOPILOTS_DRAFT_KEY } from '../constants';
import { Autopilot } from '../features/types';

export const useDraftAutopilots = () => {
	const [drafts, setDrafts] = useState([]);

	const removeDraft = (id: string) => {
		localforage.removeItem(`${AUTOPILOTS_DRAFT_KEY}-${id}`);
	};

	const saveDraft = (draft: any) => {
		localforage.setItem(`${AUTOPILOTS_DRAFT_KEY}-${draft.draft_id}`, draft);
	};

	const getDraft = (id: string) => {
		return localforage.getItem(`${AUTOPILOTS_DRAFT_KEY}-${id}`);
	};

	useEffect(() => {
		const fetchDrafts = async () => {
			const keys = await localforage.keys();
			const draftKeys = keys.filter((key) =>
				key.startsWith(AUTOPILOTS_DRAFT_KEY),
			);
			const storedDrafts = await Promise.all(
				draftKeys.map((key) => localforage.getItem(key)),
			);
			if (storedDrafts) {
				// Flatten the array of arrays
				const flattenedDrafts = storedDrafts.flat();
				setDrafts(flattenedDrafts as never[]);
			}
		};

		fetchDrafts();
	}, []);

	return { drafts, removeDraft, saveDraft, getDraft };
};
