import { useGetRecentEventsQuery } from '../api/userRecentEvents';

type UseUserRecentEvents = {
	eventNames?: string[];
	page?: number;
	pageSize?: number;
};

export const useUserRecentEvents = ({
	eventNames,
	page,
	pageSize,
}: UseUserRecentEvents) => {
	const { data, error, isLoading, refetch } = useGetRecentEventsQuery({
		event_name: eventNames ?? [],
		page: page ?? 1,
		page_size: pageSize ?? 10,
	});
	return { data, error, isLoading, refetch };
};
