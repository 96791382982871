import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { CompletionCurrentItem, CompletionHistoryItem } from '../api/types';

export interface CompletionsState {
	history: CompletionHistoryItem[];
	currentCompletions: CompletionCurrentItem[];
}

const initialState: CompletionsState = {
	history: [],
	currentCompletions: [],
};

const completionsSlice = createSlice({
	name: 'completions',
	initialState,
	reducers: {
		setCompletionsHistory: (
			state,
			action: PayloadAction<CompletionHistoryItem[]>,
		) => {
			state.history = action.payload;
		},
		setCurrentCompletions: (
			state,
			action: PayloadAction<CompletionCurrentItem[]>,
		) => {
			state.currentCompletions = action.payload;
		},
		resetCurrentCompletions: (state) => {
			state.currentCompletions = [];
		},
		addCurrentCompletions: (
			state,
			action: PayloadAction<CompletionCurrentItem>,
		) => {
			state.currentCompletions = [...state.currentCompletions, action.payload];
		},
		updateCurrentCompletions: (
			state,
			action: PayloadAction<CompletionCurrentItem>,
		) => {
			state.currentCompletions = state.currentCompletions.map((item) =>
				item.id === action.payload.id ? { ...item, ...action.payload } : item,
			);
		},
	},
});

export const {
	setCompletionsHistory,
	setCurrentCompletions,
	resetCurrentCompletions,
	addCurrentCompletions,
	updateCurrentCompletions,
} = completionsSlice.actions;

export default completionsSlice.reducer;
