import { Note, NoteResponse, Task, TaskResponse } from '../api/types';

export const isCRMNote = (note: Note | NoteResponse): note is Note => {
	if (!note) return false;
	return 'note_id' in note;
};

export const isCRMTask = (task: Task | TaskResponse): task is Task => {
	if (!task) return false;
	return 'task_id' in task;
};

// Helper functions to normalize data
export const normalizeNote = (note: Note | NoteResponse) => {
	if (isCRMNote(note)) {
		return {
			id: note.note_id,
			content: note.note_body,
			created_ts: note.created_ts,
			content_type: 'CRM Note',
		};
	}
	return {
		id: note.id,
		content: note.content,
		created_ts: note.created_ts,
		content_type: 'Internal Note',
		title: note.title,
	};
};

export const normalizeTask = (task: Task | TaskResponse) => {
	if (isCRMTask(task)) {
		return {
			id: task.task_id!,
			content: task.task_body,
			title: task.task_subject,
			created_ts: task.created_ts,
			content_type: 'CRM Task',
			status: task.task_status,
			content_metadata: {
				due_date: task.task_due_date,
			},
		};
	}
	return {
		id: task.id,
		content: task.content,
		title: task.title,
		created_ts: task.created_ts,
		content_type: 'Internal Task',
		status: task.status,
		content_metadata: task.content_metadata,
	};
};
