declare let posthog: any;

type PosthogProperties = Record<string, any>;

export const PosthogUtil = {
	captureEvent: (eventName: string, properties?: PosthogProperties) => {
		if (typeof posthog !== 'undefined') {
			posthog.capture(eventName, properties);
		}
	},
	resetUser: () => {
		if (typeof posthog !== 'undefined') {
			posthog.reset();
		}
	},
	identifyUser: (userId: string, properties?: PosthogProperties) => {
		if (typeof posthog !== 'undefined') {
			posthog.identify(userId, properties);
		}
	},
};
