import { api } from './base';
import { DisconnectAppResponse, GoogleAppType, IntegrationApp } from './types';

const integrationsApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getIntegrations: builder.query<IntegrationApp[], void>({
			query() {
				return {
					url: '/integrations/',
					method: 'GET',
				};
			},
			providesTags: ['Integrations'],
		}),
		disconnectIntegration: builder.mutation<DisconnectAppResponse, string>({
			query(appId) {
				return {
					url: `/integrations/${appId}/disconnect`,
					method: 'POST',
				};
			},
			transformResponse: (
				response: { status: string },
				meta: { response: Response },
			) => ({
				message: response.status,
				isSuccess: meta?.response?.ok ?? false,
				status: meta?.response?.status ?? 0,
			}),
			invalidatesTags: ['Integrations'],
		}),
		googleAppConnect: builder.mutation<
			{ isSuccess: boolean; status: number },
			{ app: GoogleAppType; clientData: { code: string; scope: string } }
		>({
			query({ app, clientData }) {
				return {
					url: `${app.receive_code_url}`,
					method: 'POST',
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
						'X-Requested-With': 'XmlHttpRequest',
					},
					body: `code=${clientData.code}&scope=${clientData.scope}`,
				};
			},
			transformResponse: (response: Response) => ({
				isSuccess: response?.ok ?? false,
				status: response?.status ?? 0,
			}),
			invalidatesTags: ['Integrations'],
		}),
		googleAppBatchConnect: builder.mutation<
			{ isSuccess: boolean; status: number },
			{ clientData: { code: string; scope: string }; appIds: string[] }
		>({
			query({ clientData, appIds }) {
				return {
					url: `/integrations/google_apps/receive_code`,
					method: 'POST',
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
						'X-Requested-With': 'XmlHttpRequest',
					},
					body: `code=${clientData.code}&scope=${clientData.scope}&app_ids=${appIds}`,
				};
			},
			transformResponse: (response: Response) => ({
				isSuccess: response?.ok ?? false,
				status: response?.status ?? 0,
			}),
			invalidatesTags: ['Integrations'],
		}),
		disconnectGoogleApps: builder.mutation<
			{ isSuccess: boolean; status: number },
			void
		>({
			query() {
				return {
					url: `/integrations/google_apps/disconnect`,
					method: 'POST',
				};
			},
			transformResponse: (response: Response) => ({
				isSuccess: response?.ok ?? false,
				status: response?.status ?? 0,
			}),
			invalidatesTags: ['Integrations'],
		}),
		connectIntegration: builder.mutation<
			{ isSuccess: boolean; status: number },
			{ appPath: string; params: Record<string, string> }
		>({
			query({ appPath, params }) {
				return {
					url: `/integrations/${appPath}`,
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(params),
				};
			},
			transformResponse: (response: Response) => ({
				isSuccess: response?.ok ?? false,
				status: response?.status ?? 0,
			}),
			invalidatesTags: ['Integrations'],
		}),
	}),
});

export const {
	useGetIntegrationsQuery,
	useDisconnectIntegrationMutation,
	useGoogleAppConnectMutation,
	useGoogleAppBatchConnectMutation,
	useDisconnectGoogleAppsMutation,
	useConnectIntegrationMutation,
} = integrationsApi;
