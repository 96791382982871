import { serializeArrayParams } from '../utils/helper';
import { agentApi } from './base';
import {
	Company,
	CompanyFilterOptions,
	CompanyListParams,
	CompanyListRequest,
	CompanyListResponse,
	CreateCompanyRequest,
	Deal,
	FilterOptions,
	PaginatedResponse,
	UpdateCompanyRequest,
	Note,
	Task,
} from './types';

export const companyApi = agentApi.injectEndpoints({
	endpoints: (builder) => ({
		// GET /company/list
		getCompanies: builder.query<CompanyListResponse, CompanyListRequest>({
			query: (params) => {
				const { filterKey, ...queryParams } = params;
				const arrayParams = {
					type: queryParams.type || [],
					health: queryParams.health || [],
					industry: queryParams.industry || [],
				};

				const baseParams = {
					enterprise_id: queryParams.enterprise_id,
					per_page: queryParams.per_page,
					search: queryParams.search,
					page: queryParams.page,
				};

				const arrayParamsString = serializeArrayParams(arrayParams);
				const baseParamsString = new URLSearchParams(
					Object.entries(baseParams)
						.filter(([_, v]) => v != null)
						.map(([k, v]) => [k, v?.toString() ?? '']),
				).toString();

				const queryString = [baseParamsString, arrayParamsString]
					.filter(Boolean)
					.join('&');

				return {
					url: `/company/list${queryString ? `?${queryString}` : ''}`,
					method: 'GET',
				};
			},
			serializeQueryArgs: ({ queryArgs }) => {
				if (!queryArgs) return '';
				return JSON.stringify({
					search: queryArgs.search,
					type: queryArgs.type,
					health: queryArgs.health,
					industry: queryArgs.industry,
					enterprise_id: queryArgs.enterprise_id,
				});
			},
			merge: (currentCache, newItems, { arg }) => {
				if (!arg || arg.page === 1) {
					return newItems;
				}
				return {
					...newItems,
					companies: [
						...(currentCache?.companies || []),
						...newItems.companies,
					].filter(
						(company, index, self) =>
							index === self.findIndex((c) => c.id === company.id),
					),
				};
			},
			forceRefetch: ({ currentArg, previousArg }) => {
				if (!currentArg || !previousArg) return true;

				if (currentArg.page !== previousArg.page) {
					return true;
				}

				const currentFilters = JSON.stringify({
					search: currentArg.search,
					type: currentArg.type,
					health: currentArg.health,
					industry: currentArg.industry,
					enterprise_id: currentArg.enterprise_id,
				});

				const previousFilters = JSON.stringify({
					search: previousArg.search,
					type: previousArg.type,
					health: previousArg.health,
					industry: previousArg.industry,
					enterprise_id: previousArg.enterprise_id,
				});

				return currentFilters !== previousFilters;
			},
			providesTags: (result) =>
				result
					? [
							...result.companies.map(({ id }) => ({
								type: 'Company' as const,
								id,
							})),
							{ type: 'CompanyList' as const, id: 'LIST' },
						]
					: [{ type: 'CompanyList' as const, id: 'LIST' }],
		}),

		// GET /company/filter-options
		getCompanyFilterOptions: builder.query<CompanyFilterOptions, void>({
			query: () => ({
				url: '/company/filter-options',
				method: 'GET',
			}),
			providesTags: ['CompanyList'],
		}),

		// GET /company/<company_id>
		getCompany: builder.query<
			Company,
			{ company_id: string; enterprise_id: string }
		>({
			query: ({ company_id, enterprise_id }) => ({
				url: `/company/${company_id}`,
				method: 'GET',
				params: { enterprise_id },
			}),
			providesTags: (_result, _error, { company_id }) => [
				{ type: 'Company', id: company_id },
			],
		}),

		// POST /company
		createCompany: builder.mutation<Company, CreateCompanyRequest>({
			query: (body) => ({
				url: '/company',
				method: 'POST',
				body,
			}),
			invalidatesTags: ['CompanyList'],
		}),

		// PUT /company/<company_id>
		updateCompany: builder.mutation<
			Company,
			{ company_id: string; enterprise_id: string; body: UpdateCompanyRequest }
		>({
			query: ({ company_id, enterprise_id, body }) => ({
				url: `/company/${company_id}`,
				method: 'PUT',
				params: { enterprise_id },
				body,
			}),
			invalidatesTags: (_result, _error, { company_id }) => [
				'CompanyList',
				{ type: 'Company', id: company_id },
			],
		}),

		// DELETE /company/<company_id>
		deleteCompany: builder.mutation<
			void,
			{ company_id: string; enterprise_id: string }
		>({
			query: ({ company_id, enterprise_id }) => ({
				url: `/company/${company_id}`,
				method: 'DELETE',
				params: { enterprise_id },
			}),
			invalidatesTags: ['CompanyList'],
		}),

		// GET /company/<company_id>/deals
		getCompanyDeals: builder.query<
			PaginatedResponse<Deal>,
			{
				company_id: string;
				enterprise_id: string;
				page?: number;
				per_page?: number;
			}
		>({
			query: ({ company_id, enterprise_id, page = 1, per_page = 40 }) => ({
				url: `/company/${company_id}/deals`,
				method: 'GET',
				params: {
					enterprise_id,
					page,
					per_page,
				},
			}),
			providesTags: (result, error, { company_id }) => [
				{ type: 'Company', id: `${company_id}-deals` },
			],
		}),

		// GET /company/<company_id>/notes
		getCompanyNotes: builder.query<
			PaginatedResponse<Note>,
			{
				company_id: string;
				page?: number;
				per_page?: number;
			}
		>( {
			query: ( { company_id, page = 1, per_page = 40 } ) => ( {
				url: `/company/${ company_id }/notes`,
				method: 'GET',
				params: {
					page,
					per_page,
				},
			} ),
			serializeQueryArgs: ( { queryArgs } ) => {
				return `${ queryArgs.company_id }-${ queryArgs.page }`;
			},
			merge: ( currentCache, newItems, { arg: { page } } ) => {
				if ( page === 1 ) {
					return newItems;
				}
				return {
					...newItems,
					data: [ ...( currentCache?.data || [] ), ...newItems.data ],
				};
			},
			forceRefetch: ( { currentArg, previousArg } ) => {
				if ( !previousArg ) return true;
				return (
					currentArg?.page !== previousArg?.page ||
					currentArg?.company_id !== previousArg?.company_id
				);
			},
			providesTags: ( result, error, { company_id } ) => [
				{ type: 'Company', id: `${ company_id }-notes` },
			],
		} ),

		// GET /company/<company_id>/tasks
		getCompanyTasks: builder.query<
			PaginatedResponse<Task>,
			{
				company_id: string;
				page?: number;
				per_page?: number;
			}
		>( {
			query: ( { company_id, page = 1, per_page = 40 } ) => ( {
				url: `/company/${ company_id }/tasks`,
				method: 'GET',
				params: {
					page,
					per_page,
				},
			} ),
			serializeQueryArgs: ( { queryArgs } ) => {
				return `${ queryArgs.company_id }-${ queryArgs.page }`;
			},
			merge: ( currentCache, newItems, { arg: { page } } ) => {
				if ( page === 1 ) {
					return newItems;
				}
				return {
					...newItems,
					data: [ ...( currentCache?.data || [] ), ...newItems.data ],
				};
			},
			forceRefetch: ( { currentArg, previousArg } ) => {
				if ( !previousArg ) return true;
				return (
					currentArg?.page !== previousArg?.page ||
					currentArg?.company_id !== previousArg?.company_id
				);
			},
			providesTags: ( result, error, { company_id } ) => [
				{ type: 'Company', id: `${ company_id }-tasks` },
			],
		} ),
	}),
});

export const {
	useGetCompaniesQuery,
	useGetCompanyFilterOptionsQuery,
	useGetCompanyQuery,
	useCreateCompanyMutation,
	useUpdateCompanyMutation,
	useDeleteCompanyMutation,
	useGetCompanyDealsQuery,
	useGetCompanyNotesQuery,
	useGetCompanyTasksQuery,
} = companyApi;
