import { useState, useEffect } from 'react';
import { useGetSubscriptionStatusQuery } from '../api/billingApi';

export const useBasicSubscriptionCheck = () => {
    const { data, error, isLoading, refetch } = useGetSubscriptionStatusQuery();
	const [ isActive, setIsActive ] = useState<boolean | null>( null );

	useEffect( () => {
		if ( !isLoading && data ) {
			setIsActive( data.is_active );
        }
	}, [ data, isLoading ] );

    return { isActive, error, isLoading, refetch };
};
