// to rebuild image
export enum ContentTypeEnum {
	NOTE = 'NOTE',
	TASK = 'TASK',
	PRE_MEETING_BRIEF = 'PRE_MEETING_BRIEF',
	POST_MEETING_SUMMARY = 'POST_MEETING_SUMMARY',
}

export enum ContentStatusEnum {
	DRAFT = 'DRAFT',
	PUBLISHED = 'PUBLISHED',
	COMPLETED = 'COMPLETED',
	ARCHIVED = 'ARCHIVED',
	DELETED = 'DELETED',
}

export enum CollectionTypeEnum {
	PERSON = 'PERSON',
	MEETING = 'MEETING',
	CUSTOMER = 'CUSTOMER',
	USER = 'USER',
	EMAIL = 'EMAIL',
	CONVERSATION = 'CONVERSATION',
	PRE_MEETING_BRIEF = 'PRE_MEETING_BRIEF',
	COMPANY = 'COMPANY',
	DEAL = 'DEAL',
}
