import { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { NLSearchResult } from '../api/types';
import {
	clearSelection as clearSelectionAction,
	selectAll as selectAllAction,
	setInitialSelectedResults as setInitialSelectedResultsAction,
	setSelectionMode as setSelectionModeAction,
	toggleResultSelection as toggleResultSelectionAction,
	toggleSelectionMode as toggleSelectionModeAction,
} from '../features/searchSelectionSlice';
import { RootState, useTypedSelector } from '../store';

export const useSearchSelection = (isDialog?: boolean) => {
	const dispatch = useDispatch();
	const { isSelectionMode, selectedResults } = useTypedSelector(
		(state: RootState) => state.searchSelectionState,
	);

	// Reset selection mode when landing on search page (non-dialog)
	useEffect(() => {
		if (!isDialog && window.location.pathname === '/search') {
			dispatch(setSelectionModeAction(false));
		}
	}, [isDialog]);

	const toggleSelectionMode = () => dispatch(toggleSelectionModeAction());
	const toggleResultSelection = (result: NLSearchResult) => {
		dispatch(toggleResultSelectionAction(result));
	};
	const clearSelection = () => dispatch(clearSelectionAction());
	const selectAll = (results: NLSearchResult[]) => {
		dispatch(selectAllAction(results));
	};

	const setSelectedResults = (results: NLSearchResult[]) => {
		clearSelection();
		results.forEach((result) => toggleResultSelection(result));
	};

	const setInitialSelectedResults = (results: NLSearchResult[]) => {
		dispatch(setInitialSelectedResultsAction(results));
	};

	const setSelectionMode = (mode: boolean) => {
		dispatch(setSelectionModeAction(mode));
	};

	return {
		isSelectionMode,
		selectedResults,
		toggleSelectionMode,
		toggleResultSelection,
		clearSelection,
		setSelectedResults,
		selectAll,
		setInitialSelectedResults,
		setSelectionMode,
	};
};
