import { api } from './base';
import { CreateSearchRequest, Search, UpdateSearchRequest } from './types';

const SEARCH_API_ENDPOINT = '/search';

export const searchApi = api.injectEndpoints({
	endpoints: (builder) => ({
		createSearch: builder.mutation<Search, CreateSearchRequest>({
			query: (body) => ({
				url: `${SEARCH_API_ENDPOINT}/`,
				method: 'POST',
				body,
			}),
		}),
		getSearch: builder.query<Search, string>({
			query: (id) => `${SEARCH_API_ENDPOINT}/${id}`,
			providesTags: (result, error, id) => [{ type: 'Search', id }],
		}),
		updateSearch: builder.mutation<
			Search,
			{ id: string; body: UpdateSearchRequest }
		>({
			query: ({ id, body }) => ({
				url: `${SEARCH_API_ENDPOINT}/${id}`,
				method: 'PUT',
				body,
			}),
		}),
		deleteSearch: builder.mutation<void, string>({
			query: (id) => ({
				url: `${SEARCH_API_ENDPOINT}/${id}`,
				method: 'DELETE',
			}),
		}),
		getSearches: builder.query<Search[], { page?: number; perPage?: number }>({
			query: ({ page = 1, perPage = 20 }) =>
				`${SEARCH_API_ENDPOINT}/?page=${page}&per_page=${perPage}`,
		}),
		searchPastSearches: builder.query<
			Search[],
			{ query: string; limit?: number }
		>({
			query: ({ query, limit = 10 }) =>
				`${SEARCH_API_ENDPOINT}/past?query=${encodeURIComponent(query)}&limit=${limit}`,
		}),
	}),
});

export const {
	useCreateSearchMutation,
	useGetSearchQuery,
	useUpdateSearchMutation,
	useDeleteSearchMutation,
	useGetSearchesQuery,
	useSearchPastSearchesQuery,
} = searchApi;
