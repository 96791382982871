import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
	BaseQueryFn,
	FetchArgs,
	FetchBaseQueryError,
	FetchBaseQueryMeta,
	createApi,
	fetchBaseQuery,
	retry,
} from '@reduxjs/toolkit/dist/query/react';

import {
	CSRF_TOKEN_KEY,
	LOGIN_ERROR_MESSAGE_KEY,
	PUBLIC_ROUTES,
} from '../constants';
import { setAccessToken, setIsRefreshing } from '../features/authSlice';
import { TokenUtil } from '../services/token';
import { RootState } from '../store';
import { getCookie, isChromeExtension, isPublicPage } from '../utils/helper';
import { RefreshTokenResponse } from './types';

const NO_RETRY_STATUS_CODES = [401, 403, 404];
const NO_RETRY_HTTP_METHODS = ['POST', 'PUT', 'PATCH', 'DELETE'];
const SESSION_EXPIRED_MESSAGE = 'Session expired. Please log in again.';
const LOGIN_PATH = '/app/login';

type StateShape = {
	tenantConfigState?: {
		api_url?: string;
		app_url?: string;
	};
	authState?: {
		accessToken?: string;
		isRefreshing?: boolean;
	};
	api: {
		queries?: Record<string, any>;
	};
};

let refreshPromise: Promise<string | null> | null = null;

const isPublicRoute = (path: string): boolean => {
	// Check if the path starts with any of the public routes
	path = path.replace('/app', '');
	return PUBLIC_ROUTES.some((route) => path.startsWith(route));
};

const baseQuery =
	(
		baseQueryArgs = {},
	): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> =>
	async (fetchArgs, api, extraOptions: any = {}) => {
		const { getState } = api;
		const state = getState() as RootState;
		const currentPath = window.location.pathname;

		let access_token = state.authState?.accessToken ?? '';

		if (!access_token) {
			access_token = await TokenUtil.getAccessToken(
				state.tenantConfigState?.app_url,
			);
		}

		// Modified condition to check for both public pages and public routes
		if (
			!access_token &&
			!isPublicPage(currentPath) &&
			!isPublicRoute(currentPath)
		) {
			await handleAuthTokenError('No access token available');
			return {
				error: {
					status: 401,
					data: { message: 'No access token available' },
				},
			};
		}

		const baseUrl =
			extraOptions.customBaseUrl ||
			state.tenantConfigState?.api_url ||
			`${process.env.REACT_APP_API_URL}`;

		if (!baseUrl) {
			console.error('No base URL available');
			return {
				error: { status: 'CUSTOM_ERROR' } as FetchBaseQueryError,
				data: undefined,
				meta: {},
			};
		}

		const csrfToken = getCookie(CSRF_TOKEN_KEY);

		return fetchBaseQuery({
			baseUrl,
			credentials: 'include',
			prepareHeaders: (headers: Headers) => {
				if (access_token && !headers.has('authorization')) {
					headers.set('authorization', `Bearer ${access_token}`);
				}
				if (csrfToken) {
					headers.set('X-CSRF-Token', csrfToken);
				}
				return headers;
			},
			...baseQueryArgs,
		})(fetchArgs, api, extraOptions);
	};

const baseQueryWithRetry = retry(baseQuery(), {
	retryCondition: (
		error: FetchBaseQueryError,
		args: string | FetchArgs,
		{ attempt },
	) => {
		if (
			typeof error?.status === 'number' &&
			NO_RETRY_STATUS_CODES.includes(error.status)
		) {
			return false;
		}

		if (
			typeof args !== 'string' &&
			NO_RETRY_HTTP_METHODS.includes(args.method as string)
		) {
			return false;
		}

		return attempt <= 3;
	},
});
const baseQueryWithReauth: BaseQueryFn<
	string | FetchArgs,
	unknown,
	FetchBaseQueryError
> = async (args, api, extraOptions) => {
	let result = {} as QueryReturnValue<
		unknown,
		FetchBaseQueryError,
		FetchBaseQueryMeta
	>;

	try {
		if (args) {
			result = (await baseQueryWithRetry(
				args,
				api,
				extraOptions,
			)) as QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>;
		}

		if (result?.error?.status === 401 || result?.error?.status === 403) {
			const state = api.getState() as StateShape;

			// Check if already refreshing
			if (state.authState?.isRefreshing) {
				// Wait for the existing refresh to complete
				if (refreshPromise) {
					const newToken = await refreshPromise;
					if (newToken) {
						// Retry the original request with new token
						return baseQuery()(args, api, extraOptions);
					}
				}
				return result;
			}

			// Start new refresh
			api.dispatch(setIsRefreshing(true));
			refreshPromise = refreshAccessToken(api, extraOptions);

			try {
				const newToken = await refreshPromise;
				if (newToken) {
					// Retry the original request
					return baseQuery()(args, api, extraOptions);
				}
			} finally {
				api.dispatch(setIsRefreshing(false));
				refreshPromise = null;
			}
		}
	} catch (error) {
		console.error('Base.Query.Error', error);
		result.error = {
			status: 'CUSTOM_ERROR',
			error: 'Unknown error occurred',
		};
	}

	return result;
};

// Separate refresh token logic
const refreshAccessToken = async (
	api: any,
	extraOptions: any,
): Promise<string | null> => {
	const state = api.getState() as StateShape;
	const app_url = state.tenantConfigState?.app_url;
	const refreshToken = await TokenUtil.getRefreshToken(app_url);
	if (!refreshToken) {
		await handleAuthTokenError('');
		return null;
	}

	try {
		const refreshResult = await baseQuery()(
			{
				url: `${process.env.REACT_APP_API_URL}/refresh-token`,
				method: 'GET',
				headers: {
					authorization: `Bearer ${refreshToken}`,
				},
			},
			api,
			extraOptions,
		);

		if (refreshResult.error) {
			throw new Error('Refresh token failed');
		}

		const refreshData = refreshResult.data as RefreshTokenResponse;
		if (!refreshData?.data?.access_token) {
			throw new Error('No access token in refresh response');
		}

		// Update tokens
		await TokenUtil.setAccessToken(refreshData.data.access_token, app_url);
		api.dispatch(setAccessToken(refreshData.data.access_token));
		return refreshData.data.access_token;
	} catch (error) {
		console.debug('Base.TokenRefresh.Error', error);
		await handleAuthTokenError(SESSION_EXPIRED_MESSAGE);
		return null;
	}
};

const handleAuthTokenError = async (message: string) => {
	const currentPath = window.location.pathname;
	// Don't redirect if it's a public route
	if (
		!isPublicPage(currentPath) &&
		!isChromeExtension &&
		!isPublicRoute(currentPath)
	) {
		// Clear tokens
		const app_url = window.location.origin;
		await TokenUtil.removeAccessToken(app_url);
		await TokenUtil.removeRefreshToken(app_url);

		localStorage.setItem(LOGIN_ERROR_MESSAGE_KEY, message);

		// Determine redirect path
		const redirectPath = currentPath.includes('/signup')
			? '/app/signup'
			: LOGIN_PATH;

		if (currentPath !== redirectPath) {
			window.location.href = `${redirectPath}?redirect=${encodeURIComponent(currentPath)}`;
		}
	}
};

const api = createApi({
	reducerPath: 'api',
	baseQuery: baseQueryWithReauth,
	endpoints: () => ({}),
	tagTypes: [
		'Me',
		'Chats',
		'Journals',
		'Journal',
		'JournalCategories',
		'ChatMessages',
		'Integrations',
		'Enterprise',
		'EnterpriseUsers',
		'Autopilots',
		'UserPreferences',
		'UserRecentEvents',
		'Search',
		'Favorites',
		'EnterprisePreferences',
	],
});

const contentApi = createApi({
	reducerPath: 'contentApi',
	baseQuery: (args, api, extraOptions) =>
		baseQueryWithReauth(args, api, {
			...extraOptions,
			customBaseUrl: `${process.env.REACT_APP_CONTENT_SERVICE_API_URL}/api/`,
		}),
	endpoints: () => ({}),
	tagTypes: ['Meeting', 'Conversation', 'Snippet', 'Notes', 'Tasks'],
});

const agentApi = createApi({
	reducerPath: 'agentApi',
	baseQuery: (args, api, extraOptions) =>
		baseQueryWithReauth(args, api, {
			...extraOptions,
			customBaseUrl: `${process.env.REACT_APP_AGENT_SERVICE_API_URL}`,
		}),
	endpoints: () => ({}),
	tagTypes: [
		'Agent',
		'ExecuteSearch',
		'RelatedPeople',
		'PeopleList',
		'Person',
		'PersonNotes',
		'PersonDeals',
		'PersonTasks',
		'PersonSharedUsers',
		'Company',
		'CompanyList',
		'Deal',
		'DealList',
		'Search',
		'NLSearch',
	],
});

const meetingApi = createApi({
	reducerPath: 'meetingApi',
	baseQuery: (args, api, extraOptions) =>
		baseQueryWithReauth(args, api, {
			...extraOptions,
			customBaseUrl: `${process.env.REACT_APP_MEETING_API_URL}`,
		}),
	endpoints: () => ({}),
	tagTypes: ['SharedUsers'],
});

export { contentApi, api, agentApi, meetingApi };
