import { useEffect, useState } from 'react';

import { useGetAutopilotsQuery } from '../api/autopilotsApi';
import { AutopilotsApiRequest } from '../api/types';
import { Autopilot } from '../features/types';

export function useAutopilots({
	enterpriseId,
	page,
	perPage,
	search,
	userIds,
	isActionAP,
}: AutopilotsApiRequest) {
	// const autopilotsData = useTypedSelector(selectAutopilots);

	const [autopilots, setAutopilots] = useState<Autopilot[]>([]);
	const [total, setTotal] = useState(0);
	const [currentPage, setCurrentPage] = useState(page ?? 1);
	const [currentPerPage, setCurrentPerPage] = useState(perPage ?? 10);
	const { data, error, isLoading, isFetching, refetch } = useGetAutopilotsQuery(
		{
			enterpriseId: enterpriseId,
			page: currentPage,
			perPage: currentPerPage,
			search: search ?? '',
			userIds: userIds ?? [],
			isActionAP: isActionAP ?? false,
		},
	);

	useEffect(() => {
		if (data) {
			setAutopilots(data.autopilots);
			setTotal(data.total);
			setCurrentPage(data.page);
			setCurrentPerPage(data.per_page);
		}
	}, [data]);

	const handleRefetch = (
		params: Partial<{ page: number; perPage: number }>,
	) => {
		if (params.page) setCurrentPage(params.page);
		if (params.perPage) setCurrentPerPage(params.perPage);
		refetch();
	};

	return {
		autopilots,
		total,
		page: currentPage,
		perPage: currentPerPage,
		isLoading,
		isFetching,
		error,
		// autopilotsData,
		refetch: handleRefetch,
	};
}
