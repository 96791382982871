import { useCallback } from 'react';

import { UUID } from 'crypto';

import { useLazyGetMeetingsMetastoreIdsQuery } from '../api/metastoreApi';
import { GetMeetingsMetastoreIdsResponse } from '../api/types';

export const useGetMeetingsMetastoreIds = () => {
	const [trigger, { isLoading }] = useLazyGetMeetingsMetastoreIdsQuery();

	const getMeetingsMetastoreIds = useCallback(
		async (
			meetingIds: string[],
		): Promise<{ metastore_id: UUID; meeting_id: UUID }[]> => {
			try {
				const cleanedIds = meetingIds.filter((id) => id);
				const response = await trigger({ ids: cleanedIds }).unwrap();
				return response.metastore_ids;
			} catch (error) {
				console.error('Error fetching metastore IDs:', error);
				return [];
			}
		},
		[trigger],
	);

	return {
		getMeetingsMetastoreIds,
		isLoading,
	};
};
