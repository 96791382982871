import React from "react";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { Provider as ReduxProvider } from "react-redux";
import { RouterProvider } from "react-router-dom";

import { store } from "@mightybot/core";
import { Provider as BaseProvider } from "@mightybot/web-ui";

import { createAppRouter } from "./router";
import { initializeAnalytics } from "./utils/initializeAnalytics";
import { initializeLogger } from "./utils/initializeLogger";
import reportWebVitals from "./utils/reportWebVitals";
import { ContentfulClient, ContentfulProvider } from "react-contentful";

// Tailwind CSS and global styles
import "./styles/global.css";

// Add TypeScript declaration for module.hot at the top of the file
declare global {
	interface NodeModule {
		hot?: {
			accept(path?: string, callback?: () => void): void;
			decline(path?: string): void;
			dispose(callback: (data: any) => void): void;
			addDisposeHandler(callback: (data: any) => void): void;
			removeDisposeHandler(callback: (data: any) => void): void;
			status(): string;
		};
	}
}

// Initialize analytics
initializeAnalytics();

// Initialize logger
initializeLogger();

// Create the router
const router = createAppRouter();

// Render the app
const container = document.getElementById("root");

const contentfulClient = ContentfulClient({
	space: process.env.REACT_APP_CONTENTFUL_SPACE_ID || "",
	accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN || "",
});

const render = () => {
	if (container) {
		const root = createRoot(container as HTMLElement);
		root.render(
			<ReduxProvider store={store}>
				<BaseProvider>
					<HelmetProvider>
						{/* @ts-ignore */}
						<ContentfulProvider client={contentfulClient}>
							<RouterProvider
								router={router}
								future={{
									v7_startTransition: true,
								}}
							/>
						</ContentfulProvider>
					</HelmetProvider>
				</BaseProvider>
			</ReduxProvider>
		);
	}
};

render();

// Enable HMR with a more direct approach
if (module.hot) {
	// Accept the current module
	module.hot.accept();

	// Accept updates for the router
	module.hot.accept('./router', () => {
		console.log('HMR: Router update detected');
		render();
	});

	// Accept updates for any imported modules from other packages
	module.hot.accept('@mightybot/core', () => {
		console.log('HMR: Core package update detected');
		render();
	});

	module.hot.accept('@mightybot/web-ui', () => {
		console.log('HMR: Web UI package update detected');
		render();
	});

	module.hot.accept('@mightybot/core-ui', () => {
		console.log('HMR: Core UI package update detected');
		render();
	});
}

// Initialize service worker
// initializeServiceWorker();

// Report web vitals
reportWebVitals();
