import { UserPreferencesRequest, UserPreferencesResponse } from '../api/types';
import { api } from './base';

export const userPreferencesApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getUserPreferences: builder.query<UserPreferencesResponse, void>({
			query: () => 'users/preferences/',
			providesTags: ['UserPreferences'],
		}),
		updateUserPreferences: builder.mutation<
			UserPreferencesResponse,
			UserPreferencesRequest
		>({
			query: (preferences) => ({
				url: 'users/preferences/',
				method: 'POST',
				body: preferences,
			}),
			invalidatesTags: ['UserPreferences'],
		}),
	}),
});

export const { useGetUserPreferencesQuery, useUpdateUserPreferencesMutation } =
	userPreferencesApi;
