import { agentApi } from './base';
import {
	GetMeetingsMetastoreIdsRequest,
	GetMeetingsMetastoreIdsResponse,
} from './types';

// Custom function to serialize array parameters
const serializeArrayParams = (params: { ids: string[] }) => {
	const searchParams = new URLSearchParams();
	params.ids.forEach((id) => searchParams.append('ids', id));
	return searchParams.toString();
};

export const metastoreApi = agentApi.injectEndpoints({
	endpoints: (builder) => ({
		getMeetingsMetastoreIds: builder.query<
			GetMeetingsMetastoreIdsResponse,
			{ ids: string[] }
		>({
			query: ({ ids }) => ({
				url: `/metastore/meetings?${serializeArrayParams({ ids })}`,
				method: 'GET',
			}),
		}),
	}),
});

export const {
	useGetMeetingsMetastoreIdsQuery,
	useLazyGetMeetingsMetastoreIdsQuery,
} = metastoreApi;
