import React from 'react';

import Icon, { IconProps } from '../Icon';

const Spinner = (props: IconProps) => {
	return (
		<Icon.CircleNotch {...props}>
			<animateTransform
				attributeName="transform"
				attributeType="XML"
				type="rotate"
				dur="2s"
				from="0 0 0"
				to="360 0 0"
				repeatCount="indefinite"
			></animateTransform>
		</Icon.CircleNotch>
	);
};

export default Spinner;
