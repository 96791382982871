import React from 'react';

import { TextField } from '@radix-ui/themes';
import styled from 'styled-components';

const StyledInput = styled(TextField.Root)`
	// Add any default styles you want here
`;

export interface InputProps
	extends Omit<React.ComponentProps<typeof TextField.Root>, 'slot' | 'ref'> {
	slotContent?: React.ReactNode;
	ref?: React.Ref<HTMLInputElement>;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
	({ slotContent, children, ...props }, ref) => {
		return (
			<StyledInput {...props} ref={ref}>
				{slotContent && <TextField.Slot>{slotContent}</TextField.Slot>}
				{children}
			</StyledInput>
		);
	},
);

export default Input;

Input.displayName = 'Input';
