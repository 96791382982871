import { api } from './base';

export const userUnsubscribeApi = api.injectEndpoints({
	endpoints: (builder) => ({
		unsubscribeUser: builder.mutation<void, void>({
			query: () => ({
				url: 'notifications/email/unsubscribe',
				method: 'POST',
			}),
			async onQueryStarted(arg, { queryFulfilled }) {
				try {
					await queryFulfilled;
				} catch (error) {
					// console.error(error);
				}
			},
		}),
	}),
});

export const { useUnsubscribeUserMutation } = userUnsubscribeApi;
