import { contentApi as api } from './base';
import { CreateSnippetResponse, Snippet } from './types';
import {
	CreateSnippetRequest,
	DeleteSnippetResponse,
	ModifySnippetRequest,
	PublicSnippetResponse,
} from './types';

const snippetsApi = api.injectEndpoints({
	endpoints: (builder) => ({
		createSnippet: builder.mutation<
			CreateSnippetResponse,
			CreateSnippetRequest
		>({
			query: (body) => ({
				url: 'snippet/',
				method: 'POST',
				body,
			}),
		}),

		modifySnippet: builder.mutation<
			Snippet,
			{ id: string; body: ModifySnippetRequest }
		>({
			query: ({ id, body }) => ({
				url: `snippet/${id}`,
				method: 'PUT',
				body,
			}),
			// Invalidate the specific snippet and the list
			invalidatesTags: (result, error, { id }) => [
				{ type: 'Snippet', id },
				{ type: 'Snippet', id: 'LIST' },
			],
		}),

		deleteSnippet: builder.mutation<DeleteSnippetResponse, string>({
			query: (id) => ({
				url: `snippet/${id}`,
				method: 'DELETE',
			}),
			// Invalidate the list after deletion
			invalidatesTags: [{ type: 'Snippet', id: 'LIST' }],
		}),

		getSnippetStatus: builder.query<Snippet | PublicSnippetResponse, string>({
			query: (id) => ({
				url: `snippet/${id}`,
				method: 'GET',
				credentials: 'omit',
			}),
			providesTags: (result, error, id) => [{ type: 'Snippet', id }],
		}),

		getSnippetView: builder.query<string, string>({
			query: (id) => `snippet/${id}/view`,
		}),

		listSnippets: builder.query<Snippet[], { source_collection_id: string }>({
			query: ({ source_collection_id }) =>
				`snippet/?source_collection_id=${source_collection_id}`,
			providesTags: [{ type: 'Snippet', id: 'LIST' }],
		}),
	}),
});

export const {
	useCreateSnippetMutation,
	useModifySnippetMutation,
	useDeleteSnippetMutation,
	useGetSnippetStatusQuery,
	useGetSnippetViewQuery,
	useListSnippetsQuery,
} = snippetsApi;
