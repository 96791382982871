import {
	useFindPaymentTransactionQuery,
	useGetInvoicesQuery,
	useGetPlanQuery,
	useGetSubscriptionQuery,
} from '../api/billingApi';

export function useBilling() {
	const { data: plan, isLoading: isPlanLoading } = useGetPlanQuery();

	const { data: subscription, isLoading: isSubscriptionLoading } =
		useGetSubscriptionQuery();

	const { data: invoices, isLoading: isInvoicesLoading } =
		useGetInvoicesQuery();

	const {
		data: paymentTransaction,
		isLoading: isPaymentTransactionLoading,
		refetch: refetchPaymentTransaction,
		isError: isPaymentTransactionError,
	} = useFindPaymentTransactionQuery(undefined, {
		// Don't cache the result as we only want the most recent payment
		refetchOnMountOrArgChange: true,
	});

	const isLoading = isPlanLoading || isSubscriptionLoading || isInvoicesLoading;

	const isOnTrial = subscription?.is_trial || false;
	const trialDaysRemaining = subscription?.trial_days_remaining || 0;
	const hasPaymentMethod = subscription?.has_payment_method || false;
	const nextBillingDate = subscription?.next_billing_date;
	const currentSeats = subscription?.current_seats || 1;
	const totalSeats = subscription?.total_seats || 1;
	const isSubscribed =
		!subscription && isSubscriptionLoading
			? true
			: subscription?.status === 'ACTIVE'
				? true
				: false;

	return {
		plan,
		subscription,
		invoices,
		isLoading,
		isOnTrial,
		trialDaysRemaining,
		hasPaymentMethod,
		nextBillingDate,
		currentSeats,
		totalSeats,
		isSubscribed,
		paymentTransaction,
		isPaymentTransactionLoading,
		isPaymentTransactionError,
		refetchPaymentTransaction,
	};
}
