import { agentApi } from './base';
import {
	NLSearchRequest,
	NLSearchResponse,
	SearchCountsResponse,
	StructuredSearchRequest,
	ActivitiesSearchRequest,
	ActivitiesSearchResponse,
} from './types';

export const agentSearchApi = agentApi.injectEndpoints({
	endpoints: (builder) => ({
		executeNLSearch: builder.query<NLSearchResponse, NLSearchRequest>({
			query: (body) => {
				return {
					url: '/search/nl',
					method: 'POST',
					body,
				}
			},
			keepUnusedDataFor: 200,
			serializeQueryArgs: ({ queryArgs }) => {
				const stableKey = JSON.stringify({
					query: queryArgs.query,
					filters: queryArgs.filters ? JSON.stringify(queryArgs.filters, Object.keys(queryArgs.filters).sort()) : null,
					search_id: queryArgs.search_id,
				});
				return stableKey;
			},

			merge: (currentCache, newItems) => {
				if (newItems.page === 1) {
					return newItems;
				}

				return {
					...newItems,
					results: [...(currentCache.results || []), ...(newItems.results || [])],
					total_pages: newItems.total_pages,
					total_items: newItems.total_items,
					page: newItems.page,
					count: newItems.count,
				};
			},
			forceRefetch: ({ currentArg, previousArg }) => {
				return (
					currentArg?.query !== previousArg?.query ||
					currentArg?.search_id !== previousArg?.search_id ||
					JSON.stringify(currentArg?.filters) !== JSON.stringify(previousArg?.filters)
				);
			},
		}),
		executeStructuredSearch: builder.mutation<
			NLSearchResponse,
			StructuredSearchRequest
		>({
			query: (body) => ({
				url: '/search/structured',
				method: 'POST',
				body,
			}),
		}),
		getAppSearchCounts: builder.query<SearchCountsResponse, void>({
			query: () => ({
				url: '/search/counts',
				method: 'GET',
			}),
		}),
		getActivitiesSearch: builder.query<
			ActivitiesSearchResponse,
			ActivitiesSearchRequest
		>({
			query: ({ entity_id, entity_type, page = 1, per_page = 50, team_view = false, apps = [] }) => ({
				url: `/search/activities`,
				method: 'GET',
				params: {
					entity_id,
					entity_type,
					page,
					per_page,
					team_view,
					...(apps.length > 0 ? { apps } : {}),
				},
			}),
		}),
	}),
});

export const {
	useExecuteNLSearchQuery,
	useExecuteStructuredSearchMutation,
	useGetAppSearchCountsQuery,
	useGetActivitiesSearchQuery,
} = agentSearchApi;
