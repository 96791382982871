import { useEffect, useMemo } from 'react';

import localforage from 'localforage';
import { useDispatch } from 'react-redux';

import { useGetMeetingContentQuery } from '../api/meetingContentApi';
import { ExampleMeetingData, MeetingContentItem } from '../api/types';
import { EXAMPLE_MEETINGS_KEY, EXAMPLE_MEETING_IDS } from '../constants';
import { setMeetingContent, setMeetingOutline } from '../features/meetingSlice';
import { useTypedSelector } from '../store';
import {
	SAMPLE_MEETINGS,
	SAMPLE_MEETING_CONTENT,
} from '../utils/sampleMeetingData';

type FormattedMeetingContentItem = MeetingContentItem & {
	paragraphs: string[];
};

export const useMeetingContent = (meetingId?: string) => {
	const dispatch = useDispatch();
	const isTeamView = window.location.pathname.includes('/team/');
	const { data, error, isLoading } = useGetMeetingContentQuery(
		{
			collection_id: meetingId ?? '',
			is_team_view: isTeamView,
		},
		{
			skip: !meetingId || EXAMPLE_MEETING_IDS.includes(meetingId),
		},
	);

	const meetingContent = useTypedSelector(
		(state) => state.meetingState.content,
	);
	const meetingOutline = useTypedSelector(
		(state) => state.meetingState.outline,
	);

	useEffect(() => {
		async function handleSampleMeeting() {
			const storedMeetingContent =
				(
					await localforage.getItem<ExampleMeetingData['fields']>(
						EXAMPLE_MEETINGS_KEY,
					)
				)?.meetingContent ?? SAMPLE_MEETING_CONTENT;
			dispatch(setMeetingContent(storedMeetingContent));
			const sampleOutline = storedMeetingContent.map((item) => ({
				id: item.id,
				title: item.title,
			}));
			dispatch(setMeetingOutline(sampleOutline));
		}
		// Handle sample meeting case
		if (meetingId && EXAMPLE_MEETING_IDS.includes(meetingId)) {
			handleSampleMeeting();
			return;
		}

		// Handle regular API data
		if (data) {
			dispatch(setMeetingContent(data.items));
			const meetingOutline = data.items.map((item) => ({
				id: item.id,
				title: item.title,
			}));
			dispatch(setMeetingOutline(meetingOutline));
		}
		if (error) {
			dispatch(setMeetingContent([]));
			dispatch(setMeetingOutline([]));
		}
	}, [dispatch, data, error, isLoading, meetingId]);

	const formattedMeetingContent: FormattedMeetingContentItem[] = useMemo(() => {
		if (!meetingContent) return [];

		return meetingContent.map((content: MeetingContentItem) => {
			let paragraphs = content.content.split('\n\n').filter(Boolean);
			if (paragraphs.length > 0 && paragraphs[0].includes('###')) {
				paragraphs = paragraphs.slice(1);
			}
			return {
				...content,
				paragraphs: paragraphs,
			};
		});
	}, [meetingContent]);

	return {
		meetingContent,
		formattedMeetingContent,
		meetingOutline,
		error,
		isLoading: isLoading && !EXAMPLE_MEETING_IDS.includes(meetingId ?? ''),
	};
};
