import { SplitFactory } from '@splitsoftware/splitio';

let client: SplitIO.IClient | null = null;

export function resetSplitClient() {
	client = null;
}

export const getSplitClient = async (userId?: string) => {
	if (client === null) {
		const splitApiKey =
			process.env.REACT_APP_SPLIT_API_KEY || process.env.SPLIT_API_KEY;
		if (!splitApiKey) {
			throw new Error('SPLIT_API_KEY is not set');
		}
		const factory: SplitIO.ISDK = SplitFactory({
			core: {
				authorizationKey: splitApiKey,
				key: userId as string,
			},
		});
		client = factory.client();
	}
	return client;
};

export const getSplitTreatment = async (
	userId: string,
	feature: string,
	attributes?: Record<string, string>,
) => {
	const client = await getSplitClient(userId);
	await client?.ready();
	return client?.getTreatment(feature, attributes);
};
