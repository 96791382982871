import { useCallback, useState } from 'react';

import { startStreaming } from '../api/streamingApi';
import { StreamPayload } from '../api/types';

export const useStreamingCompletion = () => {
	const [messages, setMessages] = useState<string>('');
	const [isStreaming, setIsStreaming] = useState(false);
	const [error, setError] = useState<string | null>(null);

	const startStreamingCompletion = useCallback(
		async (payload: StreamPayload, accessToken: string) => {
			setIsStreaming(true);
			setError(null);
			setMessages('');

			try {
				await startStreaming(payload, (chunk) => {
					setMessages((prevMessages) => prevMessages + chunk);
				});
			} catch (err) {
				setError(err instanceof Error ? err.message : 'An error occurred');
			} finally {
				setIsStreaming(false);
			}
		},
		[],
	);

	return {
		messages,
		isStreaming,
		error,
		startStreaming: startStreamingCompletion,
	};
};
