import styled, { keyframes } from 'styled-components';

import Card from '../Card';

const spin = keyframes`
	from { transform: rotate(0deg); }
	to { transform: rotate(360deg); }
`;
export const AgentSpinnerCard = styled(Card)`
	&.rt-Card.rt-variant-surface::after {
		box-shadow: none !important;
	}
	.rt-CardInner {
		padding: 0px;
	}
`;

export const Spinner = styled.div`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	position: relative;
	padding: 1rem;
	margin-bottom: 0.5rem;
	border-radius: 50%;
	&::before {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 1px solid transparent;
		border-radius: 50%;
		background:
			linear-gradient(var(--color-panel), var(--color-panel)) padding-box,
			linear-gradient(to right, var(--mb-blue-9), transparent) border-box;
		animation: ${spin} 1s linear infinite;
		content: '';
	}
`;
