import { CollectionTypeEnum } from '../constants/enums';
import { contentApi as api } from './base';
import { MeetingContentRequest, MeetingContentResponse } from './types';

const MEETING_CONTENT_ENDPOINT = 'content';

export const meetingContentApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getMeetingContent: builder.query<
			MeetingContentResponse,
			MeetingContentRequest
		>({
			query: ({ collection_id, is_team_view }) => ({
				url: `${MEETING_CONTENT_ENDPOINT}`,
				method: 'GET',
				params: {
					collection_id,
					collection_type: CollectionTypeEnum.MEETING,
					is_team_view,
				},
			}),
		}),
	}),
});

export const { useGetMeetingContentQuery } = meetingContentApi;
