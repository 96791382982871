import { useCallback, useEffect, useState } from 'react';

import { useListMeetingsBidirectionalQuery } from '../api/meetingsApi';
import { Meeting } from '../api/types';
import {
	MeetingCategoryType,
	MeetingDepartmentType,
	MeetingInternalExternal,
} from '../commons/types';

interface UseMeetingsListBidirectionalProps {
	initialCursor?: string;
	perPage?: number;
	people?: string[];
	meetingCategory?: MeetingCategoryType[];
	meetingDepartment?: MeetingDepartmentType[];
	meetingInternalExternal?: MeetingInternalExternal;
	dateRange?: {
		from?: Date;
		to?: Date;
	};
	searchQuery?: string;
	team_members?: string[];
}

interface MeetingsListBidirectionalResult {
	meetings: Meeting[];
	data: Meeting[];
	isLoading: boolean;
	error: any;
	hasNextPage: boolean;
	hasPreviousPage: boolean;
	fetchNextPage: () => Promise<void>;
	fetchPreviousPage: () => Promise<void>;
	refetch: () => Promise<void>;
}

export const useMeetingsListBidirectional = ({
	initialCursor,
	perPage = 50,
	people = [],
	meetingCategory = [],
	meetingDepartment = [],
	meetingInternalExternal,
	dateRange,
	searchQuery,
	team_members = [],
}: UseMeetingsListBidirectionalProps): MeetingsListBidirectionalResult => {
	const defaultCursor = initialCursor || new Date().toISOString().split('T')[0];
	const [cursor, setCursor] = useState(defaultCursor);
	const [direction, setDirection] = useState<"next" | "previous" | undefined>(undefined);

	const isTeamView = window.location.pathname.includes('/team/');

	const { data, isLoading, error, refetch, isFetching } = useListMeetingsBidirectionalQuery(
		{
			cursor,
			per_page: perPage,
			direction,
			people,
			meeting_category: meetingCategory,
			meeting_department: meetingDepartment,
			meeting_internal_external: meetingInternalExternal,
			date_range: dateRange
				? {
					from: dateRange.from?.toISOString() || '',
					to: dateRange.to?.toISOString() || '',
				}
				: undefined,
			search_query: searchQuery,
			team_members,
			is_team_view: isTeamView,
		},
	);

	useEffect(() => {
		setCursor(defaultCursor);
		setDirection(undefined);
	}, [
		people,
		meetingCategory,
		meetingDepartment,
		meetingInternalExternal,
		dateRange?.from,
		dateRange?.to,
		searchQuery,
		team_members,
		isTeamView,
	]);

	const fetchNextPage = useCallback(async () => {
		if (isLoading || !data?.meetings.length) return;

		const nextCursor = data.meetings[0]?.start_time;
		if (!nextCursor) return;

		setDirection('next');
		setCursor(nextCursor);

		await new Promise((resolve) => setTimeout(resolve, 0));
		await refetch();
	}, [data?.meetings, isLoading, refetch]);

	const fetchPreviousPage = useCallback(async () => {
		if (isLoading || !data?.meetings.length) return;

		const previousCursor = data.meetings[data.meetings.length - 1]?.start_time;
		if (!previousCursor) return;

		setDirection('previous');
		setCursor(previousCursor);

		await new Promise((resolve) => setTimeout(resolve, 0));
		await refetch();
	}, [data?.meetings, isLoading, refetch]);

	const wrappedRefetch = useCallback(async () => {
		setDirection(undefined);
		setCursor(defaultCursor);
		await new Promise((resolve) => setTimeout(resolve, 0));
		await refetch();
	}, [refetch]);

	return {
		data: data?.meetings || [],
		meetings: data?.meetings || [],
		isLoading: isLoading || isFetching,
		error,
		hasNextPage: !!data?.has_next,
		hasPreviousPage: !!data?.has_previous,
		fetchNextPage,
		fetchPreviousPage,
		refetch: wrappedRefetch,
	};
};
