import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import localforage from 'localforage';

import {
	AUTOPILOTS_TOGGLED_KEY,
	CHAIN_OF_THOUGHT_TOGGLED_KEY,
	JOURNALS_TOGGLED_KEY,
	MAX_ACTIVE_CHATS_KEY,
} from '../constants';

export interface AdminState {
	chainOfThoughtToggled: boolean;
	journalsToggled: boolean;
	autopilotsToggled: boolean;
	maxActiveChatsToggled: boolean;
}

const initialState: AdminState = {
	chainOfThoughtToggled: false,
	journalsToggled: false,
	autopilotsToggled: false,
	maxActiveChatsToggled: true,
};

export const adminSlice = createSlice({
	initialState,
	name: 'adminSlice',
	reducers: {
		toggleChainOfThought: (
			state,
			action: PayloadAction<boolean | undefined>,
		) => {
			if (action.payload !== undefined) {
				state.chainOfThoughtToggled = action.payload;
			} else {
				state.chainOfThoughtToggled = !state.chainOfThoughtToggled;
			}
			localforage.setItem(
				CHAIN_OF_THOUGHT_TOGGLED_KEY,
				state.chainOfThoughtToggled,
			);
		},
		toggleJournals: (state, action: PayloadAction<boolean | undefined>) => {
			if (action.payload !== undefined) {
				state.journalsToggled = action.payload;
			} else {
				state.journalsToggled = !state.journalsToggled;
			}
			localforage.setItem(JOURNALS_TOGGLED_KEY, state.journalsToggled);
		},
		toggleAutopilots: (state, action: PayloadAction<boolean | undefined>) => {
			if (action.payload !== undefined) {
				state.autopilotsToggled = action.payload;
			} else {
				state.autopilotsToggled = !state.autopilotsToggled;
			}
			localforage.setItem(AUTOPILOTS_TOGGLED_KEY, state.autopilotsToggled);
		},
		toggleMaxActiveChats: (
			state,
			action: PayloadAction<boolean | undefined>,
		) => {
			if (action.payload !== undefined) {
				state.maxActiveChatsToggled = action.payload;
			} else {
				state.maxActiveChatsToggled = !state.maxActiveChatsToggled;
			}
			localforage.setItem(MAX_ACTIVE_CHATS_KEY, state.maxActiveChatsToggled);
		},
		setInitialState: (state, action: PayloadAction<AdminState>) => {
			state.chainOfThoughtToggled = action.payload.chainOfThoughtToggled;
			state.journalsToggled = action.payload.journalsToggled;
			state.autopilotsToggled = action.payload.autopilotsToggled;
			state.maxActiveChatsToggled = action.payload.maxActiveChatsToggled;
		},
	},
});

export default adminSlice.reducer;

export const {
	toggleChainOfThought,
	toggleJournals,
	toggleAutopilots,
	toggleMaxActiveChats,
	setInitialState,
} = adminSlice.actions;
