import { useCallback, useState } from 'react';

import { useSearchPastSearchesQuery } from '../api/searchApi';

export const useSearchPastSearches = () => {
	const [query, setQuery] = useState('');
	const [limit, setLimit] = useState(10);

	const {
		data: pastSearches,
		isLoading,
		error,
	} = useSearchPastSearchesQuery({ query, limit }, { skip: !query });

	const searchPastSearches = useCallback(
		(newQuery: string, newLimit?: number) => {
			setQuery(newQuery);
			if (newLimit) setLimit(newLimit);
		},
		[],
	);

	return {
		pastSearches,
		isLoading,
		error,
		searchPastSearches,
		setLimit,
	};
};
