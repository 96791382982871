import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface ChatsState {
	agentType: string;
	channelId?: string;
	channelAgentType?: string;
	connectedChannels: string[];
}

const initialState: ChatsState = {
	agentType: 'OPENAI_STANDARD_AGENT',
	channelId: undefined,
	channelAgentType: undefined,
	connectedChannels: [],
};

export const channelSlice = createSlice({
	initialState,
	name: 'channelSlice',
	reducers: {
		setAgentType: (state, action: PayloadAction<string>) => {
			state.agentType = action.payload;
		},
		setChannelId: (state, action: PayloadAction<string>) => {
			state.channelId = action.payload;
		},
		setChannelAgentType: (state, action: PayloadAction<string>) => {
			state.channelAgentType = action.payload;
		},
		setConnectedChannels: (state, action: PayloadAction<string[]>) => {
			state.connectedChannels = action.payload;
		},
		addConnectedChannel: (state, action: PayloadAction<string>) => {
			if (!state.connectedChannels.includes(action.payload)) {
				state.connectedChannels.push(action.payload);
			}
		},
		removeConnectedChannel: (state, action: PayloadAction<string>) => {
			state.connectedChannels = state.connectedChannels.filter(
				(channelId) => channelId !== action.payload,
			);
		},
	},
});

export default channelSlice.reducer;

export const {
	setAgentType,
	setChannelId,
	setChannelAgentType,
	setConnectedChannels,
	addConnectedChannel,
	removeConnectedChannel,
} = channelSlice.actions;
