import { OnboardingStepStatus } from '../api/types';
import {
	useGetTourStatusQuery,
	useUpdateTourStatusMutation,
} from '../api/userApi';

export function useTour() {
	const { data: tourStatus, isLoading } = useGetTourStatusQuery();
	const [updateTourStatus] = useUpdateTourStatusMutation();

	const completeTour = async () => {
		await updateTourStatus({
			status: OnboardingStepStatus.COMPLETED,
			remind_later: false,
		});
	};

	const remindLater = async () => {
		await updateTourStatus({
			status: OnboardingStepStatus.NOT_STARTED,
			remind_later: true,
		});
	};

	const startTour = async () => {
		await updateTourStatus({
			status: OnboardingStepStatus.IN_PROGRESS,
			remind_later: false,
		});
	};

	return {
		tourStatus,
		isLoading,
		completeTour,
		remindLater,
		startTour,
		shouldShowTour:
			tourStatus?.status !== OnboardingStepStatus.COMPLETED &&
			!tourStatus?.remind_later,
	};
}
