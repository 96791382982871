import { agentApi } from './base';
import { BOHTaskLogsResponse } from './types';

export const bohApi = agentApi.injectEndpoints({
	endpoints: (builder) => ({
		getBOHTaskLogs: builder.query<BOHTaskLogsResponse, string>({
			query: (completionLogId) => ({
				url: `/boh/${completionLogId}`,
				method: 'GET',
			}),
			keepUnusedDataFor: 0,
		}),
	}),
});

export const { useGetBOHTaskLogsQuery } = bohApi;
