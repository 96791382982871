import { UUID } from 'crypto';

import {
	useCreateTemplateMutation,
	useDeleteTemplateMutation,
	useListTemplatesQuery,
	useUpdateTemplateMutation,
} from '../api/templateApi';
import { Template } from '../api/types';

export const useTemplates = () => {
	const { data, error, isLoading } = useListTemplatesQuery();
	const [createTemplate] = useCreateTemplateMutation();
	const [updateTemplate] = useUpdateTemplateMutation();
	const [deleteTemplate] = useDeleteTemplateMutation();

	return {
		templates: data?.templates || [],
		isLoading,
		error,
		createTemplate: async (template: Partial<Template>) => {
			try {
				await createTemplate(template).unwrap();
				return { success: true };
			} catch (err) {
				return {
					success: false,
					error: err,
				};
			}
		},
		updateTemplate: async (templateId: UUID, template: Partial<Template>) => {
			try {
				await updateTemplate({ templateId, body: template }).unwrap();
				return { success: true };
			} catch (err) {
				return {
					success: false,
					error: err,
				};
			}
		},
		deleteTemplate: async (templateId: UUID) => {
			try {
				await deleteTemplate(templateId).unwrap();
				return { success: true };
			} catch (err) {
				return {
					success: false,
					error: err,
				};
			}
		},
	};
};
