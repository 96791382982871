import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { MeetingContentItem } from '../api/types';
import { MeetingOutlineItem } from './types';

export interface MeetingState {
	content: MeetingContentItem[] | null;
	outline: MeetingOutlineItem[] | null;
	activeMeetingContentItem: string | null;
}

const initialState: MeetingState = {
	content: null,
	outline: null,
	activeMeetingContentItem: null,
};

const meetingSlice = createSlice({
	name: 'meeting',
	initialState,
	reducers: {
		setMeetingContent: (state, action: PayloadAction<MeetingContentItem[]>) => {
			state.content = action.payload;
		},
		setMeetingOutline: (state, action: PayloadAction<MeetingOutlineItem[]>) => {
			state.outline = action.payload;
		},
		setActiveMeetingContentItem: (state, action: PayloadAction<string>) => {
			state.activeMeetingContentItem = action.payload;
		},
	},
});

export const {
	setMeetingContent,
	setMeetingOutline,
	setActiveMeetingContentItem,
} = meetingSlice.actions;

export default meetingSlice.reducer;
