import { ConfigureStoreOptions, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import * as Sentry from '@sentry/react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { agentApi, api, contentApi, meetingApi } from './api/base';
import adminReducer from './features/adminSlice';
import authReducer from './features/authSlice';
import autopilotsReducer from './features/autopilotsSlice';
import channelReducer from './features/channelSlice';
import chatsReducer from './features/chatsSlice';
import completionsReducer from './features/completionsSlice';
import enterpriseReducer from './features/enterpriseSlice';
import meetingReducer from './features/meetingSlice';
import meetingsReducer from './features/meetingsSlice';
import searchSelectionReducer from './features/searchSelectionSlice';
import streamingReducer from './features/streamingSlice';
import tenantConfigReducer from './features/tenantConfigSlice';
import tenantsReducer from './features/tenantsSlice';
import userPreferencesReducer from './features/userPreferencesSlice';

// const appReducer = combineReducers({
// 	[api.reducerPath]: api.reducer,
// 	[contentApi.reducerPath]: contentApi.reducer,
// 	[agentApi.reducerPath]: agentApi.reducer,
// 	channelState: channelReducer,
// 	adminState: adminReducer,
// 	tenantConfigState: tenantConfigReducer,
// 	tenantsState: tenantsReducer,
// 	authState: authReducer,
// 	chatsState: chatsReducer,
// 	autopilotsState: autopilotsReducer,
// 	enterpriseState: enterpriseReducer,
// 	meetingsState: meetingsReducer,
// 	meetingState: meetingReducer,
// });

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

// const rootReducer = (
// 	state: ReturnType<typeof appReducer> | undefined,
// 	action: Action,
// ) => {
// 	if (action.type === 'LOGOUT') {
// 		return appReducer(undefined, action);
// 	}

// 	return appReducer(state, action);
// };

export const createStore = (
	options?: ConfigureStoreOptions['preloadedState'] | undefined,
) =>
	configureStore({
		reducer: {
			[api.reducerPath]: api.reducer,
			[contentApi.reducerPath]: contentApi.reducer,
			[agentApi.reducerPath]: agentApi.reducer,
			[meetingApi.reducerPath]: meetingApi.reducer,
			channelState: channelReducer,
			adminState: adminReducer,
			tenantConfigState: tenantConfigReducer,
			tenantsState: tenantsReducer,
			authState: authReducer,
			chatsState: chatsReducer,
			autopilotsState: autopilotsReducer,
			enterpriseState: enterpriseReducer,
			userPreferencesState: userPreferencesReducer,
			meetingsState: meetingsReducer,
			meetingState: meetingReducer,
			completionsState: completionsReducer,
			searchSelectionState: searchSelectionReducer,
			streamingState: streamingReducer,
		},
		devTools: process.env.NODE_ENV === 'development',
		enhancers: [sentryReduxEnhancer],
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware().concat(
				api.middleware,
				contentApi.middleware,
				agentApi.middleware,
				meetingApi.middleware,
			),
		...options,
	});

export const store = createStore();
setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
