import React, { PropsWithChildren } from 'react';

import styled from 'styled-components';

const StyledText = styled.span`
	position: absolute;
	width: 1px;
	height: 1px;
	clip: rect(0, 0, 0, 0);
	overflow: hidden;
	margin: -1px;
	padding: 0;
	border-width: 0;
	white-space: nowrap;
`;

interface ScreenReaderTextProps extends PropsWithChildren {
	as?: string;
	className?: string;
}

const ScreenReaderText = ({
	as = 'span',
	className,
	children,
}: ScreenReaderTextProps) => {
	return (
		<StyledText as={as} className={className}>
			{children}
		</StyledText>
	);
};

export default ScreenReaderText;
