import { CollectionType } from '../commons/types';
import { contentApi as api } from './base';
import { CompletionHistoryItem } from './types';

export const completionsApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getCompletionsHistory: builder.query<
			CompletionHistoryItem[],
			{
				collectionId: string;
				collectionType?: CollectionType;
				isTeamView?: boolean;
			}
		>({
			query: ({ collectionId, collectionType, isTeamView }) => ({
				url: `completions/${collectionId}/history`,
				method: 'GET',
				params: {
					collection_type: collectionType ?? '',
					is_team_view: isTeamView,
				},
			}),
		}),
	}),
	overrideExisting: false,
});

export const { useGetCompletionsHistoryQuery } = completionsApi;
