import { Socket, io } from 'socket.io-client';

import { RefreshTokenResponse } from '../api/types';
import { TokenUtil } from './token';

let socket: Socket | null;

let retryLimit = 5;

export function resetSocket() {
	socket = null;
}

export type SocketData = {
	accessToken: string;
	app_url: string;
	ws_url: string;
	api_url: string;
};

// TODO: move sockets to RTK setup?

const retrySocketInitialization = async (
	ws_url: string,
	app_url: string,
	api_url: string,
) => {
	while (retryLimit > 0) {
		try {
			const refreshToken = await TokenUtil.getRefreshToken(app_url);
			const refreshResponse = refreshToken
				? await fetch(`${api_url}refresh-token`, {
						method: 'GET',
						headers: {
							Authorization: `Bearer ${refreshToken}`,
						},
					})
				: null;
			if (refreshResponse) {
				const data: RefreshTokenResponse = await refreshResponse.json();
				TokenUtil.setAccessToken(data?.data?.access_token, app_url);
				socket = io(ws_url, {
					withCredentials: true,
					auth: {
						token: data.data.access_token,
					},
					reconnectionAttempts: 1000,
				});
				socket.connect();
			}
			retryLimit = 5;
			return;
		} catch (error) {
			console.debug('Error refreshing token', error);
			retryLimit--;
		}
	}
};

export function getSocket(socketData: SocketData) {
	if (socket && socket.connected) return socket;

	const { accessToken, app_url, ws_url, api_url } = socketData;

	if (ws_url && !socket && accessToken) {
		socket = io(ws_url as string, {
			withCredentials: true,
			auth: {
				token: accessToken,
			},
			reconnectionAttempts: 1000,
		});

		socket.on('connect', () => {
			console.debug('Socket.On.Connect');
		});

		socket.on('connect_error', () => {
			// TODO: handle auth error here
			// look up refresh token in cookies
			retrySocketInitialization(
				ws_url as string,
				app_url as string,
				api_url as string,
			);
			console.debug('Socket.On.ConnectError');
		});

		socket.on('message', (message) => {
			console.debug('Socket.On.Message', message);
		});

		socket.on('disconnect', (reason) => {
			console.debug('Socket.On.Disconnect', reason);
		});

		socket.io.on('error', (error) => {
			// TODO: handle auth error here
			// look up refresh token in store.getState()
			retrySocketInitialization(
				ws_url as string,
				app_url as string,
				api_url as string,
			);
			console.debug('Socket.On.Error', error);
		});

		socket.io.on('reconnect', () => {
			console.debug('Socket.On.Reconnect');
		});

		socket.io.on('reconnect_attempt', (attempt) => {
			console.debug('Socket.On.ReconnectAttempt', attempt);
		});

		socket.io.on('reconnect_error', (error) => {
			console.debug('Socket.On.ReconnectError', error);
		});

		socket.io.on('reconnect_failed', () => {
			console.debug('Socket.On.ReconnectFailed');
		});

		// Raise error if socket not initialized
		if (!socket) {
			console.error(
				`Socket not initialized: ${JSON.stringify({
					uri: ws_url,
					hasToken: !!accessToken,
				})}`,
			);
		}
		return socket;
	}
}
