import { useCallback, useEffect, useState } from 'react';

const useMediaQuery = (query: string): boolean => {
	// Use a callback to create the media query
	const getMatches = useCallback((query: string): boolean => {
		// Check if window is available (for SSR compatibility)
		if (typeof window !== 'undefined') {
			return window.matchMedia(query).matches;
		}
		return false;
	}, []);

	const [matches, setMatches] = useState<boolean>(getMatches(query));

	useEffect(() => {
		const handleChange = () => setMatches(getMatches(query));

		// Check if window is available (for SSR compatibility)
		if (typeof window !== 'undefined') {
			const media = window.matchMedia(query);

			// Set the initial value
			handleChange();

			// Use the modern `addEventListener` instead of deprecated `addListener`
			media.addEventListener('change', handleChange);

			// Cleanup
			return () => media.removeEventListener('change', handleChange);
		}
	}, [query, getMatches]);

	return matches;
};

export default useMediaQuery;
