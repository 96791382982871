import { TokenUtil } from '../services/token';
import { StreamPayload } from './types';

export const startStreaming = async (
	payload: StreamPayload,
	onChunk: (chunk: string) => void,
	accessToken?: string,
): Promise<void> => {
	if (!accessToken) {
		accessToken = await TokenUtil.getAccessToken();
	}
	const response = await fetch(
		`${process.env.REACT_APP_CONTENT_SERVICE_API_URL}/api/completions/stream`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'text/event-stream',
				Authorization: `Bearer ${accessToken}`,
				'Cache-Control': 'no-cache',
				Pragma: 'no-cache',
			},
			body: JSON.stringify(payload),
		},
	);

	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}

	const reader = response.body?.getReader();
	if (!reader) {
		throw new Error('ReadableStream not supported');
	}

	const decoder = new TextDecoder();

	while (true) {
		const { done, value } = await reader.read();
		if (done) break;

		const chunk = decoder.decode(value, { stream: true });
		onChunk(chunk);
	}
};
