import { useState } from 'react';

import {
	useCreateSnippetMutation,
	useDeleteSnippetMutation,
	useGetSnippetStatusQuery,
	useListSnippetsQuery,
	useModifySnippetMutation,
} from '../api/snippetsApi';
import {
	CreateSnippetRequest,
	ModifySnippetRequest,
	Snippet,
} from '../api/types';

interface UseSnippetsOptions {
	pollInterval?: number;
	sourceCollectionId?: string;
}

export function useSnippets(options: UseSnippetsOptions = {}) {
	const { pollInterval = 10000, sourceCollectionId } = options;
	const [activeSnippetId, setActiveSnippetId] = useState<string | null>(null);

	const [createSnippet, { isLoading: isCreating }] = useCreateSnippetMutation();
	const [modifySnippet, { isLoading: isModifying }] =
		useModifySnippetMutation();
	const [deleteSnippet, { isLoading: isDeleting }] = useDeleteSnippetMutation();

	const {
		data: snippets,
		isLoading: isLoadingList,
		refetch: refetchSnippets,
	} = useListSnippetsQuery(
		{ source_collection_id: sourceCollectionId ?? '' },
		{ skip: !sourceCollectionId },
	);

	const { data: activeSnippet, isLoading: isLoadingStatus } =
		useGetSnippetStatusQuery(activeSnippetId ?? '', {
			skip: !activeSnippetId,
			pollingInterval: activeSnippetId ? pollInterval : 0,
		});

	const handleCreateSnippet = async (request: CreateSnippetRequest) => {
		try {
			const response = await createSnippet(request).unwrap();
			setActiveSnippetId(response.id);
			return response;
		} catch (error) {
			console.error('Failed to create snippet:', error);
			throw error;
		}
	};

	const handleModifySnippet = async (
		id: string,
		request: ModifySnippetRequest,
	) => {
		try {
			const response = await modifySnippet({ id, body: request }).unwrap();
			return response;
		} catch (error) {
			console.error('Failed to modify snippet:', error);
			throw error;
		}
	};

	const handleDeleteSnippet = async (id: string) => {
		try {
			const response = await deleteSnippet(id).unwrap();
			if (activeSnippetId === id) {
				setActiveSnippetId(null);
			}
			return response;
		} catch (error) {
			console.error('Failed to delete snippet:', error);
			throw error;
		}
	};

	const isPending = activeSnippet?.status === 'PENDING';
	const isProcessing = isCreating || isPending;

	const getSnippet = (id: string) => {
		setActiveSnippetId(id);
	};

	return {
		createSnippet: handleCreateSnippet,
		modifySnippet: handleModifySnippet,
		deleteSnippet: handleDeleteSnippet,
		setActiveSnippetId,
		snippets,
		activeSnippet,
		activeSnippetId,
		isCreating,
		isModifying,
		isDeleting,
		isLoadingList,
		isLoadingStatus,
		isProcessing,
		isPending,
		getSnippet,
		refetchSnippets,
	};
}
