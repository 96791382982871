import { useGetAutopilotByIdQuery } from '../api/autopilotsApi';
import { useTypedSelector } from '../store';

export const useAutopilotByQuery = (
	autopilotId: string,
	enterpriseId?: string,
	includeUsers?: boolean,
) => {
	const autopilot = useTypedSelector(
		(state) => state.autopilotsState.autopilot,
	);
	const { data, error, isLoading, isFetching } = useGetAutopilotByIdQuery({
		autopilotId,
		enterpriseId,
		includeUsers,
	});

	return {
		autopilot,
		error,
		isLoading,
		isFetching,
		getAutopilot: () => {
			return autopilot || data;
		},
	};
};
