// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mb-extension .radix-themes {
	--default-font-size: 13px;
	--default-line-height: 18px;
}
`, "",{"version":3,"sources":["webpack://./../web-ui/src/components/Provider/styles-extension.css"],"names":[],"mappings":"AAAA;CACC,yBAAyB;CACzB,2BAA2B;AAC5B","sourcesContent":[".mb-extension .radix-themes {\n\t--default-font-size: 13px;\n\t--default-line-height: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
