import { api } from './base';
import {
    Invoice,
    PaymentTransaction,
    Plan,
    SubscribeRequest,
    Subscription,
    SubscriptionResponse,
    SubscriptionStatusResponse
} from './types';

export const billingApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getPlan: builder.query<Plan, void>({
			query: () => ({
				url: '/billing/plans',
				method: 'GET',
			}),
			transformResponse: (response: { plans: Plan[] }) => response.plans[0],
		}),

		getSubscription: builder.query<Subscription, void>({
			query: () => ({
				url: '/billing/subscription',
				method: 'GET',
			}),
			transformResponse: (response: SubscriptionResponse) =>
				response.subscription,
		}),

		getSubscriptionStatus: builder.query<SubscriptionStatusResponse, void>({
			query: () => ({
				url: '/billing/subscription/status',
				method: 'GET',
			}),
		}),

		subscribe: builder.mutation<Subscription, SubscribeRequest>({
			query: (body) => ({
				url: '/billing/subscribe',
				method: 'POST',
				body,
			}),
		}),

		setupPayment: builder.mutation<{ client_secret: string }, void>({
			query: () => ({
				url: '/billing/setup-payment',
				method: 'POST',
			}),
		}),

		getInvoices: builder.query<Invoice[], void>({
			query: () => ({
				url: '/billing/invoices',
				method: 'GET',
			}),
			transformResponse: (response: { invoices: Invoice[] }) =>
				response.invoices,
		}),

		cancelSubscription: builder.mutation<void, void>({
			query: () => ({
				url: '/billing/subscription/cancel',
				method: 'POST',
			}),
		}),

		findPaymentTransaction: builder.query<PaymentTransaction, void>({
			query: () => ({
				url: `/billing/payment`,
				method: 'GET',
			}),
		}),
	}),
});

export const {
	useGetPlanQuery,
	useGetSubscriptionQuery,
	useGetSubscriptionStatusQuery,
	useSubscribeMutation,
	useSetupPaymentMutation,
	useGetInvoicesQuery,
	useCancelSubscriptionMutation,
	useFindPaymentTransactionQuery,
} = billingApi;
