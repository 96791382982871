import { AUTOPILOTS_BASE_URL } from '../constants';
import { api } from './base';
import { UserRecentEvent, UserRecentEventsRequest } from './types';

export const userRecentEventsApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getRecentEvents: builder.query<UserRecentEvent, UserRecentEventsRequest>({
			query: ({ event_name, page, page_size }) => {
				const params: Record<string, any> = {};
				if (event_name) params.event_name = event_name;
				if (page) params.page = page;
				if (page_size) params.page_size = page_size;
				return {
					method: 'GET',
					url: `${AUTOPILOTS_BASE_URL}/events`,
					params,
				};
			},
		}),
	}),
});

export const { useGetRecentEventsQuery } = userRecentEventsApi;
