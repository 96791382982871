import { useEffect, useMemo, useState } from 'react';

import { useGetBOHTaskLogsQuery } from '../api/bohApi';
import { BOHTaskLog } from '../api/types';

interface UseBOHTaskLogsOptions {
	completionLogId: string;
	pollingInterval?: number;
	enabled?: boolean;
}

export const useBOHTaskLogs = ({
	completionLogId,
	pollingInterval = 3000,
	enabled = true,
}: UseBOHTaskLogsOptions) => {
	const [logs, setLogs] = useState<BOHTaskLog[]>([]);
	const [progress, setProgress] = useState<number>(0);

	const stopPolling = useMemo(() => {
		return logs.some((log) => ['REPLY', 'ERROR'].includes(log.message_type));
	}, [logs]);

	const { data, error, isLoading, refetch } = useGetBOHTaskLogsQuery(
		completionLogId,
		{
			pollingInterval: stopPolling ? 0 : enabled ? pollingInterval : 0,
			skip: !completionLogId,
			refetchOnMountOrArgChange: true,
		},
	);

	useEffect(() => {
		if (data?.logs) {
			setLogs(data.logs);
			setProgress(data.progress ?? 0);
		}
	}, [data]);

	useEffect(() => {
		if (enabled && completionLogId) {
			refetch();
		}
	}, [enabled, completionLogId, refetch]);

	return {
		logs,
		progress,
		isLoading,
		error,
		stopPolling,
		refetch,
	};
};
