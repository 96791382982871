import React, { useEffect, useState } from 'react';

import { getSplitTreatment } from '../services/split';
import { useMe } from './useMe';

// Generic custom hook for feature flags
export const useFeatureFlag = (
	featureKey: string,
	defaultValue = null,
	attributes?: Record<string, string>,
) => {
	const { data: user } = useMe();
	const [isEnabled, setIsEnabled] = useState<boolean | null>(defaultValue);

	useEffect(() => {
		if (user?.id) {
			getSplitTreatment(user.id, featureKey, {
				email: user.email,
				...attributes,
			}).then((treatment) => {
				setIsEnabled(treatment === 'on');
			});
		}
	}, [user?.id, featureKey]);

	return isEnabled;
};
