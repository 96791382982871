import { useState } from 'react';

import {
	useAddTeamMemberMutation,
	useCancelInviteMutation,
	useGetTeamMembersQuery,
	useRemoveTeamMemberMutation,
	useResendInviteMutation,
	useUpdateMemberRoleMutation,
} from '../api/teamsApi';

export function useTeams() {
	const [searchQuery, setSearchQuery] = useState('');
	const { data: teamData, isLoading, refetch } = useGetTeamMembersQuery();
	const [addMember] = useAddTeamMemberMutation();
	const [removeMember] = useRemoveTeamMemberMutation();
	const [resendInvite] = useResendInviteMutation();
	const [cancelInvite] = useCancelInviteMutation();
	const [updateMemberRole] = useUpdateMemberRoleMutation();

	const filteredMembers = teamData?.members.filter(
		(member) =>
			member.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
			member.name.toLowerCase().includes(searchQuery.toLowerCase()),
	);

	const filteredInvites = teamData?.invites.filter((invite) =>
		invite.email.toLowerCase().includes(searchQuery.toLowerCase()),
	);

	return {
		members: filteredMembers || [],
		invites: filteredInvites || [],
		isLoading,
		searchQuery,
		setSearchQuery,
		refetch,
		addMember: async (emails: string[]) => {
			try {
				await addMember({ emails });
				return true;
			} catch (error) {
				console.error('Failed to add team member:', error);
				return false;
			}
		},
		removeMember: async (email: string) => {
			try {
				await removeMember(email);
				return true;
			} catch (error) {
				console.error('Failed to remove team member:', error);
				return false;
			}
		},
		resendInvite: async (inviteId: string) => {
			try {
				await resendInvite(inviteId);
				return true;
			} catch (error) {
				console.error('Failed to resend invite:', error);
				return false;
			}
		},
		cancelInvite: async (inviteId: string) => {
			try {
				await cancelInvite(inviteId);
				return true;
			} catch (error) {
				console.error('Failed to cancel invite:', error);
				return false;
			}
		},
		updateMemberRole: async (email: string, role: string) => {
			try {
				await updateMemberRole({ email, role });
				return true;
			} catch (error) {
				console.error('Failed to update member role:', error);
				return false;
			}
		},
		isTeamAdmin: teamData?.isTeamAdmin || false,
		totalSeats: teamData?.totalSeats || 1,
	};
}
