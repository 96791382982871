import { TimestampFilterValue, UserPreferencesResponse } from '../api/types';
import {
	LANGSMITH_KEY,
	PORTKEY_KEY,
	POSTHOG_KEY,
	PUBLIC_PATHS,
} from '../constants';
import { Tenant } from '../features/types';
import { UserPreferencesState } from '../features/userPreferencesSlice';
import { RootState } from '../store';

/**
 * Retrieves socket connection data from the Redux store
 * @param state - The Redux root state
 * @returns Object containing access token and URLs needed for socket connection
 */
export const getSocketData = (state: RootState) => {
	return {
		accessToken: state.authState?.accessToken ?? '',
		app_url: state.tenantConfigState?.app_url ?? '',
		ws_url: state.tenantConfigState?.ws_url ?? '',
		api_url: state.tenantConfigState?.api_url ?? '',
	};
};

/**
 * Retrieves a cookie value by name from the document cookies
 * @param name - The name of the cookie to retrieve
 * @returns The cookie value if found, undefined otherwise
 */
export function getCookie(name: string) {
	const value = '; ' + document.cookie;
	const parts = value.split('; ' + name + '=');
	if (parts.length == 2) return parts.pop()?.split(';').shift();
}

/**
 * Sets a cookie with the specified name, value, and expiration
 * @param name - The name of the cookie
 * @param value - The value to store in the cookie
 * @param days - Number of days until the cookie expires
 */
export function setCookie(name: string, value: string, days: number) {
	const date = new Date();
	date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
	const expires = 'expires=' + date.toUTCString();
	document.cookie =
		name + '=' + value + ';' + expires + ';path=/;SameSite=Strict;Secure';
}

/**
 * Removes a cookie by setting its expiration to a past date
 * @param name - The name of the cookie to remove
 */
export function removeCookie(name: string) {
	document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

/**
 * Formats user preferences from API response into the application state format
 * @param preferences - User preferences response from the API
 * @returns Formatted user preferences state
 */
export const formatUserPreferences: (
	preferences: UserPreferencesResponse,
) => UserPreferencesState = (preferences: UserPreferencesResponse) => {
	let {
		data_consent: dataConsent,
		meeting_presence: meetingPresence,
		notification: notificationPreferences,
	} = preferences;
	if (
		!dataConsent ||
		(typeof dataConsent === 'object' && Object.keys(dataConsent).length === 0)
	)
		dataConsent = [];
	return {
		userAnalyticsToggled:
			dataConsent.find((consent) => consent.app_id === POSTHOG_KEY)?.consent ??
			true,
		qualityPerformanceToggled:
			(dataConsent.find((consent) => consent.app_id === LANGSMITH_KEY)
				?.consent ??
				true) &&
			(dataConsent.find((consent) => consent.app_id === PORTKEY_KEY)?.consent ??
				true),
		excludedParticipantDomains:
			meetingPresence?.excluded_participant_domains ?? [],
		webNotificationsToggled: notificationPreferences?.web_notifications ?? true,
		internalMeetingBotToggled:
			meetingPresence?.internal_meeting_bot_enabled ?? false,
		externalMeetingBotToggled:
			meetingPresence?.external_meeting_bot_enabled ?? true,
		googleDriveFolderUrl: meetingPresence?.google_drive_folder_url ?? '',
		pushNotificationsToggled:
			notificationPreferences?.push_notifications ?? true,
		emailNotificationsToggled:
			notificationPreferences?.email_notifications ?? false,
		smsNotificationsToggled:
			notificationPreferences?.sms_notifications ?? false,
		androidNotificationsToggled:
			notificationPreferences?.android_notifications ?? true,
		iosNotificationsToggled: notificationPreferences?.ios_notifications ?? true,
		postMeetingSummaryToggled:
			notificationPreferences?.post_meeting_summary ?? false,
		preMeetingResearchToggled:
			notificationPreferences?.pre_meeting_research ?? false,
		salesforceAddContactsToggled:
			notificationPreferences?.salesforce_add_contacts ?? false,
		summarizeEmailsToggled: notificationPreferences?.summarize_emails ?? false,
		frequency: notificationPreferences?.frequency ?? 0,
		startTime: notificationPreferences?.start_time ?? 800,
		endTime: notificationPreferences?.end_time ?? 1800,
		days:
			typeof notificationPreferences?.days === 'string'
				? notificationPreferences?.days
				: undefined,
		slackNotificationsToggled:
			notificationPreferences?.slack_notifications ?? true,
	};
};

/**
 * Creates a local tenant configuration for development purposes
 * @returns Array containing a single local tenant configuration
 */
export const fetchLocalTenant = (): Tenant[] => {
	return [
		{
			config: {
				client_id:
					process.env.GOOGLE_OAUTH2_CLIENT_ID_WEB ||
					'953248554833-soc50f1olk5fdkkcpi69kbmn915akkbp.apps.googleusercontent.com',
				redirect_url: process.env.MIGHTYBOT_REDIRECT_URI || 'local',
				api_url: process.env.MIGHTYBOT_API_URI || 'http://localhost:5001/api/',
				app_url: process.env.MIGHTYBOT_APP_URI || 'http://localhost:8001',
				ws_url: process.env.MIGHTYBOT_WS_URI || 'ws://localhost:5001/',
				contentful_entry_id:
					process.env.CONTENTFUL_ENTRY_ID || '2Sky2Zb3XHe0HFOnJ7ekh4',
			},
			tenant_id: 'local',
			workspace_domain: 'local',
		},
	];
};

/**
 * Checks if the current environment is a Chrome extension
 */
export const isChromeExtension = Boolean(window?.chrome?.runtime?.id);

/**
 * Validates if a given URL is a valid meeting URL (Google Meet or Zoom)
 * @param url - The URL to validate
 * @returns Boolean indicating if the URL is a valid meeting URL
 */
export const isValidMeetingUrl = (url: string): boolean => {
	const meetRegex =
		/^(https?:\/\/)?(meet\.google\.com\/)[a-z0-9\-]+(\?[a-zA-Z0-9=&]+)?$/i;
	const zoomRegex = /^(https?:\/\/)?(.*\.)?zoom\.us\/.*/i;
	return meetRegex.test(url) || zoomRegex.test(url);
};

/**
 * Checks if the current pathname corresponds to a public page
 * @param pathname - The current pathname to check
 * @returns Boolean indicating if the pathname is a public page
 */
export const isPublicPage = (pathname: string): boolean => {
	const pathnameWithoutBaseUrl = pathname.replace('/app', '');
	return PUBLIC_PATHS.some((path) => pathnameWithoutBaseUrl === path);
};

/**
 * Transforms a base search query by adding filters for apps, timestamps, and people
 * @param baseQuery - The original search query
 * @param filters - Optional filters object containing app, timestamp, and people filters
 * @returns Transformed query string with applied filters
 */
export const transformQueryWithFilters = (
	baseQuery: string,
	filters?: {
		appFilter?: string[];
		timestampFilter?: TimestampFilterValue;
		peopleFilter?: string[];
	},
): string => {
	const facetFilters: string[] = [];

	// Add app filters
	if (filters?.appFilter?.length && filters.appFilter.length > 0) {
		const appFilterString = filters.appFilter.join(' OR ');
		facetFilters.push(`app:(${appFilterString})`);
	}

	// Add people filters
	if (filters?.peopleFilter?.length && filters.peopleFilter.length > 0) {
		const peopleFilterString = filters.peopleFilter.join(' OR ');
		facetFilters.push(`people:(${peopleFilterString})`);
	}

	// Modified date handling to remove timezone
	if (
		filters?.timestampFilter?.startDate ||
		filters?.timestampFilter?.endDate
	) {
		const formatDate = (date: Date) => {
			return date.toISOString().slice(0, 19); // Gets YYYY-MM-DDTHH:mm:ss format
		};

		const startDate = filters.timestampFilter.startDate
			? formatDate(filters.timestampFilter.startDate)
			: null;
		const endDate = filters.timestampFilter.endDate
			? formatDate(filters.timestampFilter.endDate)
			: null;

		if (startDate && endDate) {
			facetFilters.push(`updated:${startDate}..${endDate}`);
		} else if (startDate) {
			facetFilters.push(`updated:>=${startDate}`);
		} else if (endDate) {
			facetFilters.push(`updated:<=${endDate}`);
		}
	}

	// Construct the final query string
	const queryPart = `${baseQuery}`;
	const filtersPart =
		facetFilters.length > 0
			? ` | user_selected_filters: ${facetFilters.join(' ')}`
			: '';

	return `${queryPart}${filtersPart}`;
};

/**
 * Serializes an object of arrays into a URLSearchParams string
 * @param params - The object containing arrays to serialize
 * @returns The serialized URLSearchParams string
 */
export const serializeArrayParams = (params: Record<string, string[]>) => {
	const searchParams = new URLSearchParams();
	Object.entries(params).forEach(([key, values]) => {
		if (values && values.length > 0) {
			values.forEach((value) => searchParams.append(key, value));
		}
	});
	return searchParams.toString();
};

/**
 * Formats a number as currency with the specified currency code
 * @param amount - The amount to format
 * @param currencyCode - The ISO currency code (e.g. 'USD', 'EUR')
 * @returns Formatted currency string
 */
export const formatCurrency = (
	amount: string | number,
	currencyCode: string = 'USD',
): string => {
	const numericAmount =
		typeof amount === 'string' ? parseFloat(amount) : amount;

	if (isNaN(numericAmount)) {
		return '';
	}

	return new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: currencyCode || 'USD',
		minimumFractionDigits: 0,
		maximumFractionDigits: 2,
	}).format(numericAmount);
};
