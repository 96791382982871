import { useState } from 'react';

import { useJoinMeetingMutation } from '../api/meetingsApi';
import { MeetingsJoinRequest, MeetingsJoinResponse } from '../api/types';

export const useJoinMeeting = () => {
	const [joinMeeting, { isLoading, isError, error }] = useJoinMeetingMutation();
	const [joinedMeeting, setJoinedMeeting] = useState<boolean>(false);

	const handleJoinMeeting = async (
		meetingData: MeetingsJoinRequest,
	): Promise<MeetingsJoinResponse & { already_joined?: boolean }> => {
		try {
			const response: MeetingsJoinResponse =
				await joinMeeting(meetingData).unwrap();
			setJoinedMeeting(!!response.bot_id);
			return response;
		} catch (err: any) {
			console.error('Failed to join meeting:', err);
			if (err.status === 400 && err.data?.bot_id) {
				setJoinedMeeting(true);
				return {
					bot_id: err.data.bot_id,
					already_joined: true,
					...err.data,
				};
			}
			throw err;
		}
	};

	return {
		joinMeeting: handleJoinMeeting,
		isLoading,
		isError,
		error,
		joinedMeeting,
	};
};
