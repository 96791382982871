import { meetingApi } from './base';
import {
	ShareMeetingRequest,
	ShareMeetingResponse,
	SharedUsersResponse,
} from './types';

export const meetingPresenceApi = meetingApi.injectEndpoints({
	endpoints: (builder) => ({
		shareMeeting: builder.mutation<
			ShareMeetingResponse,
			{ meetingId: string; data: ShareMeetingRequest }
		>({
			query: ({ meetingId, data }) => ({
				url: `/${meetingId}/share`,
				method: 'POST',
				body: data,
			}),
		}),
		getSharedUsers: builder.query<SharedUsersResponse, string>({
			query: (meetingId) => `/${meetingId}/shared-users`,
			providesTags: ['SharedUsers'],
		}),
	}),
});

export const { useShareMeetingMutation, useGetSharedUsersQuery } =
	meetingPresenceApi;
