import { useEffect, useRef, useState } from 'react';

import { useGetPeopleListQuery } from '../api/personApi';
import { PeopleStatusOptions, PeopleTypeOptions } from '../commons/types';

export interface UsePeopleListOptions {
	per_page: number;
	search?: string;
	companies?: string[];
	teams?: string[];
	deals?: string[];
	statuses?: PeopleStatusOptions[];
	type?: PeopleTypeOptions;
	internalOnly?: boolean;
}

export const usePeopleList = (options: UsePeopleListOptions) => {
	const [page, setPage] = useState(1);

	const filterKey = JSON.stringify({
		search: options.search,
		companies: options.companies,
		type: options.type,
	});

	const { data, isLoading, error, refetch } = useGetPeopleListQuery({
		...options,
		page,
		filterKey,
	});

	return {
		people: data?.people ?? [],
		total: data?.total ?? 0,
		isLoading,
		error,
		refetch,
		page,
		setPage,
	};
};
