import { useState } from 'react';

import {
	useCreateNoteMutation,
	useDeleteNoteMutation,
	useGetNoteQuery,
	useListNotesQuery,
	useUpdateNoteMutation,
} from '../api/notesApi';
import { CollectionType } from '../commons/types';
import { CreateNoteData, UpdateNoteData } from './types';

interface UseNotesListProps {
	collectionId?: string;
	collectionType?: CollectionType;
	page?: number;
	pageSize?: number;
}

interface UseNotesProps {
	noteId?: string;
	collectionId?: string;
	collectionType?: CollectionType;
	skip?: boolean;
}

const DEFAULT_LIMIT = 10;

// Separate hook for list functionality
export const useNotesList = ({
	collectionId,
	collectionType,
	page = 1,
	pageSize = DEFAULT_LIMIT,
}: UseNotesListProps = {}) => {
	const {
		data: noteList,
		isLoading,
		error: fetchError,
		isFetching,
	} = useListNotesQuery(
		{
			collection_id: collectionId ?? '',
			page,
			page_size: pageSize,
			collection_type: collectionType ? [collectionType] : undefined,
		}, { skip: !collectionId || !collectionType },
	);

	return {
		notes: noteList?.items ?? [],
		hasMore: noteList?.has_more ?? false,
		isLoading,
		isFetching,
		fetchError,
		total: noteList?.total ?? 0,
	};
};

export const useNotes = ({ noteId }: UseNotesProps = {}) => {
	const [error, setError] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState(false);

	const { data: note, isLoading: isFetchingNote } = useGetNoteQuery(
		noteId ?? '',
		{
			skip: !noteId,
		},
	);

	const [createNote] = useCreateNoteMutation();
	const [updateNote] = useUpdateNoteMutation();
	const [deleteNote] = useDeleteNoteMutation();

	const handleCreateNote = async (data: CreateNoteData) => {
		setError(null);
		setIsLoading(true);
		try {
			const response = await createNote(data).unwrap();
			return response;
		} catch (err) {
			setError(err instanceof Error ? err.message : 'Failed to create note');
			throw err;
		} finally {
			setIsLoading(false);
		}
	};

	const handleUpdateNote = async (noteId: string, data: UpdateNoteData) => {
		setError(null);
		setIsLoading(true);
		try {
			const response = await updateNote({ noteId, body: data }).unwrap();
			return response;
		} catch (err) {
			setError(err instanceof Error ? err.message : 'Failed to update note');
			throw err;
		} finally {
			setIsLoading(false);
		}
	};

	const handleDeleteNote = async (noteId: string) => {
		setError(null);
		setIsLoading(true);
		try {
			await deleteNote(noteId).unwrap();
		} catch (err) {
			setError(err instanceof Error ? err.message : 'Failed to delete note');
			throw err;
		} finally {
			setIsLoading(false);
		}
	};

	return {
		note,
		error,
		isLoading: isLoading || isFetchingNote,
		createNote: handleCreateNote,
		updateNote: handleUpdateNote,
		deleteNote: handleDeleteNote,
	};
};
