import { setCredentials } from '../features/authSlice';
import { logger } from '../services/logger';
import { api } from './base';
import {
	BulkUpdateFavoriteRequest,
	FavoritesResponse,
	GetUserFavoritesQuery,
	MeResponse,
	TourStatus,
	UpdateFavoriteRequest,
	UpdateTourStatusRequest,
	UserInfo,
} from './types';

export const userApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getMe: builder.query<MeResponse['data'], void>({
			query() {
				return {
					url: '/me',
					method: 'GET',
				};
			},
			transformResponse: (rawResult: MeResponse) => rawResult?.data,
			async onQueryStarted(args, { dispatch, queryFulfilled }) {
				try {
					const { data: user } = await queryFulfilled;
					logger.user({ email: user.email, id: user.id });
					dispatch(
						setCredentials({
							user,
							accessToken: undefined,
							refreshToken: undefined,
						}),
					);
				} catch (error) {
					// fail silently
				}
			},
			providesTags: ['Me'],
		}),
		getUserInfo: builder.query<UserInfo, void>({
			query() {
				return {
					url: '/users/info/',
					method: 'GET',
				};
			},
			transformResponse: (response: UserInfo) => response,
			providesTags: ['Me'],
		}),
		saveUserInfo: builder.mutation<
			{ message: string },
			{ name?: string; title?: string; enterprise_name?: string }
		>({
			query(body) {
				return {
					url: '/users/info/',
					method: 'POST',
					body,
				};
			},
			invalidatesTags: ['Me'],
			async onQueryStarted(arg, { queryFulfilled }) {
				try {
					await queryFulfilled;
				} catch (error) {
					throw new Error(`Failed to save user info`);
				}
			},
		}),
		getTourStatus: builder.query<TourStatus, void>({
			query: () => ({
				url: '/users/tour/status',
				method: 'GET',
			}),
		}),
		updateTourStatus: builder.mutation<TourStatus, UpdateTourStatusRequest>({
			query: (body) => ({
				url: '/users/tour/status',
				method: 'POST',
				body,
			}),
		}),
		getFavorites: builder.query<FavoritesResponse, GetUserFavoritesQuery>({
			query: (query) => ({
				url: '/users/favorites',
				method: 'GET',
				params: query,
			}),
			providesTags: ['Favorites'],
		}),
		updateFavorite: builder.mutation<FavoritesResponse, UpdateFavoriteRequest>({
			query: (body) => ({
				url: '/users/favorites',
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Favorites'],
		}),
		bulkUpdateFavorites: builder.mutation<
			FavoritesResponse,
			BulkUpdateFavoriteRequest
		>({
			query: (body) => ({
				url: '/users/favorites/bulk',
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Favorites'],
		}),
	}),
});

export const {
	useGetMeQuery,
	useGetUserInfoQuery,
	useSaveUserInfoMutation,
	useGetTourStatusQuery,
	useUpdateTourStatusMutation,
	useGetFavoritesQuery,
	useUpdateFavoriteMutation,
	useBulkUpdateFavoritesMutation,
} = userApi;
