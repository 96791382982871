import { Meeting, MeetingContentItem } from '../api/types';

export const SAMPLE_MEETINGS: Meeting[] = [
	{
		id: 'example-meeting',
		title: 'Q1 Product Strategy Review (Example)',
		start_time: new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString(),
		end_time: new Date(Date.now() + 25 * 60 * 60 * 1000).toISOString(),
		attendees: ['sarah@company.com', 'mike@company.com', 'alex@company.com'],
		meeting_platform: 'google_meet',
		meeting_url: 'https://meet.google.com/example-meeting',
		is_internal: false,
		meeting_category: 'Project Meetings',
		meeting_type_department: 'Product Management',
		meeting_internal_external: 'External',
		have_bot: true,
		organizer: 'sarah@company.com',
	},
];

export const SAMPLE_MEETING_CONTENT: MeetingContentItem[] = [
	{
		id: 'content-1',
		collection_id: 'example-meeting',
		collection_type: ['meeting'],
		source_agent_channel_id: '550e8400-e29b-41d4-a716-446655440000',
		title: 'AI-Enhanced Meeting Summary',
		content: `### Executive Summary
The Q1 Product Strategy Review focused on aligning our product roadmap with evolving market demands while emphasizing the integration of AI capabilities. The team engaged in comprehensive discussions about enhancing our product offerings and optimizing resource allocation to meet growing customer needs.

## Strategic Direction
The team reached consensus on a significant product evolution planned for Q2. The centerpiece of this update will be enhanced AI agent customization features, complemented by new workflow automation templates specifically designed for marketing and sales teams. We're also expanding our ecosystem by integrating with three additional productivity platforms to broaden our market reach.

### Implementation Strategy
Our technical approach will prioritize the enhancement of our machine learning pipeline, with a particular focus on improving workflow predictions and optimizing agent response times. The engineering team will begin implementation in the next sprint, while the product team finalizes workflow specifications by end of week. Design resources will be dedicated to creating an intuitive, personalized assistant dashboard.

### Key Next Steps
The immediate priority is to initiate individual team deep-dives to ensure alignment across departments. We'll commence sprint planning for the new features while simultaneously selecting customers for our beta program. Resource allocation for Q2 will be reviewed to support these initiatives.

A follow-up meeting is scheduled for next week to track progress and address any emerging challenges.`,
		cotent_metadata: {
			type: 'summary',
		},
	},
	{
		id: 'content-2',
		collection_id: 'example-meeting',
		collection_type: ['meeting'],
		source_agent_channel_id: '550e8400-e29b-41d4-a716-446655440001',
		title: 'AI Insights & Recommendations',
		content: `### AI-Generated Strategic Analysis
Based on the meeting discussions, several key opportunities and considerations have emerged for our strategic planning:

### Workflow Enhancement Strategy
Our analysis indicates significant potential for automation within the marketing department's current workflows. Additionally, the customer support team could substantially benefit from AI agent integration, potentially reducing response times and improving service quality. The sales team's reporting processes present another valuable opportunity for optimization through our new template system.

### Resource Planning Insights
To support our ambitious goals, we recommend a 20% increase in AI training resources. The growing demand for enterprise-level customizations suggests the need for a dedicated team in this area. User feedback has highlighted UI/UX improvements as a critical priority that requires immediate attention.

### Risk and Growth Considerations
As we scale our operations, system performance monitoring will be crucial. We need to maintain robust security measures while preparing for potential API integration changes. Looking ahead, there are significant opportunities in expanding our integration partnerships and developing industry-specific AI agent templates. Our success will be measured through user engagement metrics, automation adoption rates, and overall customer satisfaction.`,
		cotent_metadata: {
			type: 'action_items',
		},
	},
];
