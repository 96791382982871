import { useGetDealFilterOptionsQuery } from '../api/dealsApi';

export const useDealFilterOptions = (enterprise_id: string) => {
	const { data, isLoading, error } = useGetDealFilterOptionsQuery(
		{ enterprise_id },
		{
			// Skip the query if no enterprise_id is provided
			skip: !enterprise_id,
		},
	);

	return {
		data,
		isLoading,
		error,
	};
};
