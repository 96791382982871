import { useLocation } from 'react-router-dom';

import { MetaTags } from './types';

const routeMetaTags: Record<string, MetaTags> = {
	'/': {
		title: 'MightyBot | AI-Powered Business Assistant',
		description:
			'Boost your productivity with MightyBot, the AI-powered assistant for modern businesses.',
		ogTitle: 'MightyBot | AI-Powered Business Assistant',
		ogDescription:
			'Boost your productivity with MightyBot, the AI-powered assistant for modern businesses.',
		// ogImage: "https://www.mightybot.ai/og-image.jpg",
		twitterTitle: 'MightyBot | AI-Powered Business Assistant',
		twitterDescription:
			'Boost your productivity with MightyBot, the AI-powered assistant for modern businesses.',
		// twitterImage: "https://www.mightybot.ai/twitter-image.jpg",
	},
	'/signin': {
		title: 'Sign In | MightyBot',
		description:
			'Sign in to your MightyBot account and start boosting your productivity.',
		ogTitle: 'Sign In to MightyBot',
		ogDescription:
			'Access your AI-powered business assistant and streamline your workflow.',
		// ogImage: "https://www.mightybot.ai/signin-og-image.jpg",
		twitterTitle: 'Sign In to MightyBot',
		twitterDescription:
			'Access your AI-powered business assistant and streamline your workflow.',
		// twitterImage: "https://www.mightybot.ai/signin-twitter-image.jpg",
	},
	'/login': {
		title: 'Log In | MightyBot',
		description:
			'Log in to your MightyBot account and access your AI-powered business assistant.',
		ogTitle: 'Log In to MightyBot',
		ogDescription:
			'Access your personalized MightyBot dashboard and start optimizing your workflow.',
		// ogImage: "https://www.mightybot.ai/login-og-image.jpg",
		twitterTitle: 'Log In to MightyBot',
		twitterDescription:
			'Access your personalized MightyBot dashboard and start optimizing your workflow.',
		// twitterImage: "https://www.mightybot.ai/login-twitter-image.jpg",
	},
	'/signup': {
		title: 'Sign Up | MightyBot',
		description:
			'Sign up for MightyBot and start boosting your productivity with AI.',
		ogTitle: 'Sign Up for MightyBot',
		ogDescription:
			'Create your account and start using MightyBot to streamline your business operations.',
		twitterTitle: 'Sign Up for MightyBot',
		twitterDescription:
			'Create your account and start using MightyBot to streamline your business operations.',
	},
	'/onboarding': {
		title: 'Get Started with MightyBot',
		description:
			'Set up your MightyBot account and customize it to fit your business needs.',
		ogTitle: 'Get Started with MightyBot',
		ogDescription:
			'Personalize your AI assistant and start boosting your productivity today.',
		// ogImage: "https://www.mightybot.ai/onboarding-og-image.jpg",
		twitterTitle: 'Get Started with MightyBot',
		twitterDescription:
			'Personalize your AI assistant and start boosting your productivity today.',
		// twitterImage: "https://www.mightybot.ai/onboarding-twitter-image.jpg",
	},
	'/home': {
		title: 'MightyBot Dashboard | Your AI Assistant',
		description:
			'Access your personalized MightyBot dashboard and manage your AI-powered tasks.',
		ogTitle: 'MightyBot Dashboard | Your AI Assistant',
		ogDescription:
			'Manage your tasks, view insights, and boost productivity with MightyBot.',
		// ogImage: "https://www.mightybot.ai/dashboard-og-image.jpg",
		twitterTitle: 'MightyBot Dashboard | Your AI Assistant',
		twitterDescription:
			'Manage your tasks, view insights, and boost productivity with MightyBot.',
		// twitterImage: "https://www.mightybot.ai/dashboard-twitter-image.jpg",
	},
	'/meetings': {
		title: 'Meetings | MightyBot',
		description:
			"Manage and optimize your meetings with MightyBot's AI-powered assistance.",
		ogTitle: 'AI-Powered Meeting Management | MightyBot',
		ogDescription:
			"Streamline your meetings and boost productivity with MightyBot's intelligent features.",
		// ogImage: "https://www.mightybot.ai/meetings-og-image.jpg",
		twitterTitle: 'AI-Powered Meeting Management | MightyBot',
		twitterDescription:
			"Streamline your meetings and boost productivity with MightyBot's intelligent features.",
		// twitterImage: "https://www.mightybot.ai/meetings-twitter-image.jpg",
	},
	'/settings': {
		title: 'Account Settings | MightyBot',
		description: 'Manage your MightyBot account settings and preferences.',
		ogTitle: 'Customize Your MightyBot Experience',
		ogDescription:
			"Tailor your AI assistant to your needs with MightyBot's customizable settings.",
		// ogImage: "https://www.mightybot.ai/settings-og-image.jpg",
		twitterTitle: 'Customize Your MightyBot Experience',
		twitterDescription:
			"Tailor your AI assistant to your needs with MightyBot's customizable settings.",
		// twitterImage: "https://www.mightybot.ai/settings-twitter-image.jpg",
	},
	'/deals': {
		title: 'Deals Management | MightyBot',
		description:
			"Track and manage your business deals with MightyBot's AI-powered insights.",
		ogTitle: 'AI-Enhanced Deal Management | MightyBot',
		ogDescription:
			"Optimize your sales pipeline and close more deals with MightyBot's intelligent assistance.",
		// ogImage: "https://www.mightybot.ai/deals-og-image.jpg",
		twitterTitle: 'AI-Enhanced Deal Management | MightyBot',
		twitterDescription:
			"Optimize your sales pipeline and close more deals with MightyBot's intelligent assistance.",
		// twitterImage: "https://www.mightybot.ai/deals-twitter-image.jpg",
	},
	'/customers': {
		title: 'Customer Management | MightyBot',
		description:
			"Manage your customer relationships with MightyBot's AI-powered CRM features.",
		ogTitle: 'Intelligent Customer Management | MightyBot',
		ogDescription:
			"Enhance customer relationships and boost satisfaction with MightyBot's AI-driven insights.",
		// ogImage: "https://www.mightybot.ai/customers-og-image.jpg",
		twitterTitle: 'Intelligent Customer Management | MightyBot',
		twitterDescription:
			"Enhance customer relationships and boost satisfaction with MightyBot's AI-driven insights.",
		// twitterImage: "https://www.mightybot.ai/customers-twitter-image.jpg",
	},
	'/chats': {
		title: 'AI-Powered Chats | MightyBot',
		description:
			"Engage in intelligent conversations with MightyBot's AI chat feature.",
		ogTitle: 'Smart Conversations with MightyBot',
		ogDescription:
			"Experience seamless communication and get instant answers with MightyBot's AI chat.",
		// ogImage: "https://www.mightybot.ai/chats-og-image.jpg",
		twitterTitle: 'Smart Conversations with MightyBot',
		twitterDescription:
			"Experience seamless communication and get instant answers with MightyBot's AI chat.",
		// twitterImage: "https://www.mightybot.ai/chats-twitter-image.jpg",
	},
	'/search': {
		title: 'Intelligent Search | MightyBot',
		description:
			"Find what you need quickly with MightyBot's AI-powered search feature.",
		ogTitle: 'Smart Search with MightyBot',
		ogDescription:
			"Discover insights and information effortlessly with MightyBot's intelligent search capabilities.",
		// ogImage: "https://www.mightybot.ai/search-og-image.jpg",
		twitterTitle: 'Smart Search with MightyBot',
		twitterDescription:
			"Discover insights and information effortlessly with MightyBot's intelligent search capabilities.",
		// twitterImage: "https://www.mightybot.ai/search-twitter-image.jpg",
	},
	'/welcome': {
		title: 'Welcome to MightyBot',
		description:
			'Get started with MightyBot and explore the power of AI-assisted business management.',
		ogTitle: 'Welcome to MightyBot - Your AI Business Assistant',
		ogDescription:
			'Begin your journey with MightyBot and discover how AI can transform your business operations.',
		// ogImage: "https://www.mightybot.ai/welcome-og-image.jpg",
		twitterTitle: 'Welcome to MightyBot - Your AI Business Assistant',
		twitterDescription:
			'Begin your journey with MightyBot and discover how AI can transform your business operations.',
		// twitterImage: "https://www.mightybot.ai/welcome-twitter-image.jpg",
	},
	'/connect': {
		title: 'Connect Your Tools | MightyBot',
		description:
			'Integrate your favorite tools and services with MightyBot for seamless workflow.',
		ogTitle: 'Supercharge Your Workflow with MightyBot Integrations',
		ogDescription:
			'Connect your essential tools to MightyBot and experience the power of AI-driven integrations.',
		// ogImage: "https://www.mightybot.ai/connect-og-image.jpg",
		twitterTitle: 'Supercharge Your Workflow with MightyBot Integrations',
		twitterDescription:
			'Connect your essential tools to MightyBot and experience the power of AI-driven integrations.',
		// twitterImage: "https://www.mightybot.ai/connect-twitter-image.jpg",
	},
	'/admin': {
		title: 'Admin Dashboard | MightyBot',
		description:
			"Manage your organization's MightyBot settings and users from the admin dashboard.",
		ogTitle: 'MightyBot Admin - Powerful Control for Your Organization',
		ogDescription:
			"Efficiently manage your team's MightyBot experience with our comprehensive admin tools.",
		// ogImage: "https://www.mightybot.ai/admin-og-image.jpg",
		twitterTitle: 'MightyBot Admin - Powerful Control for Your Organization',
		twitterDescription:
			"Efficiently manage your team's MightyBot experience with our comprehensive admin tools.",
		// twitterImage: "https://www.mightybot.ai/admin-twitter-image.jpg",
	},
	'/feedback': {
		title: 'Provide Feedback | MightyBot',
		description:
			'Share your thoughts and help us improve MightyBot with your valuable feedback.',
		ogTitle: 'Help Shape the Future of MightyBot',
		ogDescription:
			'Your feedback is crucial in making MightyBot even better. Share your thoughts and suggestions with us.',
		// ogImage: "https://www.mightybot.ai/feedback-og-image.jpg",
		twitterTitle: 'Help Shape the Future of MightyBot',
		twitterDescription:
			'Your feedback is crucial in making MightyBot even better. Share your thoughts and suggestions with us.',
		// twitterImage: "https://www.mightybot.ai/feedback-twitter-image.jpg",
	},
	// Add more routes and their respective meta tags here
};

export function useMetaTags(pathname: string): MetaTags {
	const routePath = `/${pathname.split('/')[1]}`;

	return routeMetaTags[routePath] || routeMetaTags['/'];
}
