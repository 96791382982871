import { useGetUserInfoQuery, useSaveUserInfoMutation } from '../api/userApi';

export const useUserInfo = () => {
	const { data, isLoading, error } = useGetUserInfoQuery();
	const [saveUserInfo, { isLoading: isSaving, error: saveError }] =
		useSaveUserInfoMutation();

	const updateUserInfo = async (
		name?: string,
		title?: string,
		enterpriseName?: string,
	) => {
		try {
			await saveUserInfo({
				name,
				title,
				enterprise_name: enterpriseName,
			}).unwrap();
		} catch (error) {
			throw new Error('Failed to update user info ');
			// Optionally, handle error state here or propagate it
		}
	};

	return {
		userInfo: data,
		isLoading,
		error,
		updateUserInfo,
		isSaving,
		saveError,
	};
};
