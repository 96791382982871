import { useCallback } from 'react';

import {
	useBulkUpdateFavoritesMutation,
	useGetFavoritesQuery,
	useUpdateFavoriteMutation,
} from '../api/userApi';
import { FavoriteType } from '../commons/types';

export const useFavorites = (entityType: FavoriteType) => {
	const { data: favoritesData, isLoading: isFavoritesLoading } =
		useGetFavoritesQuery({ entity_type: entityType });
	const [updateFavorite] = useUpdateFavoriteMutation();
	const [bulkUpdateFavorites] = useBulkUpdateFavoritesMutation();

	const favorites = favoritesData?.favorites ?? [];

	const toggleFavorite = useCallback(
		async (itemId: string, metadata?: Record<string, any>) => {
			try {
				const isFavorite = favorites.includes(itemId);
				await updateFavorite({
					entity_id: itemId,
					is_favorite: !isFavorite,
					entity_type: entityType,
					metadata,
				}).unwrap();
				return true;
			} catch (error) {
				console.error('Failed to update favorite:', error);
				return false;
			}
		},
		[favorites, updateFavorite],
	);

	const toggleBulkFavorites = useCallback(
		async (itemIds: string[], metadata?: Record<string, any>) => {
			try {
				const shouldAddFavorites = itemIds.some(
					(id) => !favorites.includes(id),
				);

				await bulkUpdateFavorites({
					entity_ids: itemIds,
					is_favorite: shouldAddFavorites,
					entity_type: entityType,
					metadata,
				}).unwrap();
				return true;
			} catch (error) {
				console.error('Failed to bulk update favorites:', error);
				return false;
			}
		},
		[favorites, bulkUpdateFavorites],
	);

	const isFavorite = useCallback(
		(itemId: string) => {
			return favorites.includes(itemId);
		},
		[favorites],
	);

	return {
		favorites,
		isFavoritesLoading,
		toggleFavorite,
		toggleBulkFavorites,
		isFavorite,
	};
};
