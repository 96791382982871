export const sendToServiceWorker = (
	event: string,
	data: Record<string, string>,
) => {
	if (navigator?.serviceWorker) {
		void navigator.serviceWorker.ready.then((registration) => {
			registration.active?.postMessage(
				JSON.stringify({
					event,
					data,
				}),
			);
		});
	}
};
