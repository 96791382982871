import { useEffect } from 'react';

import { RootState, useTypedSelector } from '../store';
import { useMe } from './useMe';

export interface AuthState {
	isAuthenticated: boolean;
	isLoading: boolean;
	user: ReturnType<typeof useMe>['data'];
	accessToken: string | null;
}

export const useAuthState = (): AuthState => {
	const { data: user, isLoading } = useMe();
	const accessToken = useTypedSelector(
		(state: RootState) => state.authState.accessToken,
	);
	const isAuthenticated = useTypedSelector(
		(state: RootState) => state.authState.isAuthenticated,
	);

	// Ensure auth state is consistent
	useEffect(() => {
		if (!isLoading && !user && accessToken) {
			console.warn('Auth state inconsistency: Token exists but no user data');
		}
	}, [isLoading, user, accessToken]);

	return {
		isAuthenticated,
		isLoading,
		user,
		accessToken,
	};
};
