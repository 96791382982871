import { ACCESS_TOKEN_KEY, APP_URL_KEY, REFRESH_TOKEN_KEY } from '../constants';
import {
	getCookie,
	isChromeExtension,
	removeCookie,
	setCookie,
} from '../utils/helper';

const getAppUrl = (): string => {
	let defaultUrl = window.location.origin;
	if (isChromeExtension) {
		defaultUrl = localStorage.getItem(APP_URL_KEY) as string;
	}
	if (!defaultUrl) {
		defaultUrl = 'https://app.mightybot.ai';
	}
	return defaultUrl;
};

const getAccessTokenKey = () => {
	if (isChromeExtension) {
		return ACCESS_TOKEN_KEY;
	}
	return `${ACCESS_TOKEN_KEY}_web`;
};

const getRefreshTokenKey = () => {
	if (isChromeExtension) {
		return REFRESH_TOKEN_KEY;
	}
	return `${REFRESH_TOKEN_KEY}_web`;
};

const getAccessToken = async (appUrl?: string): Promise<string> => {
	appUrl = appUrl || getAppUrl();
	const tokenKey = getAccessTokenKey();

	if (isChromeExtension) {
		try {
			const token = await chrome.cookies.get({
				name: tokenKey,
				url: appUrl,
			});
			return token?.value || '';
		} catch (error) {
			console.error('Error getting access token:', error);
			return '';
		}
	} else {
		return getCookie(tokenKey) || '';
	}
};

const getRefreshToken = async (appUrl?: string): Promise<string> => {
	appUrl = appUrl || getAppUrl();
	const tokenKey = getRefreshTokenKey();

	if (isChromeExtension) {
		try {
			const token = await chrome.cookies.get({
				name: tokenKey,
				url: appUrl,
			});
			return token?.value || '';
		} catch (error) {
			console.error('Error getting refresh token:', error);
			return '';
		}
	} else {
		return getCookie(tokenKey) || '';
	}
};

const setAccessToken = async (
	token: string,
	appUrl?: string,
): Promise<void> => {
	appUrl = appUrl || getAppUrl();
	const tokenKey = getAccessTokenKey();

	if (isChromeExtension) {
		try {
			await chrome.cookies.set({
				name: tokenKey,
				url: appUrl,
				value: token,
				expirationDate: new Date().getTime() / 1000 + 86400,
				httpOnly: true,
				secure: true,
				sameSite: 'strict',
			});
		} catch (error) {
			console.error('Error setting access token:', error);
		}
	} else {
		setCookie(tokenKey, token, 1); // 1 day expiration
	}
};

const setRefreshToken = async (
	token: string,
	appUrl?: string,
): Promise<void> => {
	appUrl = appUrl || getAppUrl();
	const tokenKey = getRefreshTokenKey();

	if (isChromeExtension) {
		try {
			await chrome.cookies.set({
				name: tokenKey,
				url: appUrl,
				value: token,
				expirationDate: new Date().getTime() / 1000 + 2592000,
				httpOnly: true,
				secure: true,
				sameSite: 'strict',
			});
		} catch (error) {
			console.error('Error setting refresh token:', error);
		}
	} else {
		setCookie(tokenKey, token, 30); // 30 days expiration
	}
};

const removeAccessToken = async (appUrl?: string): Promise<void> => {
	appUrl = appUrl || getAppUrl();
	const tokenKey = getAccessTokenKey();

	if (isChromeExtension) {
		try {
			await chrome.cookies.remove({
				name: tokenKey,
				url: appUrl,
			});
		} catch (error) {
			console.error('Error removing access token:', error);
		}
	} else {
		removeCookie(tokenKey);
	}
};

const removeRefreshToken = async (appUrl?: string): Promise<void> => {
	appUrl = appUrl || getAppUrl();
	const tokenKey = getRefreshTokenKey();

	if (isChromeExtension) {
		try {
			await chrome.cookies.remove({
				name: tokenKey,
				url: appUrl,
			});
		} catch (error) {
			console.error('Error removing refresh token:', error);
		}
	} else {
		removeCookie(tokenKey);
	}
};

export const TokenUtil = {
	getAppUrl,
	getAccessToken,
	getRefreshToken,
	setAccessToken,
	setRefreshToken,
	removeAccessToken,
	removeRefreshToken,
};
