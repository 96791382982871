import { useEffect, useState } from 'react';

import { useGetCompanyDealsQuery } from '../api/companyApi';

const DEFAULT_LIMIT = 40;

interface UseCompanyDealsOptions {
	page?: number;
	perPage?: number;
	enterprise_id: string | undefined;
}

export const useCompanyDeals = (
	company_id: string,
	options: UseCompanyDealsOptions,
) => {
	const [page, setPage] = useState(1);
	const perPage = options.perPage ?? DEFAULT_LIMIT;

	const { data, isLoading, isFetching, refetch } = useGetCompanyDealsQuery(
		{
			company_id,
			enterprise_id: options.enterprise_id ?? '',
			page: options?.page ?? page,
			per_page: perPage,
		},
		{
			skip: !company_id || !options.enterprise_id,
		},
	);

	useEffect(() => {
		setPage(1);
	}, [company_id]);

	return {
		deals: data?.data ?? [],
		isLoading,
		isFetching,
		page,
		setPage,
		hasMore: data?.has_more ?? false,
		refetch,
		total: data?.total ?? 0,
	};
};
