import { api } from './base';
import { EngagementActionRequest, EngagementActionResponse } from './types';

// Define a service using a base URL and expected endpoints
export const engagementApi = api.injectEndpoints({
	endpoints: (builder) => ({
		submitEngagementAction: builder.mutation<
			EngagementActionResponse,
			EngagementActionRequest
		>({
			query: (request) => ({
				url: 'home_feed/engagement',
				method: 'POST',
				body: request,
			}),
			transformResponse: (response: any) => {
				// Transform the response if necessary
				return response;
			},
		}),
	}),
});

// Export hooks for usage in functional components
export const { useSubmitEngagementActionMutation } = engagementApi;
