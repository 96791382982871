import React from 'react';

interface LazyIconProps {
	src: string;
	alt: string;
}

const LazyIcon: React.FC<LazyIconProps> = ({ src, alt }) => {
	return <img src={src} alt={alt} style={{ width: '100%', height: '100%' }} />;
};

export default LazyIcon;
