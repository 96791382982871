export const SAMPLE_FEED = [
	{
		category: 'Decision',
		company_names: ['Example'],
		description:
			'**Summary:** Q1 Product Strategy Review completed. Key decisions: AI agent customization features, new workflow automation templates, and integration with 3 new platforms.',
		score: 95,
		title: 'Q1 Product Strategy Review Summary',
		timestamp: new Date(Date.now() + 23 * 60 * 60 * 1000).toISOString(),
		meeting_id: 'example-meeting',
	},
	{
		category: 'Task',
		company_names: ['Example'],
		description:
			'**Tasks:**\n- Engineering: Implement new AI model integration\n- Product: Finalize workflow templates\n- Design: Complete UI mockups for assistant dashboard',
		score: 90,
		title: 'Strategy Review Action Items',
		timestamp: new Date(Date.now() + 22 * 60 * 60 * 1000).toISOString(),
		meeting_id: 'example-meeting',
	},
	{
		category: 'Insight',
		company_names: ['Example'],
		description:
			'**Growth Opportunities Detected:**\n- Expand integration partnerships\n- Develop industry-specific AI templates\n- Create advanced analytics dashboard',
		score: 88,
		title: 'Strategic Growth Analysis',
		timestamp: new Date(Date.now() + 21 * 60 * 60 * 1000).toISOString(),
		meeting_id: 'example-meeting',
	},
	{
		category: 'Opportunity',
		company_names: ['Example'],
		description:
			'**Recommendations:**\n- Increase AI training resources by 20%\n- Dedicate team for enterprise customizations\n- Prioritize UX improvements based on feedback',
		score: 85,
		title: 'Resource Allocation Insights',
		timestamp: new Date(Date.now() + 20 * 60 * 60 * 1000).toISOString(),
		meeting_id: 'example-meeting',
	},
	{
		category: 'Debate',
		company_names: ['Example'],
		description:
			'**Technical Discussion Points:**\n- ML pipeline enhancement strategies\n- Performance optimization approaches\n- Enterprise security implementation options',
		score: 92,
		title: 'Technical Implementation Discussion',
		timestamp: new Date(Date.now() + 19 * 60 * 60 * 1000).toISOString(),
		meeting_id: 'example-meeting',
	},
];
