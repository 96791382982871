import { useCallback, useState } from 'react';

import { useExecuteStructuredSearchMutation, useGetActivitiesSearchQuery } from '../api/agentSearchApi';
import { StructuredSearchRequest, NLSearchResult } from '../api/types';
import { Apps, CollectionType } from '../commons/types';

export type ActivityItem = {
	title: string;
	description: string;
	timestamp: string;
	app: string;
	link: string;
	id?: string;
};

const convertSearchResultToActivity = (result: NLSearchResult): ActivityItem => {
	return {
		title: result.title || "",
		description: result.snippet || "",
		timestamp: result.time || new Date().toISOString(),
		app: result.app || "",
		link: result.link || "",
		id: result.id || "",
	};
};

export const useStructuredSearch = () => {
	const [executeStructuredSearch, { isLoading }] =
		useExecuteStructuredSearchMutation();
	const [error, setError] = useState<string | null>(null);

	const handleStructuredSearch = useCallback(
		async (searchRequest: StructuredSearchRequest) => {
			try {
				setError(null);
				const response = await executeStructuredSearch(searchRequest).unwrap();
				return response;
			} catch (err) {
				const errorMessage =
					err instanceof Error
						? err.message
						: 'An error occurred during search';
				setError(errorMessage);
				throw err;
			}
		},
		[executeStructuredSearch],
	);

	return {
		executeStructuredSearch: handleStructuredSearch,
		isLoading,
		error,
	};
};

export const useActivitiesStructuredSearch = ({
	entityId,
	entityType,
	pageSize = 20,
	page = 1,
	team_view = false,
	apps = [],
}: {
	entityId: string;
	entityType: CollectionType;
	pageSize?: number;
	page?: number;
	team_view?: boolean;
	apps?: Apps[];
}) => {
	const { data, isLoading, isFetching } = useGetActivitiesSearchQuery({
		entity_id: entityId,
		entity_type: entityType,
		page,
		per_page: pageSize,
		team_view,
		apps,
	});

	const activities = data?.results ? data.results.map(convertSearchResultToActivity) : [];
	const hasMore = data ? page < (data.total_pages || 0) : false;

	return {
		activities,
		isLoading,
		isFetching,
		hasMore,
		total: data?.total_items ?? 0,
		totalPages: data?.total_pages ?? 0,
		currentPage: data?.page ?? page,
		count: data?.count ?? 0
	};
};
