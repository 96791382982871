import { useEffect, useState } from 'react';

import { MODELS, SELECTED_MODEL_KEY } from '../constants';
import { Model } from './types';

export function useModelSelection() {
	const [selectedModel, setSelectedModel] = useState<Model>(() => {
		const storedModel = JSON.parse(
			localStorage.getItem(SELECTED_MODEL_KEY) ?? '{}',
		) as Model;
		if (MODELS.some((model) => model.id === storedModel.id)) {
			return storedModel;
		}
		return MODELS[0];
	});

	const getSelectedModel = () => {
		return JSON.parse(
			localStorage.getItem(SELECTED_MODEL_KEY) ?? '{}',
		) as Model;
	};

	useEffect(() => {
		localStorage.setItem(SELECTED_MODEL_KEY, JSON.stringify(selectedModel));
	}, [selectedModel, selectedModel.id]);

	const changeModel = (newModel: Model) => {
		setSelectedModel(newModel);
	};

	return { selectedModel, changeModel, getSelectedModel };
}
