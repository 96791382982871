import React, { PropsWithChildren } from 'react';

import { IconContext } from '@phosphor-icons/react';
import { Theme, ThemePanel } from '@radix-ui/themes';

import Toast from '../Toast';
import './styles-extension.css';
import './styles.css';

interface ProviderProps extends PropsWithChildren {
	debug?: boolean;
	isExtension?: boolean;
}

interface ThemeWrapperProps extends PropsWithChildren {
	isExtension?: boolean;
}

/**
 * Wrapper for app
 */

const ThemeWrapper = ({ children, isExtension = false }: ThemeWrapperProps) => {
	return <div className={isExtension ? 'mb-extension' : ''}>{children}</div>;
};

const Provider = ({
	children,
	debug = false,
	isExtension = false,
}: ProviderProps) => {
	return (
		<ThemeWrapper isExtension={isExtension}>
			{/*// @ts-ignore */}
			<Theme grayColor="mb-gray" accentColor="mb-blue" panelBackground="solid">
				<IconContext.Provider value={{ size: 18 }}>
					<Toast.Provider>
						{children}
						{debug && <ThemePanel />}
					</Toast.Provider>
				</IconContext.Provider>
			</Theme>
		</ThemeWrapper>
	);
};

export default Provider;
