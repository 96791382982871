import { useState } from 'react';

import {
	useGetSharedUsersQuery,
	useShareMeetingMutation,
} from '../api/meetingPresenceApi';
import {
	useGetPersonSharedUsersQuery,
	useSharePersonMutation,
} from '../api/personApi';
import { User } from '../api/types';

interface UseShareResult {
	share: (
		id: string,
		operationOnUsers: 'add' | 'remove',
		userEmails?: string[],
		enterpriseId?: string,
	) => Promise<{ message: string }>;
	refetchSharedUsers: () => void;
	sharedUsers: { users: Partial<User>[] } | undefined;
	isSharing: boolean;
	isLoadingUsers: boolean;
	error: Error | null;
}

export const useShare = (
	type: 'meeting' | 'person' = 'meeting',
	id?: string,
): UseShareResult => {
	const [shareMeetingMutation, { isLoading: isLoadingMeeting }] =
		useShareMeetingMutation();
	const [sharePersonMutation, { isLoading: isLoadingPerson }] =
		useSharePersonMutation();

	const {
		data: meetingSharedUsers,
		isLoading: isLoadingMeetingUsers,
		refetch: refetchMeetingUsers,
	} = useGetSharedUsersQuery(id || '', {
		skip: !id || type !== 'meeting',
	});

	const {
		data: personSharedUsers,
		isLoading: isLoadingPersonUsers,
		refetch: refetchPersonUsers,
	} = useGetPersonSharedUsersQuery(id || '', {
		skip: !id || type !== 'person',
	});

	const [error, setError] = useState<Error | null>(null);

	const share = async (
		id: string,
		operationOnUsers: 'add' | 'remove',
		userEmails?: string[],
		enterpriseId?: string,
	) => {
		try {
			setError(null);
			const data = {
				user_emails: userEmails,
				enterprise_id: enterpriseId,
				operation_on_users: operationOnUsers,
			};

			if (type === 'meeting') {
				return await shareMeetingMutation({ meetingId: id, data }).unwrap();
			} else {
				return await sharePersonMutation({ personId: id, data }).unwrap();
			}
		} catch (err) {
			const error =
				err instanceof Error ? err : new Error(`Failed to share ${type}`);
			setError(error);
			throw error;
		}
	};

	return {
		share,
		refetchSharedUsers:
			type === 'meeting' ? refetchMeetingUsers : refetchPersonUsers,
		sharedUsers: type === 'meeting' ? meetingSharedUsers : personSharedUsers,
		isSharing: type === 'meeting' ? isLoadingMeeting : isLoadingPerson,
		isLoadingUsers:
			type === 'meeting' ? isLoadingMeetingUsers : isLoadingPersonUsers,
		error,
	};
};
