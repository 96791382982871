import { useCallback, useEffect } from 'react';

import isEqual from 'lodash/isEqual';
import { useDispatch } from 'react-redux';

import {
	useGetUserPreferencesQuery,
	useUpdateUserPreferencesMutation,
} from '../api/userPreferencesApi';
import {
	UserPreferencesState,
	setExcludedParticipantDomains as setExcludedParticipantDomainsAction,
	setUserPreferences as setUserPreferencesAction,
	toggleInternalMeetingBot,
	toggleQualityPerformance,
	toggleUserAnalytics,
} from '../features/userPreferencesSlice';
import { useTypedSelector } from '../store';
import { formatUserPreferences } from '../utils/helper';

export function useUserPreferences() {
	const dispatch = useDispatch();
	const userPreferences = useTypedSelector(
		(state) => state.userPreferencesState,
	);

	const { data: dbUserPreferences, isLoading: isLoadingUserPreferences } =
		useGetUserPreferencesQuery();

	const shouldUpdateUserPreferences = useCallback(
		(incomingPreferences: UserPreferencesState) => {
			if (!userPreferences) {
				return true;
			}
			return !isEqual(userPreferences, incomingPreferences);
		},
		[dispatch, userPreferences, dbUserPreferences, isLoadingUserPreferences],
	);

	const setUserAnalyticsToggled = useCallback(
		(value: boolean) => {
			dispatch(toggleUserAnalytics(value));
		},
		[dispatch],
	);

	const setQualityPerformanceToggled = useCallback(
		(value: boolean) => {
			dispatch(toggleQualityPerformance(value));
		},
		[dispatch],
	);

	const setUserPreferences = useCallback(
		(preferences: any) => {
			dispatch(setUserPreferencesAction(preferences));
		},
		[dispatch],
	);

	const setInternalMeetingBotToggled = useCallback(
		(value: boolean) => {
			dispatch(toggleInternalMeetingBot(value));
		},
		[dispatch],
	);

	const setExcludedParticipantDomains = useCallback(
		(value: string[]) => {
			dispatch(setExcludedParticipantDomainsAction(value));
		},
		[dispatch],
	);

	useEffect(() => {
		if (dbUserPreferences && !isLoadingUserPreferences) {
			const formattedPreferences = formatUserPreferences(dbUserPreferences);
			const shouldUpdate = shouldUpdateUserPreferences(formattedPreferences);
			if (shouldUpdate) {
				setUserPreferences(formattedPreferences);
			}
		}
	}, [
		dispatch,
		shouldUpdateUserPreferences,
		dbUserPreferences,
		isLoadingUserPreferences,
	]);

	const [updateUserPreferences] = useUpdateUserPreferencesMutation();

	return {
		userPreferences,
		dbUserPreferences,
		setUserAnalyticsToggled,
		isLoadingUserPreferences,
		setQualityPerformanceToggled,
		setInternalMeetingBotToggled,
		updateUserPreferences,
		setExcludedParticipantDomains,
	};
}
