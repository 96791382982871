import { UUID } from 'crypto';
import localforage from 'localforage';
import sortBy from 'lodash/sortBy';
import { v4 as uuid } from 'uuid';

import { CHATS_KEY, CHAT_MESSAGES_KEY } from '../constants';
import { getSocket } from '../services/socket';
import { RootState } from '../store';
import { getSocketData } from '../utils/helper';
import { api } from './base';
import { channelApi } from './channelApi';
import {
	ChatMessageIncoming,
	ChatMinimal,
	ChatResponse,
	ChatsResponse,
	User,
} from './types';

const createChat = async (chat: ChatMinimal) => {
	try {
		let chats = (await localforage.getItem(CHATS_KEY)) as ChatMinimal[];
		if (!chats) chats = [];
		const newChat: ChatMinimal = {
			...chat,
		};
		chats.push(newChat);
		await localforage.setItem(CHATS_KEY, chats);
		return newChat;
	} catch (error) {
		console.error('Error creating chat', error);
		return null;
	}
};

export const updateChat = async (chat: Partial<ChatMinimal>) => {
	try {
		let chats = (await localforage.getItem(CHATS_KEY)) as ChatMinimal[];
		if (!chats) chats = [];
		const chatIndex = chats.findIndex((c) => c.id === chat.id);
		if (chatIndex > -1) {
			chats[chatIndex] = {
				...chats[chatIndex],
				...chat,
			};
			await localforage.setItem(CHATS_KEY, chats);
			return chats[chatIndex];
		}
		return null;
	} catch (error) {
		console.error('Error updating chat', error);
		return null;
	}
};

const bulkUpdateChats = async (
	chatsToUpdate: ChatMinimal[],
	patch: Partial<ChatMinimal>,
) => {
	try {
		let chats = (await localforage.getItem(CHATS_KEY)) as ChatMinimal[];
		if (!chats) chats = [];
		chatsToUpdate.forEach((chat) => {
			const chatIndex = chats.findIndex((c) => c.id === chat.id);
			if (chatIndex > -1) {
				chats[chatIndex] = {
					...chats[chatIndex],
					...patch,
				};
			}
		});
		await localforage.setItem(CHATS_KEY, chats);
		return chats;
	} catch (error) {
		console.error('Error updating chats', error);
		return null;
	}
};

export const chatsApi = api.injectEndpoints({
	endpoints: (builder) => ({
		// TODO: this is a temp endpoint for local browser storage
		getChats: builder.query<ChatsResponse, void>({
			// query: () => ({ url: CHATS_KEY }),
			queryFn: async () => {
				const chats: ChatMinimal[] =
					(await localforage.getItem(`${CHATS_KEY}`)) ?? [];

				return {
					data: sortBy(chats, 'created_at').reverse(),
				};
			},
			providesTags: ['Chats'],
		}),
		// TODO: this is a temp endpoint for local browser storage
		getChat: builder.query<ChatResponse, string>({
			// query: () => ({ url: 'chat' }),
			queryFn: async (chatId) => {
				const chats: ChatMinimal[] =
					(await localforage.getItem(`${CHATS_KEY}`)) ?? [];
				const chat = chats.find((chat) => chat.id === chatId);
				return {
					data: chat ?? null,
				};
			},
			providesTags: (result) =>
				result ? [{ type: 'Chats', id: result.id }] : [],
		}),
		getChatByChannelId: builder.query<ChatResponse, string>({
			queryFn: async (channelId) => {
				const chats: ChatMinimal[] =
					(await localforage.getItem(`${CHATS_KEY}`)) ?? [];
				const chat = chats.find((chat) => chat.channel_id === channelId);
				return {
					data: chat ?? null,
				};
			},
		}),
		// TODO: this is a temp endpoint for local browser storage
		createChat: builder.mutation<
			ChatMinimal | null,
			{
				agentType: string;
				channelId: string;
				categoryId?: string;
				initialPrompt?: string;
				status?: 'waiting' | 'started' | undefined;
				userId: UUID;
			}
		>({
			queryFn: async ({
				agentType,
				channelId,
				categoryId,
				initialPrompt,
				status,
				userId,
			}) => {
				const chat_id = uuid();

				const newChat: ChatMinimal | null = await createChat({
					id: chat_id,
					title: initialPrompt ?? 'Untitled chat',
					channel_id: channelId,
					agent_type: agentType,
					created_at: Date.now(),
					updated_at: Date.now(),
					categories: categoryId ? [categoryId] : undefined,
					users: [userId],
					status,
				});
				return new Promise((resolve) => {
					resolve({
						data: newChat,
					});
				});
			},
			invalidatesTags: ['Chats'],
		}),
		// TODO: this is a temp endpoint for local browser storage
		saveChat: builder.mutation<ChatMinimal, ChatMinimal>({
			queryFn: async (chat) => {
				let chats = (await localforage.getItem(CHATS_KEY)) as ChatMinimal[];
				if (!chats) chats = [];
				const existingChat = chats.find((c) => c.id === chat.id);
				if (existingChat) {
					return new Promise((resolve) => {
						resolve({ data: existingChat });
					});
				}
				chats.push(chat);
				await localforage.setItem(CHATS_KEY, chats);
				return new Promise((resolve) => {
					resolve({ data: chat });
				});
			},
			invalidatesTags: ['Chats'],
		}),
		// TODO: this is a temp endpoint for local browser storage
		updateChat: builder.mutation<
			ChatMinimal | null,
			{ id: string } & Partial<ChatMinimal>
		>({
			queryFn: async ({ id, ...patch }) => {
				const updatedChat = await updateChat({ id, ...patch });
				return new Promise((resolve) => {
					resolve({ data: updatedChat });
				});
			},
			invalidatesTags: ['Chats'],
		}),
		bulkUpdateChats: builder.mutation<
			ChatMinimal[] | null,
			{ chats: ChatMinimal[]; patch: Partial<ChatMinimal> }
		>({
			queryFn: async ({ chats, patch }) => {
				const updatedChats = await bulkUpdateChats(chats, patch);
				return new Promise((resolve) => {
					resolve({ data: updatedChats });
				});
			},
			invalidatesTags: ['Chats'],
		}),
		// TODO: this is a temp endpoint for local browser storage
		deleteChat: builder.mutation<string, string>({
			queryFn: async (id, { dispatch }) => {
				void localforage.removeItem(`${CHAT_MESSAGES_KEY}.${id}`);

				const chats =
					((await localforage.getItem(`${CHATS_KEY}`)) as ChatMinimal[]) ?? [];
				const chat = chats.find((chat) => chat.id === id);
				if (chat?.channel_id) {
					void dispatch(
						channelApi.endpoints.leaveChannel.initiate({
							channelId: chat.channel_id,
							userId: chat.users[0],
						}),
					);
				}

				const newChats = chats.filter((chat) => chat.id !== id);
				await localforage.setItem(CHATS_KEY, newChats);

				return new Promise((resolve) => {
					resolve({ data: id });
				});
			},
			invalidatesTags: ['Chats'],
		}),
		disconnectChat: builder.mutation<{ id: string }, { chatId: string }>({
			queryFn: async ({ chatId }, { getState, dispatch }) => {
				const state = getState() as RootState;
				const currentUser = state.api.queries['getMe(undefined)']?.data as User;

				const updatedChat = await updateChat({ id: chatId, status: undefined });
				if (updatedChat?.channel_id)
					void dispatch(
						channelApi.endpoints.leaveChannel.initiate({
							channelId: updatedChat.channel_id,
							userId: currentUser.id,
						}),
					);
				return new Promise((resolve) => {
					resolve({ data: { id: chatId } });
				});
			},
			invalidatesTags: ['Chats'],
		}),
		bulkDisconnectChat: builder.mutation<
			{ ids: string[] },
			{ chatIds: string[] }
		>({
			queryFn: async ({ chatIds }, { getState, dispatch }) => {
				const state = getState() as RootState;
				const currentUser = state.api.queries['getMe(undefined)']?.data as User;
				const chats = (await localforage.getItem(CHATS_KEY)) as ChatMinimal[];
				if (!chats) return { data: { ids: [] } };
				const chatsToUpdate = chats?.filter((chat) =>
					chatIds.includes(chat.id),
				);
				if (!chatsToUpdate) return { data: { ids: [] } };
				const updatedChats = await bulkUpdateChats(chatsToUpdate, {
					status: undefined,
				});
				updatedChats?.forEach((chat) => {
					if (chat.channel_id)
						void dispatch(
							channelApi.endpoints.leaveChannel.initiate({
								channelId: chat.channel_id,
								userId: currentUser.id,
							}),
						);
				});

				return new Promise((resolve) => {
					resolve({ data: { ids: chatIds } });
				});
			},
			invalidatesTags: ['Chats'],
		}),
		getChatMessages: builder.query<
			ChatMessageIncoming[],
			{ channelId: string; chatId: string }
		>({
			queryFn: async ({ chatId }) => {
				const savedMessages = await localforage.getItem(
					`${CHAT_MESSAGES_KEY}.${chatId}`,
				);
				console.debug('Chat.Messages.Loaded');

				return { data: (savedMessages ?? []) as ChatMessageIncoming[] };
			},
			async onCacheEntryAdded(
				{ channelId, chatId },
				{ cacheDataLoaded, cacheEntryRemoved, updateCachedData, getState },
			) {
				try {
					await cacheDataLoaded;

					// load_messages - get all past messages first

					const state = getState() as RootState;
					const socket = getSocket(getSocketData(state));

					const handleUpdateCacheData = (message: ChatMessageIncoming) => {
						updateCachedData((draft) => {
							if (
								message.channel_id === channelId &&
								message.conversation_id === chatId
							) {
								console.debug('Chat.On.Message', message);
								try {
									draft.push({
										...message,
										...(message.type === 'REPLY' && {
											feedback: null,
										}),
									});
								} catch (error) {
									console.error('Chat.On.Message.Error', error);
								}
							}
						});
					};

					socket?.on('message', handleUpdateCacheData);

					await cacheEntryRemoved;

					socket?.off('message', handleUpdateCacheData);
				} catch {
					// if cacheEntryRemoved resolved before cacheDataLoaded,
					// cacheDataLoaded throws
				}
			},
			providesTags: ['ChatMessages'],
		}),
	}),
});

export const {
	useGetChatsQuery,
	useGetChatQuery,
	useCreateChatMutation,
	useSaveChatMutation,
	useUpdateChatMutation,
	useBulkUpdateChatsMutation,
	useDeleteChatMutation,
	useDisconnectChatMutation,
	useBulkDisconnectChatMutation,
	useGetChatMessagesQuery,
} = chatsApi;
