import { api } from './base';
import {
	EnterprisePreferences,
	SaveEnterprisePreferencesRequest,
} from './types';

export const enterprisePreferencesApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getEnterprisePreferences: builder.query<EnterprisePreferences, string>({
			query: (enterpriseId) => ({
				url: `/enterprise/${enterpriseId}/preferences`,
				method: 'GET',
			}),
			providesTags: ['EnterprisePreferences'],
		}),
		saveEnterprisePreferences: builder.mutation<
			EnterprisePreferences,
			SaveEnterprisePreferencesRequest
		>({
			query: (data) => ({
				url: '/enterprise/preferences',
				method: 'POST',
				body: data,
			}),
			invalidatesTags: ['EnterprisePreferences'],
		}),
	}),
});

export const {
	useGetEnterprisePreferencesQuery,
	useSaveEnterprisePreferencesMutation,
} = enterprisePreferencesApi;
