import localforage from 'localforage';

import { EXAMPLE_MEETINGS_KEY } from '../constants';
import { serializeArrayParams } from '../utils/helper';
import { SAMPLE_MEETINGS } from '../utils/sampleMeetingData';
import { contentApi as api } from './base';
import {
	ExampleMeetingData,
	Meeting,
	MeetingRequest,
	MeetingResponse,
	MeetingVideoRequest,
	MeetingsBidirectionalListRequest,
	MeetingsBidirectionalListResponse,
	MeetingsJoinRequest,
	MeetingsJoinResponse,
} from './types';

export const meetingsApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getMeeting: builder.query<MeetingResponse, MeetingRequest>({
			query: ({ meeting_id, is_team_view }) => ({
				url: `meetings/${meeting_id}`,
				method: 'GET',
				params: { meeting_id, is_team_view },
			}),
		}),
		listMeetingsBidirectional: builder.query<
			MeetingsBidirectionalListResponse,
			MeetingsBidirectionalListRequest
		>({
			query: (params) => {
				const arrayParams = {
					people: params.people || [],
					meeting_category: params.meeting_category || [],
					meeting_department: params.meeting_department || [],
					team_members: params.team_members || [],
				};

				const baseParams = {
					cursor: params.cursor,
					per_page: params.per_page,
					direction: params.direction,
					meeting_internal_external: params.meeting_internal_external,
					...(params.date_range && {
						'date_range[from]': params.date_range.from,
						'date_range[to]': params.date_range.to,
					}),
					search_query: params.search_query,
					is_team_view: params.is_team_view ?? false,
				};

				const arrayParamsString = serializeArrayParams(arrayParams);
				const baseParamsString = new URLSearchParams(
					Object.entries(baseParams).filter(([_, v]) => v != null) as [
						string,
						string,
					][],
				).toString();

				// Combine both parameter strings
				const queryString = [baseParamsString, arrayParamsString]
					.filter(Boolean)
					.join('&');
				return {
					url: `meetings/${queryString ? `?${queryString}` : ''}`,
					method: 'GET',
				};
			},
			serializeQueryArgs: ({ endpointName, queryArgs }) => {
				const {
					people,
					meeting_category,
					meeting_department,
					meeting_internal_external,
					date_range,
					search_query,
					team_members,
					is_team_view = false
				} = queryArgs;

				const serializeArray = (arr: string[] | null | undefined) =>
					arr?.length ? [...arr].sort().join(',') : undefined;

				const serializedDateRange = date_range ? {
					from: date_range.from,
					to: date_range.to
				} : undefined;

				const cacheKey = {
					endpointName,
					people: serializeArray(people),
					meetingCategory: serializeArray(meeting_category),
					meetingDepartment: serializeArray(meeting_department),
					meetingInternalExternal: meeting_internal_external,
					dateRange: serializedDateRange
						? `${serializedDateRange.from}|${serializedDateRange.to}`
						: undefined,
					searchQuery: search_query?.trim(),
					teamMembers: serializeArray(team_members),
					isTeamView: is_team_view
				};

				Object.keys(cacheKey).forEach(key =>
					cacheKey[key as keyof typeof cacheKey] === undefined && delete cacheKey[key as keyof typeof cacheKey]
				);
				return cacheKey;
			},
			merge: (currentCache, newItems, { arg }) => {
				const { direction } = arg;

				if (direction === undefined) {
					currentCache.has_previous = newItems.has_previous;
					currentCache.has_next = newItems.has_next;
				} else if (direction === 'previous') {
					currentCache.has_previous = newItems.has_previous;
				} else if (direction === 'next') {
					currentCache.has_next = newItems.has_next;
				}

				const updateMeetings = (currentMeetings: Meeting[], newMeetings: Meeting[]) => {
					const uniqueMeetings = new Map();

					currentMeetings.forEach((meeting) => {
						uniqueMeetings.set(meeting.id, meeting);
					});

					newMeetings.forEach((meeting) => {
						uniqueMeetings.set(meeting.id, meeting);
					});

					return Array.from(uniqueMeetings.values()).sort(
						(a, b) => new Date(b.start_time).getTime() - new Date(a.start_time).getTime()
					);
				};

				currentCache.meetings = updateMeetings(currentCache.meetings, newItems.meetings);

				if (currentCache.meetings.length > 500) {
					currentCache.meetings = direction === 'next'
						? currentCache.meetings.slice(0, 500)
						: currentCache.meetings.slice(-500);
				}
			},
			forceRefetch({ currentArg, previousArg }) {
				return (
					currentArg?.cursor !== previousArg?.cursor ||
					currentArg?.direction !== previousArg?.direction ||
					currentArg?.people?.join(',') !== previousArg?.people?.join(',') ||
					currentArg?.meeting_category?.join(',') !==
					previousArg?.meeting_category?.join(',') ||
					currentArg?.meeting_department?.join(',') !==
					previousArg?.meeting_department?.join(',') ||
					currentArg?.meeting_internal_external !==
					previousArg?.meeting_internal_external ||
					currentArg?.date_range?.from !== previousArg?.date_range?.from ||
					currentArg?.date_range?.to !== previousArg?.date_range?.to ||
					currentArg?.search_query !== previousArg?.search_query ||
					currentArg?.team_members?.join(',') !==
					previousArg?.team_members?.join(',') ||
					currentArg?.is_team_view !== previousArg?.is_team_view
				);
			},
			transformResponse: async (
				response: MeetingsBidirectionalListResponse,
				meta,
				arg
			) => {
				if ((!response.meetings || response.meetings.length === 0) && arg?.direction === undefined && arg?.search_query === undefined) {
					const exampleMeetings =
						(
							await localforage.getItem<ExampleMeetingData['fields']>(
								EXAMPLE_MEETINGS_KEY,
							)
						)?.meetings ?? SAMPLE_MEETINGS;
					//add new data to each
					const meetings = exampleMeetings?.map((meeting) => ({
						...meeting,
						start_time: new Date(
							Date.now() + 24 * 60 * 60 * 1000,
						).toISOString(),
						end_time: new Date(Date.now() + 25 * 60 * 60 * 1000).toISOString(),
					})) as Meeting[];
					return {
						meetings,
						has_previous: false,
						has_next: false,
					};
				}
				return {
					meetings: response.meetings,
					has_previous: response.has_previous,
					has_next: response.has_next,
				};
			},
		}),
		joinMeeting: builder.mutation<MeetingsJoinResponse, MeetingsJoinRequest>({
			query: ({ meeting_url }) => ({
				url: `meetings/join`,
				method: 'POST',
				body: { meeting_url },
			}),
		}),
		getMeetingVideo: builder.query<{ video_url: string }, MeetingVideoRequest>({
			query: ({ meeting_id }) => {
				const isTeamView = window.location.pathname.includes('/team/');
				return {
					url: `meetings/${meeting_id}/video-url`,
					method: 'GET',
					params: { is_team_view: isTeamView },
				};
			},
		}),
	}),
});

export const {
	useGetMeetingQuery,
	useListMeetingsBidirectionalQuery,
	useJoinMeetingMutation,
	useGetMeetingVideoQuery,
} = meetingsApi;